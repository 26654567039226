import { Contract } from "../../../core/app";
import { AppActions } from "../../types";

export const setContractAbiAction = (contract: Contract, abiData: any) => {
  return { type: AppActions.SET_CONTRACT_ABI, contract, abiData };
};

export const loadContractAbiAction = (contract: Contract) => {
  return { type: AppActions.LOAD_CONTRACT_ABI, contract };
};

export const loadMultipleContractAbis = (contracts: Contract[]) => {
  return { type: AppActions.LOAD_MULTIPLE_CONTRACT_ABI, contracts };
};
