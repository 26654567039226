import React from "react";
import Web3 from "web3";

import { BlockChainState } from "../../../storage/state/blockChain/state";
import { ApplicationState } from "../../../storage/state/app/state";
import { appConfig, AppErrorCode, AppMode } from "../../../core/app";
import { ApiHelpers } from "../../../core/helpers/api";
import { BlockChainHelpers } from "../../../core/helpers/chain";
import { UniversalNFT } from "../../../core/contracts/universalNFT";

import UniversalNFTAbi from "../../../assets/contracts/UniversalNFT.json";

import { UniverseNFTController } from "../../../core/modules/universeNFT";
import { UniversalNFTV1 } from "../../../core/contracts/universalNFTV1";

import MainBannerImage from "../../../assets/images/collections/ciervoLab/ciervo-lab-banner.jpg";
import CiervoLabLogo from "../../../assets/images/collections/ciervoLab/LOGO-TP.png";
import AnimatedImage from "../../../assets/images/collections/ciervoLab/animation.gif";
import BBLogo from "../../../assets/images/BB_LOGO.svg";
import MeterLogo from "../../../assets/images/meter-network.png";
import { UniversalNFTV3 } from "../../../core/contracts/universalNFTV3";

import Anim3 from "../../../assets/images/animations/3.gif";
import { UniverseMintingController } from "../../../core/modules/universalMinting";

interface CollectionProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

const linksBase = {
  instagram: "",
  twitter: "",
  linkedin: "",
  telegram: "",
  discord: "",
};

interface CollectionState {
  collectionInstance: UniversalNFTV3 | null;
  universalMinting: null | UniverseMintingController;
  collection: string;
  isPublic: boolean;
  launched: boolean;
  hasCollectionKey: boolean;
  protection: boolean;
  refund: boolean;
  timeToBePublic: number;
  payment: string;
  collectionName: string;
  collectionSymbol: string;
  price: string;
  image: string;
  owner: string;
  gallery: string[];
  links: { [social: string]: string };
  totalMinted: Number;
  stakingPoolPercentage: Number;
  stakingPool: string | null;
  maxSupply: Number;
  allowance: Number;
  keysAllowance: Number;
  totalNFTs: string;
  contractBalance: Number;
  aboutOwner: string;
  aboutProject: string;
  myNFTs: any[];
  universalNFT: null | UniverseNFTController;

  //Page
  kamehameha: boolean;
  secondStage: boolean;
}

export class MangaPunksPage extends React.PureComponent<CollectionProps, CollectionState> {
  pageVideo: any;
  pageSound: any;

  constructor(props: CollectionProps) {
    super(props);

    this.state = {
      collectionInstance: null,
      isPublic: false,
      universalMinting: null,
      launched: false,
      hasCollectionKey: false,
      refund: false,
      protection: false,
      timeToBePublic: 0,
      collection: "",
      collectionName: "",
      collectionSymbol: "",
      totalMinted: 0,
      maxSupply: 0,
      payment: "",
      allowance: 0,
      keysAllowance: 0,
      contractBalance: 0,
      totalNFTs: "",
      stakingPoolPercentage: 0,
      stakingPool: "",
      links: linksBase,
      gallery: [],
      price: "",
      image: "",
      owner: "",
      aboutOwner: "",
      aboutProject: "",
      myNFTs: [],
      universalNFT: null,

      // Page
      kamehameha: false,
      secondStage: false,
    };

    this.pageVideo = React.createRef();
    this.pageSound = React.createRef();
  }

  async componentDidMount() {
    this.props.onToggleLoader(true);
    this.loadAndSetUniversalNFT();
    await this.preloadControllers(true);
  }

  async preloadControllers(closeLoader: boolean = false) {
    const provider = await BlockChainHelpers.getProvider();
    const contractAddress =
      appConfig.mode === AppMode.TEST_METER ? "0xf7501798e815da0928cc98cf9760fb7920a9b0e1" : "0xb674576c15c582dc582ddcfcfaf5e04f9ad603cd";

    let collectionInstance = null;
    let isPublic = false;
    let launched = false;
    let refund = false;
    let timeToBePublic = 0;
    let collectionName = "";
    let collectionSymbol = "";
    let maxSupply = 0;
    let totalMinted = 0;
    let payment = "";
    let allowance = 0;
    let price = "";
    let image = "";
    let owner = "";
    let aboutOwner = "";
    let aboutProject = "";
    let stakingPool = "";
    let stakingPoolPercentage = 0;
    let links = linksBase;
    let gallery: any[] = [];
    let universalNFT = null;
    let keysAllowance = 0;

    console.log(this.props.blockChain.controller?.universalMinter);
    if (this.props.blockChain.controller?.universalMinter && this.props.blockChain.controller.selectedAccount) {
      universalNFT = new UniverseMintingController(this.props.blockChain.controller.universalMinter);
      await universalNFT.initialize();

      keysAllowance = Number(
        Web3.utils.fromWei(
          await this.props.blockChain.controller.token?.allowance(
            this.props.blockChain.controller.selectedAccount,
            this.props.blockChain.controller.universalMinter.address
          ),
          "ether"
        )
      );
    }

    if (provider && contractAddress && this.props.blockChain.controller?._web3 && this.props.blockChain.controller?.selectedAccount) {
      const collectionData = await ApiHelpers.collection(contractAddress);

      collectionInstance = new UniversalNFTV3(
        new provider.eth.Contract(UniversalNFTAbi.abi as any, contractAddress),
        this.props.blockChain.controller?._web3,
        this.props.blockChain.controller?.selectedAccount
      );

      const data = await collectionInstance.getData();
      console.log(data);

      allowance = Number(
        Web3.utils.fromWei(
          await this.props.blockChain.controller.token?.allowance(this.props.blockChain.controller.selectedAccount, contractAddress),
          "ether"
        )
      );

      if (collectionData && data) {
        const launchTime = Number(data.launch.launchTime);
        const privateTime = Number(data.launch.privateTime);
        const publicDate = new Date((launchTime + privateTime) * 1000);

        timeToBePublic = (publicDate.getTime() - Date.now()) / 1000;

        image = collectionData._image;
        owner = collectionData._owner;
        links = collectionData._projectLinks;
        aboutOwner = collectionData._aboutOwner;
        aboutProject = collectionData._aboutProject;
        gallery = collectionData._gallery;
        maxSupply = data.generation.maxSupply;
        launched = data.launch.wasLaunched;

        collectionName = data.generation.name;
        collectionSymbol = data.generation.symbol;
        price = data.generation.price;
        totalMinted = data.minting.totalSupply;
        isPublic = data.minting.isPublic;
      }
    }

    this.setState(
      {
        collectionInstance,
        collectionName,
        keysAllowance,
        collectionSymbol,
        aboutOwner,
        launched,
        payment,
        refund,
        owner,
        stakingPool,
        stakingPoolPercentage,
        maxSupply,
        aboutProject,
        gallery,
        timeToBePublic,
        price,
        allowance,
        isPublic,
        totalMinted,
        image,
        links,
        collection: contractAddress || "",
        universalMinting: universalNFT,
      },
      async () => {
        if (closeLoader) this.props.onToggleLoader(false);

        if (this.state.collectionInstance) {
          const tokens = await this.state.collectionInstance.tokens();
          const preloadedNFTs = await ApiHelpers.preloadExternalNFTs(this.state.collection, tokens);

          console.log(tokens, preloadedNFTs);

          if ((preloadedNFTs || preloadedNFTs.data) && (Array.isArray(preloadedNFTs) || Array.isArray(preloadedNFTs.data))) {
            this.setState({
              myNFTs: Array.isArray(preloadedNFTs) ? preloadedNFTs : Array.isArray(preloadedNFTs.data) ? preloadedNFTs.data : [],
            });
          }
        }
      }
    );
  }

  async loadAndSetUniversalNFT() {
    let universalNFT = null;

    if (this.props.blockChain.controller?.universalFactory) {
      universalNFT = new UniverseNFTController(this.props.blockChain.controller.universalFactory);
      await universalNFT.initialize();
    }

    this.setState({
      universalNFT,
      hasCollectionKey: universalNFT?.data?.hasKey || false,
    });
  }

  private _onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this.loadAndSetUniversalNFT();
    this.preloadControllers();
  }

  private _activateKamehameha() {
    if (!this.state.secondStage) {
      this.pageVideo.current.play();
      this.pageSound.current.play();
      this.setState({ kamehameha: true }, () => {
        setTimeout(() => {
          this.setState({ secondStage: true });
        }, 2000);
      });
    }
  }

  componentDidUpdate(prevProps: CollectionProps) {
    if (
      (!prevProps.blockChain.controller?.selectedAccount && this.props.blockChain.controller?.selectedAccount) ||
      (!prevProps.blockChain.controller?.universalMinter && this.props.blockChain.controller?.universalMinter)
    ) {
      console.log(prevProps.blockChain.controller?.universalMinter);
      this.preloadControllers();
    }
  }

  render() {
    const mintingPrice = Number(Web3.utils.fromWei(this.state.price || "0", "ether"));
    const minutes = Number(this.state.timeToBePublic) / 60;
    const hours = Number(this.state.timeToBePublic) / 60 / 60;
    const progress = (Number(this.state.totalMinted) * 100) / Number(this.state.maxSupply);

    return (
      <React.Fragment>
        <div className="ct-manga-punks">
          <div className="ct-video">
            <video
              ref={this.pageVideo}
              autoPlay
              loop
              controls={false}
              src={"https://businessbuildersnfts.s3.us-east-2.amazonaws.com/WebPages/italy-video.mp4"}
            ></video>
            <audio
              ref={this.pageSound}
              controls={false}
              src="https://businessbuildersnfts.s3.us-east-2.amazonaws.com/WebPages/Kamehameha.mp3"
            ></audio>
          </div>

          <div className="ct-shadow"></div>

          <div className={"ct-info-container ct-flexible" + (this.state.secondStage ? "" : " ct-active")}>
            <label className={"ct-kamehameha switch" + (this.state.kamehameha ? " move" : "")} id="slight-move">
              <input checked={this.state.kamehameha} readOnly type="checkbox" />
              <span className="slider round"></span>
            </label>

            <div className="ct-access">
              <h4>MangaPunks</h4>
              <h5>By BusinessBuilders ITA</h5>
              <button onClick={() => this._activateKamehameha()} className={"ct-main-button" + (this.state.kamehameha ? "ct-disable" : "")}>
                Join now
              </button>
            </div>
          </div>

          <div className={"ct-info-container" + (this.state.secondStage ? " ct-active" : "")}>
            <div className="ct-minting  animate__animated animate__backInLeft animate__delay-1s">
              <div className="ct-minting-container">
                <h4>Mint your own Manga Punk</h4>
                <input
                  type="number"
                  placeholder="Total NFTs"
                  value={this.state.totalNFTs}
                  onChange={(e) => this.setState({ totalNFTs: e.target.value })}
                />
                <span>
                  <strong>Price: {(mintingPrice * Number(this.state.totalNFTs)).toFixed(1)} $MTRG</strong>{" "}
                </span>

                {this.state.launched ? (
                  this.state.allowance >= mintingPrice * Number(this.state.totalNFTs) ? (
                    <button
                      onClick={() => {
                        if (this.state.collectionInstance) {
                          this.state.collectionInstance.manyMint(Number(this.state.totalNFTs), (error) => {
                            this._onUpdateData(error);
                          });
                        }
                      }}
                    >
                      Mint
                    </button>
                  ) : (
                    <button
                      className="ct-mt-5"
                      onClick={async () => {
                        if (this.props.blockChain.controller?.token) {
                          console.log((mintingPrice * Number(this.state.totalNFTs) * 2).toString());
                          this.props.blockChain.controller?.token?.approve(
                            this.state.collection,
                            mintingPrice * Number(this.state.totalNFTs) * 2,
                            (error) => {
                              this._onUpdateData(error);
                            }
                          );
                        }
                      }}
                    >
                      Approve ({(mintingPrice * Number(this.state.totalNFTs)).toFixed(1)} MTRG)
                    </button>
                  )
                ) : (
                  ""
                )}

                {this.state.launched && !this.state.isPublic ? (
                  <span className="ct-small-text">For now only the owner of the collection key and whitelisted users can mint NFTs</span>
                ) : (
                  ""
                )}
                {this.state.launched && !this.state.isPublic ? (
                  <span>
                    <strong>Public mint: </strong> {hours > 1 ? hours.toFixed(0) + " Hours" : minutes.toFixed(0) + " Minutes"}
                  </span>
                ) : (
                  ""
                )}

                {this.state.universalMinting?.data ? (
                  <div className="ct-universal-key">
                    <div className="ct-key">
                      <img src={Anim3} alt="Lighting key" />
                    </div>
                    <div className="ct-data">
                      <h4>
                        Universal Key{" "}
                        <small>
                          ({this.state.universalMinting.data.keysSupply} / {this.state.universalMinting.data.maxKeys})
                        </small>
                      </h4>
                      <small>Mint the collection before all (Be whitelisted in all collections)</small>
                      <a href="https://docsuninft.businessbuilders.city/meter/universal-keys" target="_blank" rel="noopener noreferrer">
                        read more
                      </a>
                      {this.state.keysAllowance >= this.state.universalMinting.data.keyPrice ? (
                        <button
                          onClick={() => {
                            if (this.props.blockChain.controller) {
                              this.props.blockChain.controller.universalMinter?.mintKey((error) => {
                                this._onUpdateData(error);
                              });
                            }
                          }}
                        >
                          buy ({this.state.universalMinting?.data?.keyPrice.toFixed(3)} MTRG)
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            if (
                              this.props.blockChain.controller &&
                              this.props.blockChain.controller.universalMinter &&
                              this.state.universalMinting?.data
                            ) {
                              this.props.blockChain.controller.token?.approve(
                                this.props.blockChain.controller.universalMinter?.address,
                                this.state.universalMinting?.data?.keyPrice + this.state.universalMinting?.data?.keyPrice * 0.1,
                                (error) => {
                                  this._onUpdateData(error);
                                }
                              );
                            }
                          }}
                        >
                          approve ({this.state.universalMinting?.data?.keyPrice.toFixed(3)} MTRG)
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="ct-animation">
                <img src={"https://businessbuildersnfts.s3.us-east-2.amazonaws.com/WebPages/MangaPunks/nfts-small.gif"} alt="" />
              </div>
            </div>

            <div className="ct-collection-info ct-max-container animate__animated animate__backInLeft animate__delay-1s">
              <h4>About the NFT collection</h4>
              <div className="ct-line"></div>
              <div className="ct-line"></div>
              <h5>Minted</h5>

              <h5>
                {this.state.totalMinted} / {this.state.maxSupply}
              </h5>

              <div className="ct-flex">
                <div className="ct-project-road">
                  <h5>Roadmap</h5>
                  <ul>
                    <li>
                      1. Initial giveaway, 2 NFTs will be distributed using the Gleam.IO. You can join to it using the following{" "}
                      <a
                        href="https://gleam.io/WfAm0/airdrop-of-2-manga-punks-dark-edition-by-business-builders-ita"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        link
                      </a>
                    </li>
                    <li>
                      2. Initially only the owners of the universal key can mint at the price of 80 MTRG. After 24 hours the collection will be public
                      and everyone will be able to mint it at the price of 100 MTRG.
                    </li>
                    <li>3. You can mint 5 gold NFTs in the collection. If you find one you will get one additional NFT.</li>
                    <li>
                      4. If less than 50% of the collection is minted we will only send to the staking pool the FTB generated by universal keys
                      rewards. (Less than 25 NFTs minted)
                    </li>
                    <li>
                      5. If more than 50% of the collection is minted we will send to the staking pool the FTB generated in the BusinessBuilders ITA
                      city. (More than 25 NFTs minted)
                    </li>
                  </ul>
                  <h5>How will collected MTRG's be used?</h5>
                  <ul>
                    <li>1. 3000 MTRG will be used to buy VOLT and stake it for 4 years providing weight to the FTB-MTRG pool on voltswap.</li>
                    <li>2. 2000 MTRG will be added to the MTRG - FTB liquidity pool.</li>
                  </ul>
                </div>

                <div className="ct-benefits">
                  <h5>Advantages for the NFT staking</h5>

                  <p>
                    Once a month the FTB contained in the NFT staking pool will be distributed. The staking pool will be filled in the next two ways
                  </p>
                  <p>
                    <strong>A.</strong> A percentage of the businessBuilders ITA city generation will be distributed in the NFT collection staking
                    pool every month. <strong>The project will distribute all FTB only when the project reaches a half of selled NFTs.</strong>
                  </p>
                  <ul>
                    <li>
                      <p>FTB &#60; 0.005 USD: 300,000 FTB</p>
                    </li>
                    <li>
                      <p>0.005 USD &#60; FTB &#60; 0.01 USD: 200,000 FTB</p>
                    </li>
                    <li>
                      <p>0.01 USD &#60; FTB &#60; 0.05 USD: 120,000 FTB</p>
                    </li>
                    <li>
                      <p>0.05 USD &#60; FTB: 75,000 FTB</p>
                    </li>
                  </ul>

                  <p>
                    B. The project will distribute the universal keys staking rewards in the NFT collection pool. 9 keys will generate daily FTB and
                    you will be able to get it if you stake your Manga punks NFT.
                  </p>

                  <h5>BusinessBuilders players benefits</h5>
                  <p>
                    If you have 2 NFTs in the staking pool you will be able to request a land in the BusinessBuilders ITA city. The city has the max
                    multiplier and you will be able to generate a lot of FTB rewards with your factories.
                  </p>
                </div>
              </div>

              <div className="ct-partners">
                <div className="ct-partner">
                  <h3>Our partner</h3>
                  <img src={BBLogo} alt="" />
                  <h5>BusinessBuildersNFT</h5>
                  <a href="https://businessbuilders.city" target="_blank" rel="noopener noreferrer">
                    Web page
                  </a>
                </div>
                <div className="ct-partner">
                  <h3>Used technology</h3>
                  <img src={MeterLogo} alt="" />
                  <h5>Meter Blockchain</h5>
                  <a href="https://meter.io" target="_blank" rel="noopener noreferrer">
                    Web page
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="ct-my-collection-nfts ct-max-container ct-mt-20">
            <div className="ct-collection-grid">
              {this.state.myNFTs.map((nft, index) => {
                return (
                  <div key={index + nft._nftID.toString()} className="ct-nft-data">
                    <img src={nft._attributes.image} alt="" />
                    <div className="ct-info">
                      <small>Collection: {this.state.collectionName}</small>
                      <h4>{nft._attributes.name}</h4>
                      {nft._attibutes?.attibutes && Array.isArray(nft._attibutes?.attibutes) ? (
                        <React.Fragment>
                          <h5>Information</h5>
                          <div className="ct-metadata">
                            {nft._attibutes?.attibutes.map((meta: any, index: number) => {
                              return (
                                <div key={index}>
                                  <span>{meta.type}: </span>
                                  <span>{meta.data}</span>
                                </div>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      <a href={"https://tofunft.com/nft/meter/" + this.state.collection + "/" + nft._nftID} rel="noopener noreferrer" target="_blank">
                        View in marketplace
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
