import React from "react";
import Web3 from "web3";

import { UtilsHelpers } from "../../../core/helpers/utils";
import { BlockChainState } from "../../../storage/state/blockChain/state";
import { ApplicationState } from "../../../storage/state/app/state";
import { AppErrorCode } from "../../../core/app";

import "./styles.css";

interface DistributionProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  data: any;
  onUpdate: (error: AppErrorCode | null) => void;
}

interface DistributionState {}

export class DistributionComponent extends React.PureComponent<DistributionProps, DistributionState> {
  chartContainer: any;
  chart: any;

  constructor(props: DistributionProps) {
    super(props);

    this.state = {};

    this.chartContainer = React.createRef();
    this.chart = React.createRef();
  }

  render() {
    const tokenSymbol = UtilsHelpers.getTokenSymbolByAdress(this.props.data.token);

    return (
      <div className="ct-dist">
        <div className="ct-data">
          <h4>Universal distribution - {tokenSymbol}</h4>
          {this.props.data.stakingPercentage ? (
            <span>
              <strong>staking pool: </strong> {this.props.data.stakingPercentage} %
            </span>
          ) : (
            ""
          )}

          {this.props.data.buyBurnPercentage ? (
            <span>
              <strong>buy burn pool: </strong> {this.props.data.buyBurnPercentage} %
            </span>
          ) : (
            ""
          )}

          {this.props.data.stakingPercentage ? (
            <span>
              <strong>savings: </strong> {Web3.utils.fromWei(this.props.data.balance || "0")} {tokenSymbol}
            </span>
          ) : (
            ""
          )}

          <small>All savings will be distributed when the pool reaches 100 USD on value.</small>

          <button
            className="ct-main-button"
            onClick={() => {
              if (this.props.blockChain.controller) {
                this.props.blockChain.controller.distributor?.forceDistribution(this.props.data.token, this.props.data.balance, (error) => {
                  this.props.onUpdate(error);
                });
              }
            }}
          >
            distribute
          </button>
        </div>
      </div>
    );
  }
}
