import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";
import { AbstractUniversalNFTGeneral } from "../abstracts/universalNFTGeneral";

export class UniversalNFTGeneral extends ContractBaseModel<AbstractUniversalNFTGeneral> {
  tokenURI(id: number) {
    return this.contract.tokenURI(id).call(this.config);
  }

  name() {
    return this.contract.name().call(this.config);
  }

  symbol() {
    return this.contract.symbol().call(this.config);
  }

  totalSupply() {
    return this.contract.totalSupply().call(this.config);
  }

  isApprovedForAll(owner: string, operator: string) {
    return this.contract.isApprovedForAll(owner, operator).call(this.config);
  }

  getApproved(id: number) {
    return this.contract.getApproved(id).call(this.config);
  }

  approve(to: string, id: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .approve(to, id)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  softIDByTokenID(id: number) {
    return this.contract.softIDByTokenID(id).call(this.config);
  }

  tokenIDBySoftID(id: number) {
    return this.contract.tokenIDBySoftID(id).call(this.config);
  }

  getCustomerTokens(owner: string) {
    return this.contract.getCustomerTokens(owner).call(this.config);
  }

  balanceOf(owner: string) {
    return this.contract.balanceOf(owner).call(this.config);
  }

  ownerOf(index: number) {
    return this.contract.ownerOf(index).call(this.config);
  }

  tokenByIndex(index: number) {
    return this.contract.tokenByIndex(index).call(this.config);
  }

  tokenOfOwnerByIndex(owner: string, index: number) {
    return this.contract.tokenOfOwnerByIndex(owner, index).call(this.config);
  }

  mint(softID: number, to: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .mint(softID, to)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  burn(id: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .burn(id)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  changeURI(uri: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .changeURI(uri)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  pause(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .pause()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  setApprovalForAll(operator: string, approved: boolean, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .setApprovalForAll(operator, approved)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  unpause(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .unpause()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }
}
