import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";
import { AbstractDailyCoinBidding } from "../abstracts/dailyCoinBidding";

export class DailyCoinBidding extends ContractBaseModel<AbstractDailyCoinBidding> {
  getBiddingConfig() {
    return this.contract.getBiddingConfig().call(this.config);
  }

  getBiddingData() {
    return this.contract.getBiddingData().call(this.config);
  }

  getFees() {
    return this.contract.getFees().call(this.config);
  }

  getAllInformation() {
    return this.contract.getAllInformation().call(this.config);
  }

  calculateAmount(amount: string) {
    return this.contract.calculatePayment(amount).call(this.config);
  }

  adminStart(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .adminStart()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.INVALID_TOKEN_TRANSACTION,
          receipt,
          callback
        );
      })
      .on("error", (error: any) =>
        this.errorCallback(
          AppErrorCode.INVALID_TOKEN_TRANSACTION,
          error,
          callback
        )
      );
  }

  bid(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .bid()
      .send({ ...this.config, value: "100000000000000000" })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.INVALID_TOKEN_TRANSACTION,
          receipt,
          callback
        );
      })
      .on("error", (error: any) =>
        this.errorCallback(
          AppErrorCode.INVALID_TOKEN_TRANSACTION,
          error,
          callback
        )
      );
  }

  claim(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .claim()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.INVALID_TOKEN_TRANSACTION,
          receipt,
          callback
        );
      })
      .on("error", (error: any) =>
        this.errorCallback(
          AppErrorCode.INVALID_TOKEN_TRANSACTION,
          error,
          callback
        )
      );
  }
}
