import React from "react";
import Web3 from "web3";

import { BlockChainState } from "../../../storage/state/blockChain/state";
import { ApplicationState } from "../../../storage/state/app/state";
import { appConfig, AppErrorCode } from "../../../core/app";
import { ApiHelpers } from "../../../core/helpers/api";
import { BlockChainHelpers } from "../../../core/helpers/chain";
import { UniversalNFT } from "../../../core/contracts/universalNFT";

import UniversalNFTAbi from "../../../assets/contracts/UniversalNFT.json";
import UniversalNFTProtectedAbi from "../../../assets/contracts/UniversalNFTProtected.json";

import { UniverseNFTController } from "../../../core/modules/universeNFT";
import { UniversalNFTV1 } from "../../../core/contracts/universalNFTV1";

import MainBannerImage from "../../../assets/images/drawnCities/BigBanner.png";
import AnimatedImage from "../../../assets/images/drawnCities/animation.gif";
import BBLogo from "../../../assets/images/BB_LOGO.svg";
import MeterLogo from "../../../assets/images/meter-network.png";

interface CollectionProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

const linksBase = {
  instagram: "",
  twitter: "",
  linkedin: "",
  telegram: "",
  discord: "",
};

interface CollectionState {
  collectionInstance: UniversalNFT | UniversalNFTV1 | null;
  collection: string;
  isPublic: boolean;
  launched: boolean;
  hasCollectionKey: boolean;
  protection: boolean;
  refund: boolean;
  timeToBePublic: number;
  payment: string;
  collectionName: string;
  collectionSymbol: string;
  price: string;
  image: string;
  owner: string;
  gallery: string[];
  links: { [social: string]: string };
  totalMinted: Number;
  stakingPoolPercentage: Number;
  stakingPool: string | null;
  maxSupply: Number;
  allowance: Number;
  totalNFTs: string;
  contractBalance: Number;
  aboutOwner: string;
  aboutProject: string;
  myNFTs: any[];
  universalNFT: null | UniverseNFTController;
}

export class DrawnCitiesPage extends React.PureComponent<CollectionProps, CollectionState> {
  constructor(props: CollectionProps) {
    super(props);

    this.state = {
      collectionInstance: null,
      isPublic: false,
      launched: false,
      hasCollectionKey: false,
      refund: false,
      protection: false,
      timeToBePublic: 0,
      collection: "",
      collectionName: "",
      collectionSymbol: "",
      totalMinted: 0,
      maxSupply: 0,
      payment: "",
      allowance: 0,
      contractBalance: 0,
      totalNFTs: "",
      stakingPoolPercentage: 0,
      stakingPool: "",
      links: linksBase,
      gallery: [],
      price: "",
      image: "",
      owner: "",
      aboutOwner: "",
      aboutProject: "",
      myNFTs: [],
      universalNFT: null,
    };
  }

  async componentDidMount() {
    this.props.onToggleLoader(true);
    this.loadAndSetUniversalNFT();
    await this.preloadControllers(true);
  }

  async preloadControllers(closeLoader: boolean = false) {
    const provider = await BlockChainHelpers.getProvider();

    const contractAddress = "0x56a69dbf318a7f38d89207f2a63fc3d4396a4915";

    let collectionInstance = null;
    let isPublic = false;
    let launched = false;
    let refund = false;
    let timeToBePublic = 0;
    let collectionName = "";
    let collectionSymbol = "";
    let maxSupply = 0;
    let totalMinted = 0;
    let payment = "";
    let allowance = 0;
    let price = "";
    let image = "";
    let owner = "";
    let aboutOwner = "";
    let aboutProject = "";
    let stakingPool = "";
    let stakingPoolPercentage = 0;
    let links = linksBase;
    let gallery: any[] = [];
    let protection = false;

    if (provider && contractAddress && this.props.blockChain.controller?._web3 && this.props.blockChain.controller?.selectedAccount) {
      const collectionData = await ApiHelpers.collection(contractAddress);

      protection = collectionData._protected;

      if (protection) {
        collectionInstance = new UniversalNFT(
          new provider.eth.Contract(UniversalNFTProtectedAbi.abi as any, contractAddress),
          this.props.blockChain.controller?._web3,
          this.props.blockChain.controller?.selectedAccount
        );
      } else {
        collectionInstance = new UniversalNFT(
          new provider.eth.Contract(UniversalNFTAbi.abi as any, contractAddress),
          this.props.blockChain.controller?._web3,
          this.props.blockChain.controller?.selectedAccount
        );
      }

      const mintingData = await collectionInstance.getMintingData();
      const generationData = await collectionInstance.getGenerationData();

      allowance = Number(
        Web3.utils.fromWei(
          await this.props.blockChain.controller.token?.allowance(this.props.blockChain.controller.selectedAccount, contractAddress),
          "ether"
        )
      );

      if (collectionData && generationData && mintingData) {
        const launchTime = Number(generationData.lt);
        const privateTime = Number(generationData.pt);
        const publicDate = new Date((launchTime + privateTime) * 1000);

        timeToBePublic = (publicDate.getTime() - Date.now()) / 1000;

        image = collectionData._image;
        owner = collectionData._owner;
        links = collectionData._projectLinks;
        aboutOwner = collectionData._aboutOwner;
        aboutProject = collectionData._aboutProject;
        maxSupply = generationData.mx;
        gallery = collectionData._gallery;
        launched = generationData.wl;
        collectionName = generationData.na;
        collectionSymbol = generationData.sy;
        price = mintingData.mp;
        totalMinted = mintingData.ts;
        isPublic = mintingData.ip;
        payment = mintingData.py;
        refund = generationData.r;

        if (generationData.cs) {
          stakingPool = generationData.cs;
          stakingPoolPercentage = generationData.csP;
        }
      }
    }

    this.setState(
      {
        collectionInstance,
        collectionName,
        protection,
        collectionSymbol,
        aboutOwner,
        launched,
        payment,
        refund,
        owner,
        stakingPool,
        stakingPoolPercentage,
        maxSupply,
        aboutProject,
        gallery,
        timeToBePublic,
        price,
        allowance,
        isPublic,
        totalMinted,
        image,
        links,
        collection: contractAddress || "",
      },
      async () => {
        if (closeLoader) this.props.onToggleLoader(false);

        if (this.state.collectionInstance) {
          const tokens = await this.state.collectionInstance.getCustomerTokens();

          const preloadedNFTs = await ApiHelpers.preloadExternalNFTs(this.state.collection, tokens);

          if ((preloadedNFTs || preloadedNFTs.data) && (Array.isArray(preloadedNFTs) || Array.isArray(preloadedNFTs.data))) {
            this.setState({
              myNFTs: Array.isArray(preloadedNFTs) ? preloadedNFTs : Array.isArray(preloadedNFTs.data) ? preloadedNFTs.data : [],
            });
          }
        }
      }
    );
  }

  async loadAndSetUniversalNFT() {
    let universalNFT = null;

    if (this.props.blockChain.controller?.universalFactory) {
      universalNFT = new UniverseNFTController(this.props.blockChain.controller.universalFactory);
      await universalNFT.initialize();
    }

    this.setState({
      universalNFT,
      hasCollectionKey: universalNFT?.data?.hasKey || false,
    });
  }

  private _onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this.loadAndSetUniversalNFT();
    this.preloadControllers();
  }

  render() {
    const mintingPrice = Number(Web3.utils.fromWei(this.state.price, "ether"));
    const minutes = Number(this.state.timeToBePublic) / 60;
    const hours = Number(this.state.timeToBePublic) / 60 / 60;

    return (
      <React.Fragment>
        <div className="ct-drawn-cities">
          <img src={MainBannerImage} alt="" />
          <div className="ct-call-to-action">
            <div className="ct-main-banner ct-max-container">
              <h1>Drawn Cities NFTs</h1>
              <h5>Mint your randomly generated city.</h5>
              <div className="ct-minted">
                <span>Minted NFTs</span>
                <strong>
                  {this.state.totalMinted} / {this.state.maxSupply}
                </strong>
              </div>
            </div>
          </div>
          <div className="ct-minting ct-max-container">
            <div className="ct-minting-container">
              <h4>Mint your own city</h4>

              <input
                type="number"
                placeholder="Total NFTs"
                value={this.state.totalNFTs}
                onChange={(e) => this.setState({ totalNFTs: e.target.value })}
              />
              <span>
                <strong>Price: {(mintingPrice * Number(this.state.totalNFTs)).toFixed(1)} $MTRG</strong>{" "}
              </span>
              <small>
                Your payments will be protected by BusinessBuilders contracts. Your payment will be processed only if the collection owners keep their
                roadmap promises.
              </small>
              {this.state.allowance >= mintingPrice * Number(this.state.totalNFTs) ? (
                <button
                  onClick={() => {
                    if (this.state.collectionInstance) {
                      this.state.collectionInstance.payedManyMint(Number(this.state.totalNFTs), (error) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  Mint
                </button>
              ) : (
                <button
                  className="ct-mt-5"
                  onClick={async () => {
                    if (this.props.blockChain.controller?.token) {
                      console.log((mintingPrice * Number(this.state.totalNFTs) * 2).toString());
                      this.props.blockChain.controller?.token?.approve(
                        this.state.collection,
                        mintingPrice * Number(this.state.totalNFTs) * 2,
                        (error) => {
                          this._onUpdateData(error);
                        }
                      );
                    }
                  }}
                >
                  Approve ({(mintingPrice * Number(this.state.totalNFTs)).toFixed(1)} MTRG)
                </button>
              )}
              {this.state.protection && this.state.refund ? (
                <button
                  className="ct-mt-5"
                  onClick={() => {
                    if (this.state.collectionInstance) {
                      (this.state.collectionInstance as UniversalNFT).withdrawPayment((error) => {
                        this._onUpdateData(error);
                      });
                    }
                  }}
                >
                  Withdraw payments
                </button>
              ) : (
                ""
              )}
              {this.state.launched && !this.state.isPublic ? (
                <span className="ct-small-text">For now only the owner of the collection key can mint NFTs</span>
              ) : (
                ""
              )}
              {this.state.launched && !this.state.isPublic ? (
                <span>
                  <strong>Public mint: </strong> {hours > 1 ? hours.toFixed(0) + " Hours" : minutes.toFixed(0) + " Minutes"}
                </span>
              ) : (
                ""
              )}
              <strong>Protected funds: {Web3.utils.fromWei(this.state.payment || "0", "ether")} $MTRG</strong>
            </div>
            <div className="ct-images">
              <img src={AnimatedImage} alt="" />
            </div>
          </div>
          <div className="ct-roadmap">
            <div className="ct-part">
              <p>
                <strong>1. </strong> Launch our collection (Jun 14) using UniNFT platform on Meter blockchain.{" "}
                <a href="https://uninft.businessbuilders.city/drawn-cities" target="_blank" rel="noopener noreferrer">
                  web page
                </a>
              </p>
            </div>
            <div className="ct-part">
              <p>
                <strong>2. </strong> Create our own staking pool using NFT utility services in UniNFT. <strong>soon</strong>
              </p>
            </div>
            <div className="ct-part">
              <p>
                <strong>3. </strong> Finish the minting process and start funds unlock by BusinessBuilders <strong>soon</strong>
              </p>
            </div>
            <div className="ct-part">
              <p>
                <strong>4. </strong> Unlock 50% of the collected funds and use it to create our first $MTRG node pay servers and send all rewards to
                the staking pool. <strong>soon</strong>
              </p>
            </div>
            <div className="ct-part">
              <p>
                <strong>5. </strong> 15 days later we will unlock the other 50% to create our second node and send all the rewards to the staking
                pool. <strong>soon</strong>
              </p>
            </div>
            <div className="ct-part">
              <p>
                <strong>6. </strong> Start our new NFT collection and deploy our new DAPP using Meter blockchain. This will be a NFT collection
                generator based in collected art. The users can upload their draws and we will validate it, if the draw is valid we will add it to our
                collection and the arts will earn a percentage of our collection based in total draws.
              </p>
            </div>
            <div className="ct-part">
              <p>
                <strong>7. </strong> Launch our secondary collection. Our plan is to get allies in nearby foundations to collect art and with this to
                launch our NFT collection. And raise funds for the foundations. Little funds but slowly growing in the world of NFTs.
              </p>
            </div>
            <div className="ct-part">
              <p>
                <strong>8. </strong> Use our first staking pool to increase the rewards with the new collection. This will be a long term staking
                pool.
              </p>
            </div>
            <div className="ct-part">
              <p>
                <strong>9. </strong> Create more nodes using $MTRG to increase the staking pool rewards. Launch more about us and more roadmap
                sections.
              </p>
            </div>
          </div>
          <div className="ct-partners">
            <div className="ct-partner">
              <h3>Our partner</h3>
              <img src={BBLogo} alt="" />
              <h5>BusinessBuildersNFT</h5>
              <a href="https://businessbuilders.city" target="_blank" rel="noopener noreferrer">
                Web page
              </a>
            </div>
            <div className="ct-partner">
              <h3>Used technology</h3>
              <img src={MeterLogo} alt="" />
              <h5>Meter Blockchain</h5>
              <a href="https://meter.io" target="_blank" rel="noopener noreferrer">
                Web page
              </a>
            </div>
          </div>
          <div className="ct-my-collection-nfts ct-max-container ct-mt-20">
            <div className="ct-collection-grid">
              {this.state.myNFTs.map((nft, index) => {
                return (
                  <div key={index + nft._nftID.toString()} className="ct-nft-data">
                    <img src={nft._attributes.image} alt="" />
                    <div className="ct-info">
                      <small>Collection: {this.state.collectionName}</small>
                      <h4>{nft._attributes.name}</h4>
                      {nft._attibutes?.attibutes && Array.isArray(nft._attibutes?.attibutes) ? (
                        <React.Fragment>
                          <h5>Information</h5>
                          <div className="ct-metadata">
                            {nft._attibutes?.attibutes.map((meta: any, index: number) => {
                              return (
                                <div key={index}>
                                  <span>{meta.type}: </span>
                                  <span>{meta.data}</span>
                                </div>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      <a href={"https://tofunft.com/nft/meter/" + this.state.collection + "/" + nft._nftID} rel="noopener noreferrer" target="_blank">
                        View in marketplace
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
