import Web3 from "web3";
import { appConfig, AppErrorCode } from "../app";

export class ContractBaseModel<T> {
  public key: string = "";
  public contract: T;
  public address: string;

  constructor(
    public contractData: any,
    public web3: Web3,
    public selectedAccount: string
  ) {
    this.contract = this.contractData.methods;
    this.address = this.contractData._address;
  }

  get config() {
    return {
      from: this.selectedAccount,
    };
  }

  errorCallback(
    defaultError: AppErrorCode,
    response: any,
    callback: (error: AppErrorCode | null, receipt?: any | null) => void
  ) {
    if (response.code === 4001) {
      if (callback) callback(AppErrorCode.USER_DENIED_TRANSACTION);
    } else if (callback) callback(defaultError);
  }

  defaultReceipt(
    defaultError: AppErrorCode,
    receipt: any,
    callback: (error: AppErrorCode | null, receipt?: any | null) => void
  ) {
    if (receipt) {
      if (appConfig.confirmedBlocks > 0) {
        let total = false;
        let waitInterval = setInterval(async () => {
          const blockNumber = await this.web3.eth.getBlockNumber();
          if (blockNumber - receipt.blockNumber >= appConfig.confirmedBlocks) {
            if (!total) {
              clearInterval(waitInterval);
              callback(null, receipt);
              total = true;
            }
          }
        }, appConfig.blocktimeout);
      } else if (callback) callback(null, receipt);
    } else if (callback) callback(defaultError);
  }

  getPastEvents(event: string, config: any) {
    return new Promise((res) => {
      this.contractData.getPastEvents(
        event,
        config,
        (error: any, events: any) => {
          if (error) res([]);
          else res(events);
        }
      );
    });
  }
}
