import { AppErrorCode } from "../../../core/app";
import { BlockChain } from "../../../core/chain";
import { Customer } from "../../../core/customer";
import { AppActions } from "../../types";

export const saveBlockChainController = (controller: BlockChain) => {
  return { type: AppActions.SAVE_BLOCK_CHAIN_CONTROLLER, controller };
};

export const destroyBlockChainController = () => {
  return { type: AppActions.DESTROY_BLOCK_CHAIN_CONTROLLER };
};

export const setBlockChainError = (error: AppErrorCode | null) => {
  return { type: AppActions.SET_BLOCK_CHAIN_ERROR, error };
};

export const setCustomer = (customer: Customer) => {
  return { type: AppActions.SET_CUSTOMER, customer };
};

export const clearCustomer = () => {
  return { type: AppActions.CLEAR_CUSTOMER };
};
