import React from "react";
import Web3 from "web3";

import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { appConfig, AppErrorCode, AppMode, Contract } from "../../core/app";

import { DistributionComponent } from "../organisms/distribution/distribution";

interface DistributionProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadBlockChain: () => void;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface DistributionState {
  distributionData: any;
}

export class DistributionPage extends React.PureComponent<DistributionProps, DistributionState> {
  constructor(props: DistributionProps) {
    super(props);

    this.state = {
      distributionData: null,
    };
  }

  async componentDidMount() {
    this.preloadControllers();
    this.props.onToggleLoader(false);
  }

  componentDidUpdate(prevProps: DistributionProps) {
    if (
      (!prevProps.blockChain.controller?.selectedAccount && this.props.blockChain.controller?.selectedAccount) ||
      (!prevProps.appState.appData && this.props.appState.appData)
    ) {
      this.preloadControllers();
    }
  }

  async preloadControllers() {
    if (this.props.appState.appData?.contractsAddress[Contract.TOKEN]) {
      const data = await this.props.blockChain.controller?.distributor?.getData();
      this.setState({ distributionData: data });
    }
  }

  async _onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this.preloadControllers();
  }

  render() {
    return (
      <React.Fragment>
        <div className="ct-distribution">
          <div className="ct-distributions ct-max-container">
            {this.state.distributionData?.tokens?.map((token: any, index: number) => {
              return (
                <DistributionComponent
                  key={index}
                  data={{
                    token,
                    balance: this.state.distributionData.balances[index],
                    buyBurnPercentage: this.state.distributionData.buyBurnPercentage,
                    stakingPercentage: this.state.distributionData.stakingPercentage,
                  }}
                  blockChain={this.props.blockChain}
                  appState={this.props.appState}
                  onUpdate={(error) => this._onUpdateData(error)}
                ></DistributionComponent>
              );
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
