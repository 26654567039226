import React from "react";
import Web3 from "web3";

import UniversalNFTAbiV2 from "../../assets/contracts/UniversalNFTV2.json";
import UniversalNFTProtectedV2Abi from "../../assets/contracts/UniversalNFTProtectedV2.json";
import UniversalNFTV3Abi from "../../assets/contracts/UniversalNFT.json";
import UniversalNFTProtectedV3Abi from "../../assets/contracts/UniversalNFTProtected.json";
import UniversalNFTV1Abi from "../../assets/contracts/UniversalNFTV1.json";
import IERC20 from "../../assets/contracts/static/ERC20.json";
import Anim3 from "../../assets/images/animations/3.gif";

import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { AppErrorCode } from "../../core/app";
import { ApiHelpers } from "../../core/helpers/api";
import { BlockChainHelpers } from "../../core/helpers/chain";
import { UniversalNFT } from "../../core/contracts/universalNFT";
import { UniversalNFTV1 } from "../../core/contracts/universalNFTV1";
import { UniversalNFTV2 } from "../../core/contracts/universalNFTV2";
import { Token } from "../../core/contracts/token";
import { toast } from "react-toastify";
import { UniverseMintingController } from "../../core/modules/universalMinting";
import { Slide } from "react-slideshow-image";

import "react-slideshow-image/dist/styles.css";
import { UniversalNFTV3 } from "../../core/contracts/universalNFTV3";

interface CollectionProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadBlockChain: () => void;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

const linksBase = {
  instagram: "",
  twitter: "",
  linkedin: "",
  telegram: "",
  discord: "",
};

interface CollectionState {
  collectionInstance: UniversalNFTV3 | null;
  universalMinting: null | UniverseMintingController;
  tokenInstance: Token | null;
  collection: string;
  isPublic: boolean;
  launched: boolean;
  hasCollectionKey: boolean;
  protection: boolean;
  refund: boolean;
  timeToBePublic: number;
  payment: string;
  collectionName: string;
  collectionSymbol: string;
  price: string;
  baseUri: string;
  image: string;
  owner: string;
  gallery: string[];
  links: { [social: string]: string };
  totalMinted: number;
  stakingPoolPercentage: number;
  stakingPool: string | null;
  tokenSymbol: string;
  maxSupply: number;
  allowance: number;
  keyAllowance: number;
  contractBalance: number;
  aboutOwner: string;
  whitelisted: boolean;
  aboutProject: string;
  myNFTs: any[];
  preloadedNFTs: any[];
  mintingAmount: string;

  //amounts
  recolectorFees: number;
  customStakingFees: number;
  customStakingAmount: number;
  ownerAmount: number;
  recolectorAmount: number;
  totalAmount: number;
  tokenBalance: number;
}

export class CollectionPage extends React.PureComponent<CollectionProps, CollectionState> {
  constructor(props: CollectionProps) {
    super(props);

    this.state = {
      collectionInstance: null,
      universalMinting: null,
      tokenInstance: null,
      isPublic: false,
      launched: false,
      hasCollectionKey: false,
      refund: false,
      protection: false,
      timeToBePublic: 0,
      collection: "",
      baseUri: "",
      collectionName: "",
      collectionSymbol: "",
      tokenSymbol: "",
      totalMinted: 0,
      maxSupply: 0,
      tokenBalance: 0,
      payment: "",
      allowance: 0,
      contractBalance: 0,
      stakingPoolPercentage: 0,
      keyAllowance: 0,
      stakingPool: "",
      links: linksBase,
      gallery: [],
      whitelisted: false,
      preloadedNFTs: [],
      price: "",
      image: "",
      owner: "",
      aboutOwner: "",
      aboutProject: "",
      myNFTs: [],
      mintingAmount: "",

      //Amounts
      recolectorFees: 0,
      customStakingFees: 0,
      customStakingAmount: 0,
      ownerAmount: 0,
      recolectorAmount: 0,
      totalAmount: 0,
    };
  }

  async componentDidMount() {
    this.props.onToggleLoader(true);
    await this.preloadControllers(true);
  }

  async preloadControllers(closeLoader: boolean = false) {
    const provider = await BlockChainHelpers.getProvider();
    const contractAddress = new URLSearchParams(window.location.search).get("address");

    let collectionInstance = null;
    let universalMinting = null;
    let tokenInstance = null;

    let isPublic = false;
    let launched = false;
    let refund = false;

    let timeToBePublic = 0;
    let collectionName = "";
    let collectionSymbol = "";
    let maxSupply = 0;
    let totalMinted = 0;
    let payment = "";
    let allowance = 0;
    let price = "";
    let image = "";
    let owner = "";
    let aboutOwner = "";
    let aboutProject = "";
    let stakingPool = "";
    let baseUri = "";
    let stakingPoolPercentage = 0;
    let links = linksBase;
    let gallery: any[] = [];
    let protection = false;
    let hasCollectionKey = false;
    let preloadedNFTs: any[] = [];
    let keyAllowance = 0;
    let whitelisted = false;
    let tokenSymbol = "MTRG";

    let recolectorFees = 0;
    let customStakingFees = 0;
    let customStakingAmount = 0;
    let ownerAmount = 0;
    let recolectorAmount = 0;
    let totalAmount = 0;
    let tokenBalance = 0;

    if (
      provider &&
      contractAddress &&
      this.props.blockChain.controller?._web3 &&
      this.props.blockChain.controller?.selectedAccount &&
      this.props.blockChain.controller.universalMinter
    ) {
      keyAllowance = Number(
        Web3.utils.fromWei(
          await this.props.blockChain.controller.token?.allowance(
            this.props.blockChain.controller.selectedAccount,
            this.props.blockChain.controller.universalMinter?.address
          )
        )
      );

      const collectionData = await ApiHelpers.collection(contractAddress);

      collectionInstance = new UniversalNFTV3(
        new provider.eth.Contract(UniversalNFTV3Abi.abi as any, contractAddress),
        this.props.blockChain.controller?._web3,
        this.props.blockChain.controller?.selectedAccount
      );

      const data = await collectionInstance.getData();

      tokenInstance = new Token(
        new provider.eth.Contract(IERC20 as any, data.generation.token),
        this.props.blockChain.controller?._web3,
        this.props.blockChain.controller?.selectedAccount
      );

      allowance = Number(
        Web3.utils.fromWei(await tokenInstance.allowance(this.props.blockChain.controller.selectedAccount, contractAddress), "ether")
      );

      tokenBalance = Number(Web3.utils.fromWei(await tokenInstance.balanceOf(this.props.blockChain.controller.selectedAccount), "ether"));

      tokenSymbol = await tokenInstance.symbol();

      if (data) {
        const launchTime = Number(data.launch.launchTime);
        const privateTime = Number(data.launch.privateTime);
        const publicDate = new Date((launchTime + privateTime) * 1000);

        timeToBePublic = (publicDate.getTime() - Date.now()) / 1000;

        image = collectionData._image;
        owner = collectionData._owner;
        links = collectionData._projectLinks;
        aboutOwner = collectionData._aboutOwner;
        aboutProject = collectionData._aboutProject;
        gallery = collectionData._gallery;
        maxSupply = data.generation.maxSupply;
        launched = data.launch.wasLaunched;
        collectionName = data.generation.name;
        collectionSymbol = data.generation.symbol;
        price = data.generation.price;
        totalMinted = data.minting.totalSupply;
        isPublic = data.minting.isPublic;
        hasCollectionKey = data.minting.hasKey;
        baseUri = data.generation.uri;
        whitelisted = data.minting.isWhitelisted;

        console.log(data);
        recolectorFees = Number(data.generation.recolectorPercentage || "0");
        customStakingFees = Number(data.generation.customStakingPercentage || "0");
        customStakingAmount = Number(Web3.utils.fromWei(data.amounts.customStaking || "0"));
        ownerAmount = Number(Web3.utils.fromWei(data.amounts.owner || "0"));
        recolectorAmount = Number(Web3.utils.fromWei(data.amounts.recolector || "0"));
        totalAmount = Number(Web3.utils.fromWei(data.amounts.total || "0"));

        console.log(recolectorFees, customStakingFees);

        preloadedNFTs = await Promise.all(
          [baseUri + "1", baseUri + "2", baseUri + "3"].map(
            (nft) =>
              new Promise(async (res) => {
                const data = await ApiHelpers.get(nft);
                res(data ? data : null);
              })
          )
        );

        console.log(preloadedNFTs);

        // payment = mintingData.py;
        // refund = generationData.r;

        // if (generationData.cs) {
        //   stakingPool = generationData.cs;
        //   stakingPoolPercentage = generationData.csP;
        // }
        console.log(data);
      }
    }

    if (this.props.blockChain.controller?.universalMinter) {
      universalMinting = new UniverseMintingController(this.props.blockChain.controller.universalMinter);
      await universalMinting.initialize();
    }

    this.setState(
      {
        collectionInstance,
        collectionName,
        protection,
        collectionSymbol,
        aboutOwner,
        launched,
        whitelisted,
        baseUri,
        payment,
        refund,
        hasCollectionKey,
        owner,
        stakingPool,
        stakingPoolPercentage,
        preloadedNFTs,
        maxSupply,
        tokenBalance,
        tokenSymbol,
        aboutProject,
        gallery,
        timeToBePublic,
        price,
        allowance,
        isPublic,
        totalMinted,
        universalMinting,
        image,
        tokenInstance,
        links,
        collection: contractAddress || "",
        keyAllowance,

        //Amounts
        recolectorFees,
        recolectorAmount,
        customStakingAmount,
        customStakingFees,
        totalAmount,
        ownerAmount,
      },
      async () => {
        if (closeLoader) this.props.onToggleLoader(false);

        if (this.state.collectionInstance) {
          const tokens = await this.state.collectionInstance.tokens();
          const preloadedNFTs = await ApiHelpers.preloadExternalNFTs(this.state.collection, tokens);

          if ((preloadedNFTs || preloadedNFTs.data) && (Array.isArray(preloadedNFTs) || Array.isArray(preloadedNFTs.data))) {
            this.setState({
              myNFTs: Array.isArray(preloadedNFTs) ? preloadedNFTs : Array.isArray(preloadedNFTs.data) ? preloadedNFTs.data : [],
            });
          }
        }
      }
    );
  }

  private _onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this.preloadControllers();
  }

  async componentDidUpdate(prevProps: CollectionProps) {
    if (!prevProps.blockChain.controller?.selectedAccount && this.props.blockChain.controller?.selectedAccount) {
      await this.preloadControllers();
    }
  }

  render() {
    const mintingPrice = Number(Web3.utils.fromWei(this.state.price, "ether"));
    const minutes = Number(this.state.timeToBePublic) / 60;
    const hours = Number(this.state.timeToBePublic) / 60 / 60;

    let invalidMint = !this.state.launched || this.state.maxSupply === this.state.totalMinted;

    if (!this.state.isPublic && !this.state.hasCollectionKey) {
      invalidMint = true;
    }

    if (this.state.launched && this.state.whitelisted) {
      invalidMint = false;
    }

    let mintedPercentage = (this.state.totalMinted * 100) / this.state.maxSupply;

    let prediction = {
      totalFunds: 0,
      stakingPoolFee: 0,
      buyBurnFee: 0,
      ownerFunds: 0,
      customStakingFees: 0,
    };

    prediction.totalFunds = mintingPrice * this.state.maxSupply;
    prediction.stakingPoolFee = (prediction.totalFunds * (this.state.recolectorFees / 2)) / 100;
    prediction.buyBurnFee = prediction.stakingPoolFee;
    prediction.ownerFunds = prediction.totalFunds - prediction.stakingPoolFee * 2;

    return (
      <React.Fragment>
        <div className="ct-collection-container">
          <div className="ct-banner">
            <div className="ct-banner-list">
              {[...this.state.preloadedNFTs, ...this.state.preloadedNFTs].map((nft, index) => {
                return nft?.image ? <img src={nft?.image} key={index} alt="" /> : "";
              })}
            </div>
          </div>
          <div className="ct-max-container ct-data-container">
            <div className="ct-data">
              <div className="ct-image">
                {this.state.image ? <img src={this.state.image} alt="" /> : ""}
                <div className="ct-collection-links">
                  {this.state.links
                    ? Object.keys(this.state.links).map((link, index) => {
                        if (this.state.links[link]) {
                          return (
                            <a key={index} href={this.state.links[link]} target="_blank" rel="noopener noreferrer">
                              <span className={"fab fa-" + link}></span>
                            </a>
                          );
                        } else return "";
                      })
                    : ""}
                </div>
              </div>
              <div className="ct-info">
                <h4>{this.state.collectionName}</h4>
                <span>
                  <strong>Launched: </strong> {this.state.launched?.toString()}
                </span>
                <span>
                  <strong>Is Public: </strong> {this.state.isPublic?.toString()}
                </span>
                {this.state.launched && !this.state.isPublic ? (
                  <span>
                    <strong>Public in: </strong> {hours > 1 ? hours.toFixed(0) + " Hours" : minutes.toFixed(0) + " Minutes"}
                  </span>
                ) : (
                  ""
                )}
                <span>
                  <strong>Minting price: </strong> {mintingPrice} {this.state.tokenSymbol}
                </span>
                <span>
                  <strong>Max supply: </strong> {this.state.maxSupply}{" "}
                </span>
                <span>
                  <strong>Minted: </strong> {this.state.totalMinted}{" "}
                </span>
                {this.state.protection ? (
                  <span>
                    <strong>Protected founds: </strong> {Web3.utils.fromWei(this.state.payment || "0", "ether")} MTRG
                  </span>
                ) : (
                  ""
                )}
                <span>
                  <strong>Owner: </strong>{" "}
                  <a href={"http://scan.meter.io/address/" + this.state.owner} target="_blank" rel="noopener noreferrer" className="ct-address">
                    {this.state.owner}
                  </a>
                </span>
                <span>
                  <strong>Contract: </strong>{" "}
                  <a href={"http://scan.meter.io/address/" + this.state.collection} target="_blank" rel="noopener noreferrer" className="ct-address">
                    {this.state.collection}
                  </a>
                </span>
                <div className="ct-minting">
                  {this.state.collectionInstance instanceof UniversalNFTV2 ? (
                    <input
                      type="number"
                      placeholder="0"
                      value={this.state.mintingAmount}
                      onChange={(e) => {
                        this.setState({ mintingAmount: e.target.value });
                      }}
                    />
                  ) : (
                    ""
                  )}
                  {mintingPrice * Number(this.state.mintingAmount) > this.state.allowance ? (
                    <button
                      disabled={invalidMint}
                      className="ct-mt-5 ct-main-button"
                      onClick={() => {
                        if (this.state.tokenInstance) {
                          this.state.tokenInstance?.approve(this.state.collection, mintingPrice * Number(this.state.mintingAmount), (error) => {
                            this._onUpdateData(error);
                          });
                        }
                      }}
                    >
                      Approve
                    </button>
                  ) : (
                    <button
                      disabled={invalidMint}
                      onClick={() => {
                        if (this.state.collectionInstance) {
                          const instance = this.state.collectionInstance;

                          if (instance instanceof UniversalNFTV2) {
                            if (Number(this.state.mintingAmount) > 0) {
                              instance.manyMint(Number(this.state.mintingAmount), (error) => {
                                this._onUpdateData(error);
                              });
                            } else toast.info("Increase the amount of NFTs.");
                          } else {
                            instance.mint((error) => {
                              this._onUpdateData(error);
                            });
                          }
                        }
                      }}
                      className="ct-mt-5 ct-main-button"
                    >
                      Mint
                    </button>
                  )}
                </div>

                <small className="ct-price">
                  <strong>Total price:</strong> {(mintingPrice * Number(this.state.mintingAmount)).toFixed(2)} {this.state.tokenSymbol}
                </small>
                <small className="ct-price">
                  <strong>Total balance:</strong> {this.state.tokenBalance.toFixed(2)} {this.state.tokenSymbol}
                </small>

                {this.state.universalMinting?.data && !this.state.hasCollectionKey ? (
                  <div className="ct-universal-key">
                    <div className="ct-key">
                      <img src={Anim3} alt="Lighting key" />
                    </div>
                    <div className="ct-data">
                      <h4>
                        Universal Key{" "}
                        <small>
                          ({this.state.universalMinting.data.keysSupply} / {this.state.universalMinting.data.maxKeys})
                        </small>
                      </h4>
                      <small>Whitelisted on all collections.</small>
                      <a href="https://docsuninft.businessbuilders.city/meter/universal-keys" target="_blank" rel="noopener noreferrer">
                        read more
                      </a>
                      {this.state.keyAllowance >= this.state.universalMinting.data.keyPrice ? (
                        <button
                          onClick={() => {
                            if (this.props.blockChain.controller) {
                              this.props.blockChain.controller.universalMinter?.mintKey((error) => {
                                this._onUpdateData(error);
                              });
                            }
                          }}
                        >
                          buy ({this.state.universalMinting?.data?.keyPrice.toFixed(3)} MTRG)
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            if (
                              this.props.blockChain.controller &&
                              this.props.blockChain.controller.universalMinter &&
                              this.state.universalMinting?.data &&
                              this.state.tokenInstance
                            ) {
                              this.props.blockChain.controller.token?.approve(
                                this.props.blockChain.controller.universalMinter?.address,
                                this.state.universalMinting?.data?.keyPrice + this.state.universalMinting?.data?.keyPrice * 0.1,
                                (error) => {
                                  this._onUpdateData(error);
                                }
                              );
                            }
                          }}
                        >
                          approve ({this.state.universalMinting?.data?.keyPrice.toFixed(3)} MTRG)
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* <div className="ct-percentage">
                <div className="ct-bar" style={{ width: mintedPercentage + "%" }}></div>
                <small>{mintedPercentage}%</small>
              </div> */}

              {/* {this.state.stakingPoolPercentage > 0 ? (
                <div className="ct-staking-pool">
                  <p>
                    The NFT collection has a staking pool relation, <strong>{this.state.stakingPoolPercentage} %</strong> will be used in the staking
                    pool. The owner can't change it when the collection start the minting process and the only way to get the founds is distribute it
                    to all staking holders. All collected founds will be distributed in the end of the month, please read the collection roadmap to
                    see what will be the distribution in the next months.
                  </p>
                  <p className="ct-mt-10">
                    Click in the following link to get all information about the pool{" "}
                    <a href={"/nft-staking?pool=" + this.state.stakingPool} target="_blank" rel="noopener noreferrer">
                      Pool address {this.state.stakingPool}
                    </a>
                  </p>
                </div>
              ) : (
                ""
              )} */}

              {!!this.state.aboutProject ? (
                <div className="ct-collection-information ct-about-project">
                  <h4>the project</h4>
                  <div
                    className="ct-about-project"
                    dangerouslySetInnerHTML={{
                      __html: this.state.aboutProject,
                    }}
                  ></div>
                </div>
              ) : (
                ""
              )}

              {/* {this.state.aboutOwner ? (
                <div className="ct-collection-information">
                  <h4>the owner</h4>
                  <p className="ct-owner-info">{this.state.aboutOwner}</p>
                </div>
              ) : (
                ""
              )} */}

              <div className="ct-tokenomics">
                <div className="ct-bar" style={{ width: mintedPercentage + "%" }}></div>
                <div className="ct-tokenomics-container">
                  <h4>live tokenomics</h4>
                  <div className="ct-data">
                    <span>
                      <strong>total funds: </strong> {Intl.NumberFormat().format(this.state.totalAmount)} {this.state.tokenSymbol}
                    </span>
                    <span>
                      <strong>special staking fees: </strong> {Intl.NumberFormat().format(this.state.recolectorAmount / 2)} {this.state.tokenSymbol}
                    </span>
                    <span>
                      <strong>buy burn FTB fees: </strong> {Intl.NumberFormat().format(this.state.recolectorAmount / 2)} {this.state.tokenSymbol}
                    </span>
                    <span>
                      <strong>owner funds: </strong> {Intl.NumberFormat().format(this.state.ownerAmount)} {this.state.tokenSymbol}
                    </span>
                    <span>
                      <strong>minted: </strong> {mintedPercentage}% ({this.state.totalMinted} / {this.state.maxSupply})
                    </span>
                  </div>
                </div>
                <div className="ct-tokenomics-container">
                  <h4>tokenomics prediction</h4>
                  <div className="ct-data">
                    <span>
                      <strong>total funds: </strong> {Intl.NumberFormat().format(prediction.totalFunds)} {this.state.tokenSymbol}
                    </span>
                    <span>
                      <strong>special staking fees: </strong> {Intl.NumberFormat().format(prediction.stakingPoolFee)} {this.state.tokenSymbol}
                    </span>
                    <span>
                      <strong>buy burn FTB fees: </strong> {Intl.NumberFormat().format(prediction.buyBurnFee)} {this.state.tokenSymbol}
                    </span>
                    <span>
                      <strong>owner funds: </strong> {Intl.NumberFormat().format(prediction.ownerFunds)} {this.state.tokenSymbol}
                    </span>
                    <span>
                      <strong>minted: </strong> 100% ({this.state.maxSupply} / {this.state.maxSupply})
                    </span>
                  </div>
                </div>
              </div>

              {this.state.gallery.length > 0 ? (
                <div className="ct-gallery">
                  <Slide slidesToScroll={2} slidesToShow={2} arrows={false} autoplay={true}>
                    {this.state.gallery.map((slideImage, index) => (
                      <div className="ct-each-slide" key={index}>
                        <img src={slideImage} alt="" />
                      </div>
                    ))}
                  </Slide>
                </div>
              ) : (
                ""
              )}

              {this.state.myNFTs.length > 0 ? (
                <div className="ct-my-collection-nfts ct-mt-20">
                  <h4>my nfts</h4>

                  <div className="ct-collection-grid">
                    {this.state.myNFTs.map((nft, index) => {
                      return (
                        <div key={index + nft._nftID.toString()} className="ct-nft-data">
                          <img src={nft._attributes.image} alt="" />
                          <div className="ct-info">
                            <div className="ct-actions">
                              <div className="ct-names">
                                <small>Collection: {this.state.collectionName}</small>
                                <h4>{nft._attributes.name}</h4>
                              </div>
                              <div className="ct-links">
                                <a
                                  href={"https://tofunft.com/nft/meter/" + this.state.collection + "/" + nft._nftID}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  <span className="fas fa-shopping-cart"></span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
