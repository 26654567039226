import { AnyAction } from "redux";
import { AppData } from "../../../core/types";
import { AppActions, AppReducer } from "../../types";

export const applicationKey = AppReducer.APP;

export interface ApplicationState {
  loader: boolean;
  leftNavigation: boolean;
  employeesPagination: number;
  appData: AppData | null;
}

export const initialState: ApplicationState = {
  loader: false,
  leftNavigation: false,
  employeesPagination: 0,
  appData: null,
};

export const applicationReducer = (
  state: ApplicationState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case AppActions.CHANGE_EMPLOYEES_PAGINATION:
      return {
        ...state,
        employeesPagination: action.page,
      };
    case AppActions.TOGGLE_LOADER:
      return {
        ...state,
        loader: action.force !== undefined ? action.force : !state.loader,
      };
    case AppActions.TOGGLE_LEFT_NAVIGATION:
      return {
        ...state,
        leftNavigation:
          action.force !== undefined ? action.force : !state.leftNavigation,
      };
    case AppActions.SAVE_APP_DATA:
      return {
        ...state,
        appData: action.data,
      };
    default:
      return state;
  }
};
