import { Contract } from "./app";

export interface CustomerToken {
  mainTokenBalance: number;
  loadTokenBalance: number;
  loadTokenSymbol: string;
  loadTokenName: string;
}

export interface ContractsAddress {
  [Contract.TOKEN]: string;
  [Contract.UNIVERSAL_FACTORY]: string;
  [Contract.UNIVERSAL_GENERAL]: string;
  [Contract.UNIVERSAL_STAKING_FACTORY]: string;
  [Contract.UNIVERSAL_MINTER]: string;
}

export class AppData {
  constructor(
    public customerToken: CustomerToken,
    public contractsAddress: ContractsAddress
  ) {}

  static buildFromTemplates(
    customerToken: CustomerToken,
    contractsAddress: ContractsAddress
  ) {
    return new AppData(customerToken, contractsAddress);
  }
}
