import { AnyAction } from "redux";
import { Contract } from "../../core/app";
import { ContractData, ContractsStateController } from "../../core/contracts";
import { UtilsHelpers } from "../../core/helpers/utils";
import { AppActions } from "../types";

export const loadContractAbi =
  (store: any) => (next: any) => (action: AnyAction) => {
    if (action.type === AppActions.LOAD_CONTRACT_ABI) {
      UtilsHelpers.debugger(
        "Execute contracts middleware (" +
          AppActions.LOAD_MULTIPLE_CONTRACT_ABI +
          ")"
      );

      UtilsHelpers.debugger(
        "Success contracts middleware (" +
          AppActions.LOAD_MULTIPLE_CONTRACT_ABI +
          ")"
      );

      next({
        type: AppActions.SET_CONTRACT_ABI,
        contract: {
          data: ContractsStateController.loadContractAbi(
            action.contract as Contract
          ),
          contract: action.contract,
        },
      });
    } else if (action.type === AppActions.LOAD_MULTIPLE_CONTRACT_ABI) {
      UtilsHelpers.debugger(
        "Execute contracts middleware (" +
          AppActions.LOAD_MULTIPLE_CONTRACT_ABI +
          ")"
      );

      let contracts: ContractData[] = [];

      for (let i = 0; i < action.contracts?.length; i++) {
        contracts.push({
          data: ContractsStateController.loadContractAbi(
            action.contracts[i] as Contract
          ),
          contract: action.contracts[i],
        });
      }

      UtilsHelpers.debugger(
        "Success contracts middleware (" +
          AppActions.LOAD_MULTIPLE_CONTRACT_ABI +
          ")"
      );

      next({
        type: AppActions.SET_MULTIPLE_CONTRACT_ABI,
        contracts,
      });
    } else next(action);
  };
