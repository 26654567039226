import Web3 from "web3";
import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";
import { AbstractUniversalStaking } from "../abstracts/universalStaking";

export class UniversalStaking extends ContractBaseModel<AbstractUniversalStaking> {
  isValidCollection(collection: string) {
    return this.contract.isValidCollection(collection).call(this.config);
  }

  getConfig() {
    return this.contract.getConfig().call(this.config);
  }

  createPool(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .createPool()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.MINTER_REQUEST_ERROR,
          receipt,
          callback
        );
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback)
      );
  }

  setCollection(
    collection: string,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .setCollection(collection)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.MINTER_REQUEST_ERROR,
          receipt,
          callback
        );
      })
      .on("error", (error: any) =>
        this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback)
      );
  }
}
