import React from "react";
import * as Scroll from "react-scroll";

import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { AppErrorCode } from "../../core/app";
import Web3 from "web3";

import DLCLogo from "../../assets/images/DLC.png";
import MTRGLogo from "../../assets/images/meter-network.png";
import CoinAnimation from "../../assets/images/coin_animation.gif";

interface TokenResearchComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadBlockChain: () => void;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface BiddingConfig {
  augment: number;
  buyBackFee: number;
  creator: string;
  creatorFee: number;
  initialBid: string;
  initialTime: string;
  minFeesToExecute: string;
  pair: string;
  previousFee: number;
  timeIncrement: number;
}

interface BiddingData {
  bidAmount: string;
  buyBackFee: string;
  creatorFee: string;
  finishTime: number;
  lastBid: string;
  lastBidder: string;
  nextBid: string;
  totalBids: number;
}

interface TokenResearchComponentState {
  biddingConfig: BiddingConfig | null;
  biddingData: BiddingData | null;
  MTRGAllowance: string;
  activeChart: boolean;
}

export class TokenResearchPage extends React.PureComponent<
  TokenResearchComponentProps,
  TokenResearchComponentState
> {
  constructor(props: TokenResearchComponentProps) {
    super(props);

    this.state = {
      biddingConfig: null,
      biddingData: null,
      MTRGAllowance: "0",
      activeChart: false,
    };
  }

  async componentDidMount() {
    this.preloadControllers();
    Scroll.scrollSpy.update();
  }

  componentWillUnmount() {}

  async preloadControllers() {
    let biddingConfig: BiddingConfig | null = null;
    let biddingData: BiddingData | null = null;
    let MTRGAllowance: string = "0";

    if (this.props.blockChain.controller?.dailyCoinBidding) {
      const allInformation =
        await this.props.blockChain.controller.dailyCoinBidding.getAllInformation();

      biddingConfig = allInformation.config as BiddingConfig;
      biddingData = allInformation.data as BiddingData;
    }

    if (
      this.props.blockChain.controller?.MTRG &&
      this.props.blockChain.controller.selectedAccount &&
      this.props.blockChain.controller.dailyCoinBidding?.address
    ) {
      MTRGAllowance = await this.props.blockChain.controller.MTRG.allowance(
        this.props.blockChain.controller.selectedAccount,
        this.props.blockChain.controller.dailyCoinBidding?.address
      );
    }

    this.setState({ biddingConfig, biddingData, MTRGAllowance });
    this.props.onToggleLoader(false);
  }

  private async _onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    await this.preloadControllers();
  }

  componentDidUpdate(prevProps: TokenResearchComponentProps) {
    if (
      !prevProps.blockChain.controller?.selectedAccount &&
      this.props.blockChain.controller?.selectedAccount
    ) {
      this.preloadControllers();
    }
  }

  render() {
    const finishDate = new Date(
      (this.state.biddingData?.finishTime || 0) * 1000
    ).getTime();

    const parsedNextBid = Number(
      Web3.utils.fromWei(this.state.biddingData?.nextBid || "0")
    );
    const parsedAllowance = Number(
      Web3.utils.fromWei(this.state.MTRGAllowance || "0")
    );

    const now = new Date().getTime();

    return (
      <React.Fragment>
        <div className="ct-bidding-page ct-max-container">
          <div className="ct-some-info">
            <div className="ct-text">
              <h4>What is DLCs?</h4>
              <p>
                This is an investigation token to generate profit creating new
                development projects. All holders will be able to burn their
                tokens to earn a percentage of the new projects and earn profits
                basis on the total burned tokens.
              </p>
            </div>
            <div className="ct-image">
              <img src={CoinAnimation} alt="" />
            </div>
          </div>

          <div className="ct-tokenomics">
            <div className="ct-data-containers">
              <div className="ct-data">
                <h4>Max supply</h4>
                <p>1000000000</p>
              </div>
              <div className="ct-data">
                <h4>Burned tokens</h4>
                <p>100000</p>
              </div>
              <div className="ct-data">
                <h4>Price</h4>
                <p>0.00001 $</p>
              </div>
              <div className="ct-data">
                <h4>Contract</h4>
                <p>0xasbdkjjbasdb</p>
              </div>
              <div
                onClick={() => {
                  this.setState({ activeChart: !this.state.activeChart });
                }}
                className="ct-data"
              >
                <h4>Chart</h4>
                <p>
                  {this.state.activeChart ? (
                    <span className="fas fa-chevron-up"></span>
                  ) : (
                    <span className="fas fa-chevron-down"></span>
                  )}
                </p>
              </div>
              <div className="ct-small-chart"></div>
            </div>

            {this.state.activeChart ? (
              <div className="ct-embed-chart">
                <div id="dexscreener-embed">
                  <iframe src="https://dexscreener.com/meter/0x31c2D10e930977C6Bf26C9F0C5Fe0cf59F9FE9f1?embed=1&trades=0&info=0"></iframe>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="ct-bidding-data">
            <div className="ct-data">
              <h4>Total bids</h4>
              <p>{this.state.biddingData?.totalBids}</p>
              <small>Total bids in this cycle.</small>
            </div>

            <div className="ct-data">
              <h4>Last bid</h4>
              <p>
                {Number(
                  Web3.utils.fromWei(this.state.biddingData?.lastBid || "0")
                ).toFixed(3)}{" "}
                MTRG
              </p>
            </div>

            <div className="ct-data">
              <h4>Last bidder earnings </h4>
              <p>
                {(
                  Number(
                    Web3.utils.fromWei(this.state.biddingData?.lastBid || "0")
                  ) +
                  (Number(
                    Web3.utils.fromWei(this.state.biddingData?.nextBid || "0")
                  ) *
                    (this.state.biddingConfig?.previousFee || 0)) /
                    100 -
                  Number(
                    Web3.utils.fromWei(this.state.biddingData?.lastBid || "0")
                  )
                ).toFixed(3)}{" "}
                MTRG{" "}
                <small>
                  <strong>+{this.state.biddingConfig?.previousFee}%</strong>
                </small>
              </p>
              <small>
                When a new user place a bid the last user will earn{" "}
                {this.state.biddingConfig?.previousFee}% from the new bid.
              </small>
            </div>

            <div className="ct-data">
              <h4>Next bid </h4>
              <p>
                {Number(
                  Web3.utils.fromWei(this.state.biddingData?.nextBid || "0")
                ).toFixed(3)}{" "}
                MTRG{" "}
                <small>
                  <strong>+{this.state.biddingConfig?.augment}%</strong>
                </small>
              </p>
              <small>
                The next user must pay this to join or increase the last bid.
              </small>
            </div>

            <div className="ct-data">
              <h4>Time increment</h4>
              <p>
                {((this.state.biddingConfig?.timeIncrement || 0) / 60).toFixed(
                  2
                )}{" "}
                minutes
              </p>
              <small>
                The time will be increased when other user place a bid.
              </small>
            </div>

            <div className={"ct-data" + (finishDate < now ? " ct-active" : "")}>
              <h4>Finish time</h4>
              <p>
                {finishDate < now
                  ? "The winner can withdraw"
                  : "In " +
                    ((finishDate - now) / 1000 / 60).toFixed(0) +
                    " minutes"}
              </p>
              <small>The pool will be closed when the time comes.</small>
            </div>

            <div className="ct-data">
              <h4>Buy and burn fee</h4>
              <p>
                {Number(
                  Web3.utils.fromWei(this.state.biddingData?.buyBackFee || "0")
                ).toFixed(3)}{" "}
                MTRG{" "}
                <small>
                  <strong>+{this.state.biddingConfig?.buyBackFee}%</strong>
                </small>
              </p>
              <small>
                When we reach{" "}
                {Number(
                  Web3.utils.fromWei(
                    this.state.biddingConfig?.minFeesToExecute || "0"
                  )
                ).toFixed(3)}{" "}
                MTRG the bot will execute a buy and burn transaction
              </small>
            </div>
          </div>

          <div className="ct-bidding-container">
            <div className="ct-bidding">
              <div className="ct-header">
                <h4>Buy</h4>
                <small>
                  Participate for the next DLCs bag using our bidding system.
                </small>
              </div>
              <div className="ct-info">
                <p>
                  <strong>winner: </strong>{" "}
                  <a
                    href="http://"
                    className="ct-address"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ display: "block" }}
                  >
                    {this.state.biddingData?.lastBidder}
                  </a>
                </p>
              </div>
              {finishDate < now &&
              this.state.biddingData?.lastBidder !==
                "0x0000000000000000000000000000000000000000" ? (
                <React.Fragment>
                  <button
                    className="ct-main-button"
                    onClick={() => {
                      if (this.props.blockChain.controller?.dailyCoinBidding) {
                        this.props.blockChain.controller?.dailyCoinBidding?.claim(
                          (error) => {
                            this._onUpdateData(error);
                          }
                        );
                      }
                    }}
                  >
                    Claim
                  </button>
                </React.Fragment>
              ) : parsedAllowance >= parsedNextBid ? (
                <React.Fragment>
                  <button
                    onClick={() => {
                      if (this.props.blockChain.controller?.dailyCoinBidding) {
                        this.props.blockChain.controller?.dailyCoinBidding?.bid(
                          (error) => {
                            this._onUpdateData(error);
                            this.props.onLoadCustomerData(false);
                          }
                        );
                      }
                    }}
                    className="ct-main-button"
                  >
                    Bid ({parsedNextBid.toFixed(3)} MTRG)
                  </button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <button
                    onClick={() => {
                      if (this.props.blockChain.controller?.dailyCoinBidding) {
                        this.props.blockChain.controller?.MTRG?.approve(
                          this.props.blockChain.controller.dailyCoinBidding
                            .address,
                          parsedNextBid + parsedNextBid * 0.05,
                          (error) => this._onUpdateData(error)
                        );
                      }
                    }}
                    className="ct-main-button"
                  >
                    Approve ({parsedNextBid.toFixed(3)} MTRG)
                  </button>
                </React.Fragment>
              )}
            </div>
            <div className="ct-swap">
              <div className="ct-header">
                <h4>Sell</h4>
                <small>Swap your DLCs using the voltswap contracts.</small>
              </div>
              <div className="ct-tokens">
                <div className="ct-token">
                  <div className="ct-logo">
                    <img src={DLCLogo} alt="" />
                  </div>
                  <div className="ct-input">
                    <input type="number" />
                    <small>DLCs</small>
                  </div>
                </div>

                <div className="ct-token">
                  <div className="ct-logo">
                    <img src={MTRGLogo} alt="" />
                  </div>
                  <div className="ct-input">
                    <input type="number" disabled />
                    <small>MTRG</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
