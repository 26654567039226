import { AnyAction } from "redux";
import { Contract } from "../../../core/app";

import {
  ContractData,
  ContractsStateController,
} from "../../../core/contracts";

import { AppActions, AppReducer } from "../../types";

export const contractsKey = AppReducer.CONTRACTS;

export interface ContractsState {
  [Contract.TOKEN]: ContractData | null;
  [Contract.UNIVERSAL_FACTORY]: ContractData | null;
  [Contract.UNIVERSAL_GENERAL]: ContractData | null;
  [Contract.UNIVERSAL_STAKING_FACTORY]: ContractData | null;
  [Contract.UNIVERSAL_MINTER]: ContractData | null;
  [Contract.UNIVERSAL_QUERIES]: ContractData | null;
  [Contract.UNIVERSAL_BIDDING]: ContractData | null;
  [Contract.BATTLE_MINI_GAMES]: ContractData | null;
  [Contract.UNIVERSAL_MINI_BIDDING]: ContractData | null;
  [Contract.DISTRIBUTOR]: ContractData | null;
  [Contract.LP_STAKING]: ContractData | null;
  [Contract.VOLT_REWARDS]: ContractData | null;

  [Contract.MTRG]: ContractData | null;
  [Contract.DAILY_COIN]: ContractData | null;
  [Contract.DAILY_COIN_BIDDING]: ContractData | null;
}

export const initialState: ContractsState = {
  [Contract.TOKEN]: null,
  [Contract.UNIVERSAL_FACTORY]: null,
  [Contract.UNIVERSAL_GENERAL]: null,
  [Contract.UNIVERSAL_MINTER]: null,
  [Contract.UNIVERSAL_STAKING_FACTORY]: null,
  [Contract.UNIVERSAL_QUERIES]: null,
  [Contract.UNIVERSAL_BIDDING]: null,
  [Contract.BATTLE_MINI_GAMES]: null,
  [Contract.UNIVERSAL_MINI_BIDDING]: null,
  [Contract.DISTRIBUTOR]: null,
  [Contract.LP_STAKING]: null,
  [Contract.VOLT_REWARDS]: null,

  [Contract.MTRG]: null,
  [Contract.DAILY_COIN]: null,
  [Contract.DAILY_COIN_BIDDING]: null,
};

export const contractsReducer = (
  state: ContractsState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case AppActions.SET_MULTIPLE_CONTRACT_ABI:
      return ContractsStateController.setMultipleContractsAbi(
        state,
        action.contracts
      );
    default:
      return state;
  }
};
