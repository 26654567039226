import { appConfig, AppMode } from "../app";

import FTBImage from "../../assets/images/BB_LOGO.svg";
import MTRGImage from "../../assets/images/meter-network.png";
import VoltImage from "../../assets/images/VOLT.png";
import KeyImage from "../../assets/images/key.gif";
import CoinAnimation from "../../assets/images/coin_animation.gif";
import QuestionMark from "../../assets/images/question.png";
import MangaPunk from "../../assets/images/MANGA_PUNK.png";

export class UtilsHelpers {
  static toHexadecimal(number: string) {
    if (!isNaN(Number(number))) return parseInt(number, 16).toString();
    else console.log("[UTILS] To Hexadecimal error.");
  }

  static normalizeWei(wei: string) {
    return !isNaN(Number(wei)) ? Number(wei) / Math.pow(10, 18) : NaN;
  }

  static debugger(log: string) {
    if (
      appConfig.mode === AppMode.DEV ||
      appConfig.mode === AppMode.TEST ||
      appConfig.mode === AppMode.TEST_METER ||
      appConfig.mode === AppMode.TEST_POLYGON
    ) {
      console.warn("[DEBUG] " + log);
    }
  }

  static parseSeconds(timeInSeconds: number) {
    const minutes = timeInSeconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;

    return timeInSeconds < 60
      ? timeInSeconds.toFixed() + " Seconds"
      : minutes < 60
      ? minutes.toFixed() + " Minutes"
      : hours < 48
      ? hours.toFixed() + " Hours"
      : days.toFixed() + " Days";
  }

  static timestampToHours(miliseconds: number) {
    return (miliseconds / 1000 / 60 / 60).toFixed(1);
  }

  static parseMoney(money: number) {
    return Intl.NumberFormat().format(money);
  }

  static getTokenImageBySymbol(symbol: string) {
    switch (symbol) {
      case "MTRG":
        return MTRGImage;
      case "VOLT":
        return VoltImage;
      default:
        return FTBImage;
    }
  }

  static getTokenSymbolByAdress(address: string) {
    switch (address.toLocaleLowerCase()) {
      case "0x6cfe9adad5215195c1aa9755daed29360e6ab986".toLocaleLowerCase():
      case "0x1d12e9f21dc4145689d8b9f1d3c1eb6ed6e6cc68".toLocaleLowerCase():
        return "FTB";
      case "0x228ebbee999c6a7ad74a6130e81b12f9fe237ba3".toLocaleLowerCase():
      case "0x8a419ef4941355476cf04933e90bf3bbf2f73814".toLocaleLowerCase():
        return "MTRG";
      case "0x6af7f7cddfd0d69ba9b03863347bd762bd70f8dc".toLocaleLowerCase():
      case "0x8df95e66cb0ef38f91d2776da3c921768982fba0".toLocaleLowerCase():
        return "VOLT";
      case "0x24aa189dfaa76c671c279262f94434770f557c35".toLocaleLowerCase():
        return "BUSD";
      default:
        return "?";
    }
  }

  static getCollectionNameByAddress(address: string) {
    switch (address.toLocaleLowerCase()) {
      //Testnet
      case "0x0Cbc6aD09F0c7fBE4d1978172905c2c3b117901D".toLocaleLowerCase():
      case "0x604e65fb1d1201f6726a55c2c97a528af0c859ac".toLocaleLowerCase(): //Mainnet
        return "Universal NFTs";
      case "0x04cc04b1a2ad782b170f0c4714acefdf0b6b63be".toLocaleLowerCase(): //Special staking NFTs Mainnet
        return "Special staking NFTs";
      case "0X34CF3D594D152F30F7E035656E63A66BF3957060".toLocaleLowerCase():
      case "0xaefeb8c7351b033a7ecc53babd1cdbdb0a255993".toLocaleLowerCase(): //Mainnet
        return "Universal Keys";
      case "0xf7501798e815da0928cc98cf9760fb7920a9b0e1".toLocaleLowerCase():
      case "0xb674576c15c582dc582ddcfcfaf5e04f9ad603cd".toLocaleLowerCase(): //Mainnet
        return "Manga Punks";

      default:
        return "?";
    }
  }

  static getCollectionImageBySymbol(symbol: string) {
    switch (symbol) {
      case "CKL":
        return KeyImage;
      case "UNIS":
        return CoinAnimation;
      default:
        return null;
    }
  }

  static getCollectionImageByAddress(address: string) {
    switch (address.toLocaleLowerCase()) {
      //Testnet
      case "0x0Cbc6aD09F0c7fBE4d1978172905c2c3b117901D".toLocaleLowerCase():
      case "0x604e65fb1d1201f6726a55c2c97a528af0c859ac".toLocaleLowerCase(): // Mainnet
      case "0x04cc04b1a2ad782b170f0c4714acefdf0b6b63be".toLocaleLowerCase(): // Special staking NFTs Mainnet
        return FTBImage;
      case "0X34CF3D594D152F30F7E035656E63A66BF3957060".toLocaleLowerCase():
      case "0xaefeb8c7351b033a7ecc53babd1cdbdb0a255993".toLocaleLowerCase(): // Mainnet
        return KeyImage;
      case "0xf7501798e815da0928cc98cf9760fb7920a9b0e1".toLocaleLowerCase():
      case "0xb674576c15c582dc582ddcfcfaf5e04f9ad603cd".toLocaleLowerCase(): // Mainnet
        return MangaPunk;

      default:
        return QuestionMark;
    }
  }

  static shuffle(array: any[]) {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  }

  static validateFiles(files: File[], validType: string[]) {
    let isValid = true;

    for (let i = 0; i < files.length; i++) {
      let validated = false;

      for (let j = 0; j < validType.length; j++) {
        if (files[i].type === validType[j]) {
          validated = true;
          break;
        }
      }

      if (validated === false) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  static validateFilesSize(files: File[], maxSize: number) {
    let isValid = true;

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxSize) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }
}
