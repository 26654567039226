import React from "react";
import Dropzone from "react-dropzone";
import Web3 from "web3";
import Slider from "@mui/material/Slider";

import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { TokenController } from "../../core/modules/token";
import { UtilsHelpers } from "../../core/helpers/utils";
import { appConfig, AppErrorCode, Contract } from "../../core/app";
import { UniverseNFTController } from "../../core/modules/universeNFT";
import { ApiHelpers } from "../../core/helpers/api";
import { toast } from "react-toastify";

import IERC721 from "../../assets/contracts/static/ERC721.json";

import SwordIcon from "../../assets/icons/sword.png";
import DrawCitiesBackground from "../../assets/images/drawnCities.png";
import SwordImage from "../../assets/images/battles/sword.jpg";

import { FollowCursorCard } from "../organisms/cardModels/followCursor";
import { CloseButton } from "react-toastify/dist/components";
import { Stats } from "fs";
import { BlockChainHelpers } from "../../core/helpers/chain";

const CARD_OPTIONS = [
  {
    option: 1,
    life: 0,
    damage: [0, 30],
    title: "ATTACK",
    image: SwordImage,
  },
  {
    option: 2,
    life: [0, 30],
    damage: 0,
    title: "DEFEND",
    image: SwordImage,
  },
  {
    option: 3,
    life: [0, 15],
    damage: [0, 15],
    title: "BALANCED",
    image: SwordImage,
  },
];

interface BattleVSComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface BattleVSComponentState {
  partyMembers: any[];
  validNFTs: any[];
  partyMembersShow: boolean;
  prizesShow: boolean;
  validNFTsShow: boolean;
  nftSelectorShow: boolean;
  accountShow: boolean;
  error: string | null;
  loadedNFTs: any[];
  loadedCollectionName: string;
  gameData: any;
  tokenAllowance: string;
  team: any[];
  selectorPosition: number;
  accountManagerShow: boolean;
  balanceHistoryShow: boolean;
  teamHistoryShow: boolean;
  withdrawValue: string;
  injectValue: string;
  gameplayValue: string;
  balanceHistory: any[];
  teamHistory: any[];
  opponentData: any;
  customerData: any;
  selectedFightPercentage: number;
  walletHash: string | null;
  battle: any;
  attackInfo: any;
  winnerInfo: any;
  loader: boolean;
  rules: boolean;
  selectedCollection: string;
  generationNFTs: number[];
  preloadedField: {
    firstCustomer: any;
    secondCustomer: any;
    firstSelected: any;
    secondSelected: any;
  };
}

export class BattleVSPage extends React.PureComponent<BattleVSComponentProps, BattleVSComponentState> {
  constructor(props: BattleVSComponentProps) {
    super(props);

    this.state = {
      partyMembers: [],
      validNFTs: [],
      partyMembersShow: false,
      prizesShow: false,
      validNFTsShow: true,
      nftSelectorShow: false,
      accountShow: false,
      accountManagerShow: false,
      balanceHistoryShow: false,
      teamHistoryShow: false,
      error: null,
      loadedNFTs: [],
      loadedCollectionName: "",
      gameData: null,
      tokenAllowance: "",
      team: [],
      selectorPosition: 0,
      withdrawValue: "",
      injectValue: "",
      gameplayValue: "",
      balanceHistory: [],
      teamHistory: [],
      customerData: null,
      opponentData: null,
      selectedFightPercentage: 50,
      walletHash: null,
      attackInfo: null,
      loader: false,
      selectedCollection: "",
      battle: null,
      rules: false,
      winnerInfo: null,
      generationNFTs: [],
      preloadedField: {
        firstCustomer: null,
        secondCustomer: null,
        firstSelected: null,
        secondSelected: null,
      },
    };
  }

  async componentDidMount() {
    this.preloadControllers();
    await this.preloadGameData();
    await this.loadBattlesData();
  }

  async preloadControllers(closeLoader: boolean = false) {
    this.props.onToggleLoader(false);
  }

  async preloadGameData(callback?: () => void) {
    if (this.props.blockChain.controller && this.props.blockChain.controller.selectedAccount) {
      const gameData = await this.props.blockChain.controller.battleMiniGames?.getGameData();
      const party = await ApiHelpers.getUniversalBattleParty();
      const customerData = await ApiHelpers.getUniversalBattlePartyMember(this.props.blockChain.controller.selectedAccount);

      if (gameData && customerData.inmutable && customerData.simple) {
        let partyMembers: any = gameData.members.map((member: any) => {
          const simplePartyMemberData = party.simple.find(
            (partyData: any) => partyData._address.toLocaleLowerCase() === member.owner.toLocaleLowerCase()
          );

          const inmutablePartyData = party.inmutable.find(
            (partyData: any) => partyData.address.toLocaleLowerCase() === member.owner.toLocaleLowerCase()
          );

          if (simplePartyMemberData && inmutablePartyData && member.isOnParty) {
            return {
              simple: simplePartyMemberData,
              inmutable: { ...inmutablePartyData, balance: Number(Web3.utils.fromWei(inmutablePartyData.balance || "0")) },
              block: member,
            };
          } else return undefined;
        });

        partyMembers = partyMembers.filter((member: any) => !!member).sort((a: any, b: any) => b.inmutable.balance - a.inmutable.balance);

        this.setState(
          {
            gameData,
            customerData: {
              inmutable: {
                ...customerData?.inmutable[0],
                balance: Number(Web3.utils.fromWei(customerData?.inmutable[0]?.balance || "0")),
                ...customerData?.inmutableField[0],
                amount: customerData?.inmutableField[0]?.amount ? Number(Web3.utils.fromWei(customerData?.inmutableField[0]?.amount || "0")) : 0,
                underAttack:
                  customerData?.inmutableField[0]?.defendTeam?.address?.toLocaleLowerCase() ===
                  this.props.blockChain.controller.selectedAccount.toLocaleLowerCase(),
                attacking:
                  customerData?.inmutableField[0]?.attackTeam?.address?.toLocaleLowerCase() ===
                  this.props.blockChain.controller.selectedAccount.toLocaleLowerCase(),
                active: !!customerData?.inmutable[0]?.address,
              },
              simple: customerData?.simple[0],
            },
            partyMembers: partyMembers,
            team: customerData?.inmutable[0]?.team
              ? customerData?.inmutableField[0]?.active
                ? [
                    {
                      collection: customerData?.inmutableField[0]?.attackTeam?.attack?.collection,
                      id: customerData?.inmutableField[0]?.attackTeam?.attack?.id,
                      life: customerData?.inmutableField[0]?.attackTeam?.attack?.life,
                      power: customerData?.inmutableField[0]?.attackTeam?.attack?.power,
                      active: true,
                      battle: true,
                    },
                    {
                      collection: customerData?.inmutableField[0]?.attackTeam?.defend?.collection,
                      id: customerData?.inmutableField[0]?.attackTeam?.defend?.id,
                      life: customerData?.inmutableField[0]?.attackTeam?.defend?.life,
                      power: customerData?.inmutableField[0]?.attackTeam?.defend?.power,
                      active: true,
                      battle: true,
                    },
                    {
                      collection: customerData?.inmutableField[0]?.defendTeam?.attack?.collection,
                      id: customerData?.inmutableField[0]?.defendTeam?.attack?.id,
                      life: customerData?.inmutableField[0]?.defendTeam?.attack?.life,
                      power: customerData?.inmutableField[0]?.defendTeam?.attack?.power,
                      active: true,
                      battle: true,
                    },
                    {
                      collection: customerData?.inmutableField[0]?.defendTeam?.defend?.collection,
                      id: customerData?.inmutableField[0]?.defendTeam.defend.id,
                      life: customerData?.inmutableField[0]?.defendTeam?.defend?.life,
                      power: customerData?.inmutableField[0]?.defendTeam?.defend?.power,
                      active: true,
                      battle: true,
                    },
                  ]
                : [
                    {
                      collection: customerData?.inmutable[0]?.team?.attack?.collection,
                      id: customerData?.inmutable[0]?.team.attack.id,
                      active: true,
                      battle: false,
                    },
                    {
                      collection: customerData?.inmutable[0]?.team?.defend?.collection,
                      id: customerData?.inmutable[0]?.team.defend.id,
                      active: true,
                      battle: false,
                    },
                  ]
              : [],
          },
          () => (callback ? this.preloadTeamNFTs(callback) : this.preloadTeamNFTs())
        );
      }
    }
  }

  async loadBattlesData(callback?: () => void) {
    if (this.props.blockChain.controller) {
      const validNFTs = await ApiHelpers.getUniversalBattlesValidNFTs();
      const party = await ApiHelpers.getUniversalBattleParty();
      const gameData = await this.props.blockChain.controller.battleMiniGames?.getGameData();

      if (Array.isArray(validNFTs.data)) {
        let partyMembers: any = gameData.members.map((member: any) => {
          const simplePartyMemberData = party.simple.find(
            (partyData: any) => partyData._address.toLocaleLowerCase() === member.owner.toLocaleLowerCase()
          );

          const inmutablePartyData = party.inmutable.find(
            (partyData: any) => partyData.address.toLocaleLowerCase() === member.owner.toLocaleLowerCase()
          );

          if (simplePartyMemberData && inmutablePartyData && member.isOnParty) {
            return {
              simple: simplePartyMemberData,
              inmutable: { ...inmutablePartyData, balance: Number(Web3.utils.fromWei(inmutablePartyData.balance || "0")) },
              block: member,
            };
          } else return undefined;
        });

        partyMembers = partyMembers.filter((member: any) => !!member).sort((a: any, b: any) => b.inmutable.balance - a.inmutable.balance);

        this.setState(
          {
            validNFTs: validNFTs.data,
            partyMembers: partyMembers,
            gameData,
          },
          () => {
            if (callback) callback();
          }
        );
      }
    }
  }

  async loadNFTs(address: string, name: string, callback?: () => void) {
    if (
      this.props.blockChain.controller?._web3 &&
      this.props.blockChain.controller.selectedAccount &&
      this.props.blockChain.controller.battleMiniGames
    ) {
      const customerNFTs = await this.props.blockChain.controller.universalQueries?.getAllCustomerNFTs(
        address,
        this.props.blockChain.controller.selectedAccount
      );

      if (Array.isArray(customerNFTs) && customerNFTs.length > 0) {
        const statsNFTs = await this.props.blockChain.controller.battleMiniGames?.getAllNFTsStats(address, customerNFTs);
        const loadedNFTs = await ApiHelpers.preloadExternalNFTs(address, customerNFTs);

        const tokenAllowance = await this.props.blockChain.controller.token?.allowance(
          this.props.blockChain.controller.selectedAccount,
          this.props.blockChain.controller.battleMiniGames.address
        );

        if (loadedNFTs && !loadedNFTs.error && Array.isArray(loadedNFTs.data) && Array.isArray(statsNFTs)) {
          const dataNFTs = loadedNFTs.data.map((nft: any) => {
            const searchedNFT = statsNFTs.find((stats) => Number(stats.id) === Number(nft._nftID));
            return { ...nft, life: searchedNFT.life, power: searchedNFT.power, active: searchedNFT.active };
          });

          this.setState({ loadedNFTs: dataNFTs, loadedCollectionName: name, tokenAllowance, generationNFTs: [], selectedCollection: address }, () => {
            if (callback) callback();
          });
        } else if (callback) callback();
      } else toast.info("Maybe you don't have NFTs from this collection.");
    }
  }

  async preloadTokenAllowance() {
    if (this.props.blockChain.controller && this.props.blockChain.controller.selectedAccount && this.props.blockChain.controller.battleMiniGames) {
      const tokenAllowance = await this.props.blockChain.controller.token?.allowance(
        this.props.blockChain.controller.selectedAccount,
        this.props.blockChain.controller.battleMiniGames.address
      );

      this.setState({ tokenAllowance });
    }
  }

  async preloadTeamNFTs(callback?: () => void) {
    if (this.props.blockChain.controller) {
      let firstCustomer = null;
      let secondCustomer = null;
      let firstSelected = null;
      let secondSelected = null;

      if (this.state.team[0] && this.state.team[0]?.collection) {
        const data = (await ApiHelpers.preloadExternalNFTs(this.state.team[0].collection, [this.state.team[0].id]))?.data;

        if (this.state.team[0]?.battle) {
          if (data && data[0]) firstCustomer = { ...data[0], power: this.state.team[0].power, life: this.state.team[0].life };
        } else {
          const stats = await this.props.blockChain.controller.battleMiniGames?.getAllNFTsStats(this.state.team[0].collection, [
            this.state.team[0].id,
          ]);

          if (data && data[0]) firstCustomer = { ...data[0], power: stats[0].power, life: stats[0].life };
        }
      }

      if (this.state.team[1] && this.state.team[1]?.collection) {
        const data = (await ApiHelpers.preloadExternalNFTs(this.state.team[1].collection, [this.state.team[1].id]))?.data;
        if (this.state.team[1]?.battle) {
          if (data && data[0]) secondCustomer = { ...data[0], power: this.state.team[1].power, life: this.state.team[1].life };
        } else {
          const stats = await this.props.blockChain.controller.battleMiniGames?.getAllNFTsStats(this.state.team[1].collection, [
            this.state.team[1].id,
          ]);

          if (data && data[0]) secondCustomer = { ...data[0], power: stats[0].power, life: stats[0].life };
        }
      }

      if (this.state.team[2] && this.state.team[2]?.collection) {
        const data = (await ApiHelpers.preloadExternalNFTs(this.state.team[2].collection, [this.state.team[2].id]))?.data;
        if (this.state.team[1]?.battle) {
          if (data && data[0]) firstSelected = { ...data[0], power: this.state.team[2].power, life: this.state.team[2].life };
        } else {
          const stats = await this.props.blockChain.controller.battleMiniGames?.getAllNFTsStats(this.state.team[2].collection, [
            this.state.team[2].id,
          ]);

          if (data && data[0]) firstSelected = { ...data[0], power: stats[0].power, life: stats[0].life };
        }
      }

      if (this.state.team[3] && this.state.team[3]?.collection) {
        const data = (await ApiHelpers.preloadExternalNFTs(this.state.team[3].collection, [this.state.team[3].id]))?.data;
        if (this.state.team[1]?.battle) {
          if (data && data[0]) secondSelected = { ...data[0], power: this.state.team[3].power, life: this.state.team[3].life };
        } else {
          const stats = await this.props.blockChain.controller.battleMiniGames?.getAllNFTsStats(this.state.team[3].collection, [
            this.state.team[3].id,
          ]);

          if (data && data[0]) secondSelected = { ...data[0], power: stats[0].power, life: stats[0].life };
        }
      }

      this.setState({ preloadedField: { ...this.state.preloadedField, firstCustomer, secondCustomer, firstSelected, secondSelected } }, () =>
        callback ? callback() : undefined
      );
    }
  }

  async loadBalanceHistory() {
    if (this.props.blockChain.controller?.selectedAccount) {
      if (this.state.balanceHistory.length === 0) {
        const balanceHistory = await ApiHelpers.getPartyMemberBalanceHistory(this.props.blockChain.controller?.selectedAccount.toLocaleLowerCase());
        this.setState({ balanceHistoryShow: true, balanceHistory: Array.isArray(balanceHistory.data) ? balanceHistory.data.reverse() : [] });
      } else this.setState({ balanceHistoryShow: true });
    }
  }

  async loadTeamHistory() {
    if (this.props.blockChain.controller?.selectedAccount) {
      if (this.state.teamHistory.length === 0) {
        const teamHistory = await ApiHelpers.getPartyMemberTeamHistory(this.props.blockChain.controller?.selectedAccount.toLocaleLowerCase());
        this.setState({ teamHistoryShow: true, teamHistory: Array.isArray(teamHistory.data) ? teamHistory.data.reverse() : [] });
      } else this.setState({ teamHistoryShow: true });
    }
  }

  setError(error: string | null) {
    toast.info(error);
  }

  async setOpponentTeam(opponent: any, callback?: () => void) {
    if (this.props.blockChain.controller) {
      if (opponent.inmutable?.team) {
        this.setState(
          {
            opponentData: opponent,
            team: [
              this.state.team[0],
              this.state.team[1],
              {
                collection: opponent.inmutable?.team?.attack?.collection,
                id: opponent.inmutable?.team?.attack?.id,
                battle: false,
                active: true,
              },
              {
                collection: opponent.inmutable?.team?.defend?.collection,
                id: opponent.inmutable?.team?.defend?.id,
                battle: false,
                active: true,
              },
            ],
          },
          () => this.preloadTeamNFTs(callback)
        );
      }
    }
  }

  async claimBattleRewards() {
    if (this.props.blockChain.controller?.selectedAccount) {
      const msg = {
        domain: {
          chainId: parseInt(BlockChainHelpers.getAppChain().chainId, 16),
          name: "uninft.businessbuilders.city",
          verifyingContract: this.props.blockChain.controller?.battleMiniGames?.address,
          version: "1",
        },
        message: {
          action: "CLAIM-BATTLE-REWARDS",
        },
        primaryType: "Request",
        types: {
          EIP712Domain: [
            { name: "name", type: "string" },
            { name: "version", type: "string" },
            { name: "chainId", type: "uint256" },
            {
              name: "verifyingContract",
              type: "address",
            },
          ],
          Request: [
            {
              name: "action",
              type: "string",
            },
          ],
        },
      };

      const eth = BlockChainHelpers.getEthWindow();

      if (eth) {
        const sign = await eth.request({
          method: "eth_signTypedData_v4",
          params: [this.props.blockChain.controller.selectedAccount, JSON.stringify(msg)],
        });

        this.setState({ walletHash: sign }, async () => {
          if (this.state.walletHash) {
            const claimBattle = await ApiHelpers.claimBattleRewards(this.state.walletHash);

            if (claimBattle && claimBattle.error === false) toast.success("Success battle claim.");
            else toast.error(claimBattle.message);

            setTimeout(() => this.preloadGameData(), 5000);
          }
        });
      }
    }
  }

  async fight() {
    this.setState({ loader: true }, async () => {
      if (this.state.customerData?.inmutable && this.state.opponentData?.inmutable && this.props.blockChain.controller?.selectedAccount) {
        const maxFightBalance =
          this.state.opponentData?.inmutable?.balance / 2.1 < this.state.customerData?.inmutable?.balance
            ? this.state.opponentData?.inmutable?.balance / 2.1
            : this.state.customerData?.inmutable?.balance || 0;

        const ammount = ((maxFightBalance || 0) * this.state.selectedFightPercentage) / 100;
        const opponent = this.state.opponentData.inmutable.address.toLocaleLowerCase();

        const msg = {
          domain: {
            chainId: parseInt(BlockChainHelpers.getAppChain().chainId, 16),
            name: "uninft.businessbuilders.city",
            verifyingContract: this.props.blockChain.controller?.battleMiniGames?.address,
            version: "1",
          },
          message: {
            amount: ammount,
            opponent: opponent,
          },
          primaryType: "Request",
          types: {
            EIP712Domain: [
              { name: "name", type: "string" },
              { name: "version", type: "string" },
              { name: "chainId", type: "uint256" },
              {
                name: "verifyingContract",
                type: "address",
              },
            ],
            Request: [
              {
                name: "opponent",
                type: "address",
              },
              {
                name: "amount",
                type: "uint256",
              },
            ],
          },
        };

        const eth = BlockChainHelpers.getEthWindow();

        if (eth) {
          const sign = await eth.request({
            method: "eth_signTypedData_v4",
            params: [this.props.blockChain.controller.selectedAccount, JSON.stringify(msg)],
          });

          this.setState({ walletHash: sign }, async () => {
            if (this.state.walletHash) {
              const createField = await ApiHelpers.startFight(ammount, opponent, this.state.walletHash);

              if (createField && createField.error === false) toast.success("Created battle field.");
              else toast.error(createField.message);

              setTimeout(async () => await this.preloadGameData(() => this.setState({ loader: false })), 3000);
            }
          });
        }
      } else this.setState({ loader: false });
    });
  }

  async attack(option: number) {
    this.setState({ loader: true }, async () => {
      if (this.props.blockChain.controller?.selectedAccount) {
        const msg = {
          domain: {
            chainId: parseInt(BlockChainHelpers.getAppChain().chainId, 16),
            name: "uninft.businessbuilders.city",
            verifyingContract: this.props.blockChain.controller?.battleMiniGames?.address,
            version: "1",
          },
          message: {
            option: option,
            title: CARD_OPTIONS.find((card) => card.option === option)?.title || "NONE",
          },
          primaryType: "Request",
          types: {
            EIP712Domain: [
              { name: "name", type: "string" },
              { name: "version", type: "string" },
              { name: "chainId", type: "uint256" },
              {
                name: "verifyingContract",
                type: "address",
              },
            ],
            Request: [
              {
                name: "option",
                type: "uint256",
              },
              {
                name: "title",
                type: "string",
              },
            ],
          },
        };

        const eth = BlockChainHelpers.getEthWindow();

        if (eth) {
          const sign = await eth.request({
            method: "eth_signTypedData_v4",
            params: [this.props.blockChain.controller.selectedAccount, JSON.stringify(msg)],
          });

          this.setState({ walletHash: sign }, async () => {
            if (this.state.walletHash) {
              const attackData = await ApiHelpers.attackOnBattle(option, this.state.walletHash);

              if (attackData && attackData.error === false) toast.success(attackData.message);
              else toast.error(attackData.message);

              console.log(attackData);

              setTimeout(
                async () =>
                  this.preloadGameData(() =>
                    this.setState({
                      loader: false,
                      attackInfo: !!attackData.data.winner ? null : attackData.data,
                      winnerInfo: !!attackData.data.winner ? attackData.data : null,
                    })
                  ),
                1000
              );
            }
          });
        }
      } else this.setState({ loader: false });
    });
  }

  async changeTeamMember(position: number, newMember: any, callback?: () => void) {
    this.setState({ loader: true }, async () => {
      if (this.props.blockChain.controller?.battleMiniGames && this.state.customerData?.inmutable?.active) {
        const msg = {
          domain: {
            chainId: parseInt(BlockChainHelpers.getAppChain().chainId, 16),
            name: "uninft.businessbuilders.city",
            verifyingContract: this.props.blockChain.controller?.battleMiniGames?.address,
            version: "1",
          },
          message: {
            owner: this.props.blockChain.controller.selectedAccount?.toLocaleLowerCase(),
            newMemberCollection: newMember.collection,
            newMemberID: newMember.id,
            position: position,
          },
          primaryType: "Request",
          types: {
            EIP712Domain: [
              { name: "name", type: "string" },
              { name: "version", type: "string" },
              { name: "chainId", type: "uint256" },
              {
                name: "verifyingContract",
                type: "address",
              },
            ],
            Request: [
              {
                name: "owner",
                type: "address",
              },
              {
                name: "newMemberCollection",
                type: "address",
              },
              {
                name: "newMemberID",
                type: "uint256",
              },
              {
                name: "position",
                type: "uint256",
              },
            ],
          },
        };

        const eth = BlockChainHelpers.getEthWindow();

        if (eth) {
          const sign = await eth.request({
            method: "eth_signTypedData_v4",
            params: [this.props.blockChain.controller.selectedAccount, JSON.stringify(msg)],
          });

          this.setState({ walletHash: sign }, async () => {
            if (this.state.walletHash) {
              const changeTeamMember = await ApiHelpers.changeTeamMember(position, newMember, this.state.walletHash);

              if (changeTeamMember && changeTeamMember.error === false) toast.success("Udpated team.");
              else toast.error(changeTeamMember.message);

              setTimeout(() => this.preloadGameData(() => this.setState({ loader: false })), 5000);
            }
          });
        }
      } else this.setState({ loader: false });
    });
  }

  async addGenerationNFT(id: number) {
    const generationState = [...this.state.generationNFTs];
    generationState.push(id);
    this.setState({ generationNFTs: generationState });
  }

  async removeGenerationNFT(id: number) {
    const generationState = [...this.state.generationNFTs];
    const searchIndex = generationState.findIndex((all: any) => all === id);
    if (searchIndex !== -1) generationState.splice(searchIndex, 1);
    this.setState({ generationNFTs: generationState });
  }

  render() {
    const tokenAllowance = Number(Web3.utils.fromWei(this.state.tokenAllowance || "0"));
    const initialPrice = Number(Web3.utils.fromWei(this.state.gameData?.data?.generationPrice || "0"));
    const generationPrice = Number(Web3.utils.fromWei(this.state.gameData?.data?.generationPrice || "0"));
    const contractBalance = Number(Web3.utils.fromWei(this.state.gameData?.member?.balance || "0"));

    const maxFightBalance =
      this.state.opponentData?.inmutable?.balance / 2 < this.state.customerData?.inmutable?.balance
        ? this.state.opponentData?.inmutable?.balance / 2
        : this.state.customerData?.inmutable?.balance || 0;

    const attackTeamAverage = [
      this.state.preloadedField?.firstCustomer && this.state.preloadedField?.secondCustomer
        ? Math.floor((Number(this.state.preloadedField.firstCustomer.power) + Number(this.state.preloadedField.secondCustomer.power)) / 2)
        : 0,
      this.state.preloadedField?.firstCustomer && this.state.preloadedField?.secondCustomer
        ? Math.floor((Number(this.state.preloadedField.firstCustomer.life) + Number(this.state.preloadedField.secondCustomer.life)) / 2)
        : 0,
    ];

    return (
      <React.Fragment>
        <div className="ct-battles-container ct-max-container">
          <div className="ct-battle-data">
            <div className="ct-party-members">
              <h4>
                Battle members <small>({this.state.partyMembers.length}/20)</small>
              </h4>
              {this.state.partyMembersShow
                ? this.state.partyMembers.map((member, index) => {
                    return (
                      <div
                        className="ct-member"
                        onClick={() => {
                          if (!this.state.customerData?.inmutable?.attacking && !this.state.customerData?.inmutable?.underAttack) {
                            if (member.inmutable.balance >= this.state.customerData?.inmutable?.balance) {
                              this.setState({ loader: true }, () => {
                                this.setOpponentTeam(member, () => this.setState({ loader: false }));
                              });
                            } else toast.error("You can't fight with opponents with lower balance than you.");
                          }
                        }}
                        key={index}
                      >
                        <img src={member.simple._image} alt="" />
                        <span>{member.simple._name}</span>
                        <div className="ct-team">
                          <img src={member.simple._team[0]?._attributes?.image} alt="" />
                          <img src={member.simple._team[1]?._attributes?.image} alt="" />
                        </div>
                        <span>{member.inmutable.balance.toFixed(2)} MTRG</span>
                        {!this.state.customerData?.inmutable?.attacking && !this.state.customerData?.inmutable?.underAttack ? (
                          <div className="ct-battle-shadow">
                            <img className="ct-sword" src={SwordIcon} alt="" />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })
                : ""}
              <div
                className="ct-close-bar"
                onClick={() => {
                  this.setState({ partyMembersShow: !this.state.partyMembersShow });
                }}
              >
                <span className="fas fa-chevron-up"></span>
              </div>
            </div>
            <div className="ct-prizes">
              <h4>Distribution</h4>
              {this.state.prizesShow ? (
                <React.Fragment>
                  <div className="ct-prize">
                    <span>
                      <span className="fas fa-award"></span> First place:{" "}
                      {Number(Web3.utils.fromWei(this.state.gameData?.distribution.prizes[0] || "0")).toFixed(2)} MTRG
                    </span>
                  </div>
                  <div className="ct-prize">
                    <span>
                      <span className="fas fa-award"></span> Second place:{" "}
                      {Number(Web3.utils.fromWei(this.state.gameData?.distribution.prizes[1] || "0")).toFixed(2)} MTRG
                    </span>
                  </div>
                  <div className="ct-prize">
                    <span>
                      <span className="fas fa-award"></span> Third place:{" "}
                      {Number(Web3.utils.fromWei(this.state.gameData?.distribution.prizes[2] || "0")).toFixed(2)} MTRG
                    </span>
                  </div>
                  <div className="ct-prize">
                    <span>
                      <span className="fas fa-sync"></span> FTB buy-burn:{" "}
                      {Number(Web3.utils.fromWei(this.state.gameData?.distribution.buyBack || "0")).toFixed(2)} MTRG
                    </span>
                    <small>Next week DLCs</small>
                  </div>
                  <div className="ct-prize">
                    <span>
                      <span className="fas fa-sync"></span> Staking pool:{" "}
                      {Number(Web3.utils.fromWei(this.state.gameData?.distribution.staking || "0")).toFixed(2)} MTRG
                    </span>
                    <small>Special NFTs staking</small>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}
              <div className="ct-close-bar" onClick={() => this.setState({ prizesShow: !this.state.prizesShow })}>
                <span className="fas fa-chevron-up"></span>
              </div>
            </div>
            <div className="ct-account">
              <h4>Account</h4>
              {this.state.accountShow ? (
                <React.Fragment>
                  <div className="ct-data">
                    <span>Contract Balance: {Web3.utils.fromWei(this.state.gameData?.member?.balance || "0")} MTRG</span>
                    <span>Gameplay balance: {this.state.customerData?.inmutable?.balance} MTRG</span>
                  </div>
                  <button onClick={() => this.setState({ accountManagerShow: true })} className="ct-main-button">
                    Manage account
                  </button>
                  <div className="ct-data ct-history">
                    <span className="ct-little-click" onClick={() => this.loadBalanceHistory()}>
                      Balance history
                    </span>
                    <span className="ct-little-click" onClick={() => this.loadTeamHistory()}>
                      Team history
                    </span>
                    <span className="ct-little-click" onClick={() => this.loadBalanceHistory()}>
                      Fight history
                    </span>
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}

              <div
                className="ct-close-bar"
                onClick={() => {
                  this.setState({ accountShow: !this.state.accountShow });
                }}
              >
                <span className="fas fa-chevron-up"></span>
              </div>
            </div>
            <div className="ct-rules">
              <h4 onClick={() => this.setState({ rules: !this.state.rules })}>Rules</h4>
            </div>
            <div className="ct-copy">
              <h4>By Drawn Meter cities</h4>
            </div>
          </div>
          <div className="ct-battles">
            <img src={DrawCitiesBackground} alt="" />
            <div className="ct-battle-field">
              <div className="ct-team">
                <div className="ct-nft">
                  <FollowCursorCard
                    children={
                      this.state.preloadedField.firstSelected ? (
                        <div className="ct-field-nft">
                          <img src={this.state.preloadedField.firstSelected?._attributes?.image} alt="" />
                          <div className="ct-data">
                            <div className="ct-stats">
                              <span className="ct-power">
                                <span
                                  style={{ width: ((Number(this.state.preloadedField?.firstSelected?.power) * 100) / 100).toString() + "%" }}
                                  className="ct-bar"
                                ></span>
                                <small>Attack: {this.state.preloadedField?.firstSelected?.power} / 100</small>
                              </span>
                              <span className="ct-life">
                                <span
                                  style={{ width: ((Number(this.state.preloadedField?.firstSelected?.life) * 100) / 100).toString() + "%" }}
                                  className="ct-bar"
                                ></span>
                                <small> Life: {this.state.preloadedField?.firstSelected?.life} / 100</small>
                                {this.state.attackInfo
                                  ? (() => {
                                      const diff =
                                        this.state.attackInfo?.attackData?.increaseLifeOpponent - this.state.attackInfo.attackData.reduceLifeOpponent;

                                      if (diff < 0) return <span className="ct-anim ct-anim-reduce">{" - " + Math.abs(diff)} </span>;
                                      else if (diff > 0) return <span className="ct-anim ct-anim-increase">{" + " + Math.abs(diff)} </span>;
                                      else return <span className="ct-anim ct-anim-same">{diff}</span>;
                                    })()
                                  : ""}
                              </span>
                              <span className="ct-total">
                                <span
                                  style={{
                                    width:
                                      (
                                        ((Number(this.state.preloadedField?.firstSelected?.life) +
                                          Number(this.state.preloadedField?.firstSelected?.power)) *
                                          100) /
                                        200
                                      ).toString() + "%",
                                  }}
                                  className="ct-bar"
                                ></span>
                                <small>
                                  {" "}
                                  Power:{" "}
                                  {Number(this.state.preloadedField?.firstSelected?.life) + Number(this.state.preloadedField?.firstSelected?.power)} /
                                  200
                                </small>
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="ct-add-nft"
                          onClick={() => {
                            this.setError("Choose the opponent team on the battle members panel.");
                            this.setState({ partyMembersShow: true });
                          }}
                        >
                          <img className="ct-sword" src={SwordIcon} alt="" />
                          <span className="fas fa-plus"></span>
                        </div>
                      )
                    }
                  />
                </div>
                <div className="ct-nft">
                  <FollowCursorCard
                    children={
                      this.state.preloadedField.secondSelected ? (
                        <div className="ct-field-nft">
                          <img src={this.state.preloadedField.secondSelected?._attributes?.image} alt="" />
                          <div className="ct-data">
                            <div className="ct-stats">
                              <span className="ct-power">
                                <span
                                  style={{ width: ((Number(this.state.preloadedField?.secondSelected?.power) * 100) / 100).toString() + "%" }}
                                  className="ct-bar"
                                ></span>
                                <small>Attack: {this.state.preloadedField?.secondSelected?.power} / 100</small>
                              </span>
                              <span className="ct-life">
                                <span
                                  style={{ width: ((Number(this.state.preloadedField?.secondSelected?.life) * 100) / 100).toString() + "%" }}
                                  className="ct-bar"
                                ></span>
                                <small> Life: {this.state.preloadedField?.secondSelected?.life} / 100</small>
                                {this.state.attackInfo
                                  ? (() => {
                                      const diff =
                                        this.state.attackInfo?.attackData?.increaseLifeOpponent - this.state.attackInfo.attackData.reduceLifeOpponent;

                                      if (diff < 0) return <span className="ct-anim ct-anim-reduce">{" - " + Math.abs(diff)} </span>;
                                      else if (diff > 0) return <span className="ct-anim ct-anim-increase">{" + " + Math.abs(diff)} </span>;
                                      else return <span className="ct-anim ct-anim-same">{diff}</span>;
                                    })()
                                  : ""}
                              </span>
                              <span className="ct-total">
                                <span
                                  style={{
                                    width:
                                      (
                                        ((Number(this.state.preloadedField?.secondSelected?.life) +
                                          Number(this.state.preloadedField?.secondSelected?.power)) *
                                          100) /
                                        200
                                      ).toString() + "%",
                                  }}
                                  className="ct-bar"
                                ></span>
                                <small>
                                  {" "}
                                  Power:{" "}
                                  {Number(this.state.preloadedField?.secondSelected?.life) +
                                    Number(this.state.preloadedField?.secondSelected?.power)}{" "}
                                  / 200
                                </small>
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="ct-add-nft"
                          onClick={() => {
                            this.setError("Choose the opponent team on the battle members panel.");
                            this.setState({ partyMembersShow: true });
                          }}
                        >
                          <img className="ct-sword" src={SwordIcon} alt="" />
                          <span className="fas fa-plus"></span>
                        </div>
                      )
                    }
                  />
                </div>
              </div>
              <div className="ct-team">
                <div className="ct-nft">
                  <FollowCursorCard
                    children={
                      this.state.team[0]?.active ? (
                        this.state.preloadedField.firstCustomer ? (
                          <div className="ct-field-nft">
                            <div className="ct-data">
                              <div className="ct-stats">
                                <span className="ct-power">
                                  <span
                                    style={{ width: ((Number(this.state.preloadedField?.firstCustomer?.power) * 100) / 100).toString() + "%" }}
                                    className="ct-bar"
                                  ></span>
                                  <small>Attack: {this.state.preloadedField?.firstCustomer?.power} / 100</small>
                                </span>
                                <span className="ct-life">
                                  <span
                                    style={{ width: ((Number(this.state.preloadedField?.firstCustomer?.life) * 100) / 100).toString() + "%" }}
                                    className="ct-bar"
                                  ></span>
                                  <small> Life: {this.state.preloadedField?.firstCustomer?.life} / 100</small>
                                  {this.state.attackInfo
                                    ? (() => {
                                        const diff =
                                          this.state.attackInfo?.attackData?.increaseLifeCustomer -
                                          this.state.attackInfo.attackData.reduceLifeCustomer;

                                        if (diff < 0) return <span className="ct-anim ct-anim-reduce">{" - " + Math.abs(diff)} </span>;
                                        else if (diff > 0) return <span className="ct-anim ct-anim-increase">{" + " + Math.abs(diff)} </span>;
                                        else return <span className="ct-anim ct-anim-same">{diff}</span>;
                                      })()
                                    : ""}
                                </span>
                                <span className="ct-total">
                                  <span
                                    style={{
                                      width:
                                        (
                                          ((Number(this.state.preloadedField?.firstCustomer?.life) +
                                            Number(this.state.preloadedField?.firstCustomer?.power)) *
                                            100) /
                                          200
                                        ).toString() + "%",
                                    }}
                                    className="ct-bar"
                                  ></span>
                                  <small>
                                    {" "}
                                    Power:{" "}
                                    {Number(this.state.preloadedField?.firstCustomer?.life) +
                                      Number(this.state.preloadedField?.firstCustomer?.power)}{" "}
                                    / 200
                                  </small>
                                </span>
                              </div>
                            </div>
                            <img src={this.state.preloadedField?.firstCustomer?._attributes?.image} alt="" />
                            {!this.state.customerData?.inmutable?.attacking && !this.state.customerData?.inmutable?.underAttack ? (
                              <span
                                onClick={() => {
                                  this.setState({ nftSelectorShow: true, selectorPosition: 0 });
                                }}
                                className="ct-edit fas fa-sync"
                              ></span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          "Invalid NFT"
                        )
                      ) : (
                        <div
                          className="ct-add-nft"
                          onClick={() => {
                            this.setError("Select your favorite collection and if you have NFTs it will load in the next seconds.");
                            this.setState({ nftSelectorShow: true, selectorPosition: 0 });
                          }}
                        >
                          <img className="ct-sword" src={SwordIcon} alt="" />
                          <span className="fas fa-plus"></span>
                        </div>
                      )
                    }
                  />
                </div>
                <div className="ct-nft">
                  <FollowCursorCard
                    children={
                      this.state.team[1]?.active ? (
                        this.state.preloadedField.secondCustomer ? (
                          <div className="ct-field-nft">
                            <img src={this.state.preloadedField.secondCustomer?._attributes?.image} alt="" />
                            <div className="ct-data">
                              <div className="ct-stats">
                                <span className="ct-power">
                                  <span
                                    style={{ width: ((Number(this.state.preloadedField?.secondCustomer?.power) * 100) / 100).toString() + "%" }}
                                    className="ct-bar"
                                  ></span>
                                  <small>Attack: {this.state.preloadedField?.secondCustomer?.power} / 100</small>
                                </span>
                                <span className="ct-life">
                                  <span
                                    style={{ width: ((Number(this.state.preloadedField?.secondCustomer?.life) * 100) / 100).toString() + "%" }}
                                    className="ct-bar"
                                  ></span>
                                  <small> Life: {this.state.preloadedField?.secondCustomer?.life} / 100</small>
                                  {this.state.attackInfo
                                    ? (() => {
                                        const diff =
                                          this.state.attackInfo?.attackData?.increaseLifeCustomer -
                                          this.state.attackInfo.attackData.reduceLifeCustomer;

                                        if (diff < 0) return <span className="ct-anim ct-anim-reduce">{" - " + Math.abs(diff)} </span>;
                                        else if (diff > 0) return <span className="ct-anim ct-anim-increase">{" + " + Math.abs(diff)} </span>;
                                        else return <span className="ct-anim ct-anim-same">{diff}</span>;
                                      })()
                                    : ""}
                                </span>
                                <span className="ct-total">
                                  <span
                                    style={{
                                      width:
                                        (
                                          ((Number(this.state.preloadedField?.secondCustomer?.life) +
                                            Number(this.state.preloadedField?.secondCustomer?.power)) *
                                            100) /
                                          200
                                        ).toString() + "%",
                                    }}
                                    className="ct-bar"
                                  ></span>
                                  <small>
                                    {" "}
                                    Power:{" "}
                                    {Number(this.state.preloadedField?.secondCustomer?.life) +
                                      Number(this.state.preloadedField?.secondCustomer?.power)}{" "}
                                    / 200
                                  </small>
                                </span>
                              </div>
                            </div>
                            {!this.state.customerData?.inmutable?.underAttack && !this.state.customerData?.inmutable?.attacking ? (
                              <span
                                onClick={() => {
                                  this.setState({ nftSelectorShow: true, selectorPosition: 1 });
                                }}
                                className="ct-edit fas fa-sync"
                              ></span>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          "Invalid NFT"
                        )
                      ) : (
                        <div
                          className="ct-add-nft"
                          onClick={() => {
                            this.setError("Select your favorite collection and if you have NFTs it will load in the next seconds.");
                            this.setState({ nftSelectorShow: true, selectorPosition: 1 });
                          }}
                        >
                          <img className="ct-sword" src={SwordIcon} alt="" />
                          <span className="fas fa-plus"></span>
                        </div>
                      )
                    }
                  />
                </div>
                {!this.state.gameData?.member?.isOnParty && this.state.team[0] && this.state.team[1] ? (
                  <div className="ct-join-to-party">
                    <h4>New war member</h4>
                    <p>
                      If you want to participate for the prizes and collect MTRG while you are fighting you must register for one week. When the week
                      is over a random day will be chosen to distribute the prizes.
                    </p>
                    <h5>Rules</h5>
                    <ul>
                      <li>
                        You will pay 1 MTRG to join in the battle field but all MTRG will be saved in your NFTs. If another user beat your team he
                        will earn your MTRG
                      </li>
                      <li>You can fight with other users to recover your MTRG or earn more MTRG.</li>
                      <li>
                        The best fighter will be the one with better MTRG balance at the end of the week (All users can claim the balance in their
                        NFTs).
                      </li>
                      <li>
                        You can fight as many times as you want, remember to set up your teams (You can change your team members when you want).
                      </li>
                      <li>
                        You will use your BusinessBuilders profile if you have one, if you don't have it go to{" "}
                        <a href="https://meter.businessbuilders.city/" target="_blank" rel="noopener noreferrer">
                          profile creator
                        </a>{" "}
                        and create one. You will play with anonymous user if you don't want a profile.
                      </li>
                      <li>Your balance will be saved in the contract but you can recharge your NFTs balance when you want</li>
                    </ul>
                    {contractBalance >= initialPrice ? (
                      <div className="ct-join-buttons">
                        <button
                          className="ct-main-button"
                          onClick={() => {
                            if (this.props.blockChain.controller) {
                              if (this.state.team[0] && this.state.team[1]) {
                                this.props.blockChain.controller.battleMiniGames?.joinInTheParty(
                                  [this.state.team[0]?.collection, this.state.team[1]?.collection],
                                  [this.state.team[0]?.id, this.state.team[1]?.id],
                                  false,
                                  (error) => {
                                    if (error) toast.error("Tx error");
                                    setTimeout(() => this.loadBattlesData(), 7000);
                                    this.props.onLoadCustomerData(true);
                                  }
                                );
                              } else toast.error("Select your team before join.");
                            }
                          }}
                        >
                          Profile Join <br /> ({generationPrice} MTRG)
                        </button>
                        <button
                          onClick={() => {
                            if (this.props.blockChain.controller) {
                              if (this.state.team[0] && this.state.team[1]) {
                                this.props.blockChain.controller.battleMiniGames?.joinInTheParty(
                                  [this.state.team[0]?.collection, this.state.team[1]?.collection],
                                  [this.state.team[0]?.id, this.state.team[1]?.id],
                                  true,
                                  (error) => {
                                    if (error) toast.error("Tx error");
                                    setTimeout(() => {
                                      this.setState({ loader: true }, async () => {
                                        await this.preloadGameData();
                                        this.loadBattlesData(() => {
                                          this.setState({ loader: false });
                                        });
                                      });
                                    }, 7000);
                                    this.props.onLoadCustomerData(true);
                                  }
                                );
                              } else toast.error("Select your team before join.");
                            }
                          }}
                          className="ct-main-button"
                        >
                          Anonymous Join <br /> ({generationPrice} MTRG)
                        </button>
                      </div>
                    ) : (
                      <button
                        className="ct-main-button"
                        onClick={() => {
                          this.setState({ accountManagerShow: true });
                        }}
                      >
                        Add contract funds
                      </button>
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="ct-battle-actions">
                <span>VS</span>
                <div className="ct-fight">
                  {this.state.preloadedField.firstCustomer &&
                  this.state.preloadedField.secondCustomer &&
                  this.state.preloadedField.firstSelected &&
                  this.state.preloadedField.secondSelected &&
                  this.state.opponentData?.inmutable &&
                  !this.state.customerData?.inmutable?.underAttack &&
                  !this.state.customerData?.inmutable?.attacking ? (
                    <React.Fragment>
                      <Slider
                        aria-label="Battle Competition"
                        defaultValue={10}
                        color={"primary"}
                        valueLabelDisplay="auto"
                        step={5}
                        marks
                        min={0}
                        value={this.state.selectedFightPercentage}
                        max={100}
                        onChange={(e: any) => this.setState({ selectedFightPercentage: e.target?.value })}
                      />
                      <div className="ct-data">
                        <span> If you Win: + {(((maxFightBalance || 0) * this.state.selectedFightPercentage) / 100).toFixed(2)} MTRG</span>{" "}
                        <span> If you lose: - {(((maxFightBalance || 0) * this.state.selectedFightPercentage) / 100).toFixed(2)} MTRG</span>{" "}
                      </div>
                      <button
                        onClick={() => {
                          this.fight();
                        }}
                        className="ct-main-button"
                      >
                        Fight
                      </button>
                    </React.Fragment>
                  ) : !this.state.customerData?.inmutable?.underAttack && !this.state.customerData?.inmutable?.attacking ? (
                    <span>Select teams to start the battle</span>
                  ) : (
                    ""
                  )}
                  {this.state.attackInfo ? <div className="ct-attack-data"></div> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.nftSelectorShow ? (
          <div className="ct-nft-selector-container">
            <div className="ct-nft-selector ct-max-container">
              <div className="ct-available-nfts">
                <h4>
                  Collections{" "}
                  <span
                    onClick={() => {
                      this.setState({ validNFTsShow: !this.state.validNFTsShow });
                    }}
                    className="fas fa-chevron-up"
                  ></span>
                </h4>
                {this.state.validNFTsShow
                  ? this.state.validNFTs.map((nft, index) => {
                      return (
                        <div
                          className="ct-valid-nft"
                          key={index}
                          onClick={() => {
                            this.setState({ loader: true }, () => {
                              this.loadNFTs(nft._address, nft._name, () => {
                                this.setState({ loader: false });
                              });
                            });
                          }}
                        >
                          <img src={nft._image} alt="" />
                          <span>{nft._name}</span>
                        </div>
                      );
                    })
                  : ""}
              </div>
              <div className="ct-available-nfts ct-nfts-generation">
                <div className="ct-nfts-generation">
                  <strong>Generation</strong>
                  <span>Total NFTs: {this.state.generationNFTs.length}</span>
                  <span>Total price: {this.state.generationNFTs.length * generationPrice} MTRG</span>
                  {tokenAllowance >= this.state.generationNFTs.length * generationPrice ? (
                    <button
                      className="ct-main-button"
                      onClick={() => {
                        if (
                          this.props.blockChain.controller?.battleMiniGames &&
                          this.state.selectedCollection &&
                          this.state.generationNFTs.length > 0
                        ) {
                          this.props.blockChain.controller.battleMiniGames?.generateMultipleRandomStats(
                            this.state.selectedCollection,
                            this.state.generationNFTs,
                            (error) => {
                              if (error) toast.error("Tx error.");
                              this.loadNFTs(this.state.selectedCollection, this.state.loadedCollectionName);
                              this.preloadTokenAllowance();
                              this.props.onLoadCustomerData(true);
                            }
                          );
                        }
                      }}
                    >
                      Generate ({this.state.generationNFTs.length * generationPrice} MTRG)
                    </button>
                  ) : (
                    <button
                      className="ct-main-button"
                      onClick={() => {
                        if (this.props.blockChain.controller && this.props.blockChain.controller.battleMiniGames?.address) {
                          this.props.blockChain.controller.token?.approve(
                            this.props.blockChain.controller.battleMiniGames?.address,
                            this.state.generationNFTs.length * generationPrice,
                            (error) => {
                              if (error) toast.error("Tx error.");
                              this.preloadTokenAllowance();
                            }
                          );
                        }
                      }}
                    >
                      Approve ({this.state.generationNFTs.length * generationPrice} MTRG)
                    </button>
                  )}
                </div>
              </div>
              <div className="ct-available-nfts ct-tokenomics">
                <div className="ct-tokenomics">
                  <strong>TOKENOMICS</strong>
                  <span>100% FTB buy and burn (weekly).</span>
                  <span>100% DLCs buy and burn (weekly).</span>
                  <span>
                    <strong>
                      Buying and burning FTB.{" "}
                      <a
                        href="https://scan-warringstakes.meter.io/address/0x000000000000000d0e0a0d000000000000000000?tab=1&p=1"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Go to burning address{" "}
                      </a>
                    </strong>
                  </span>
                </div>
              </div>
              <div
                className="ct-exit"
                onClick={() => {
                  this.setState({ nftSelectorShow: false });
                }}
              >
                <span className="fas fa-times"></span> EXIT
              </div>
              <div className="ct-selector-container">
                <div className="ct-selector ct-max-container">
                  <h4>NFTs</h4>
                  <div className="ct-nfts-list">
                    <div className="ct-loaded-nft ct-invalid"></div>
                    <div className="ct-loaded-nft ct-invalid"></div>
                    {this.state.loadedNFTs.length > 0 ? (
                      <React.Fragment>
                        {this.state.loadedNFTs.map((nft, index) => {
                          const isTeamMember =
                            (nft._nftID.toString() === this.state.team[0]?.id.toString() &&
                              nft._collection.toLocaleLowerCase() === this.state.team[0]?.collection.toLocaleLowerCase()) ||
                            (nft._nftID.toString() === this.state.team[1]?.id.toString() &&
                              nft._collection.toLocaleLowerCase() === this.state.team[1]?.collection.toLocaleLowerCase());

                          const isSelected = this.state.generationNFTs.findIndex((data: any) => data === nft._nftID) !== -1;

                          return (
                            <div className="ct-loaded-nft" key={index + "-" + nft._id}>
                              {nft.active ? (
                                <span
                                  className={"ct-add-to-team" + (isTeamMember ? " ct-disabled-background" : "")}
                                  onClick={() => {
                                    if (isTeamMember) {
                                      toast.error("It is already in your team.");
                                    } else {
                                      const newTeam = [...this.state.team];
                                      newTeam[this.state.selectorPosition] = { active: true, collection: nft._collection, id: nft._nftID };

                                      this.setState({ team: newTeam, nftSelectorShow: false }, () => {
                                        this.preloadTeamNFTs();
                                        this.changeTeamMember(this.state.selectorPosition, {
                                          active: true,
                                          collection: nft._collection,
                                          id: nft._nftID,
                                        });
                                      });
                                    }
                                  }}
                                >
                                  <img src={SwordIcon} alt="" />
                                </span>
                              ) : (
                                ""
                              )}

                              <img src={nft._attributes?.image} alt="" />
                              <div className="ct-data">
                                <span>Collection: {this.state.loadedCollectionName}</span>
                                <span>Name: {nft._attributes?.name || "Undefined"}</span>
                                {nft.active ? (
                                  <div className="ct-stats">
                                    <span className="ct-power">
                                      <span style={{ width: ((Number(nft.power) * 100) / 100).toString() + "%" }} className="ct-bar"></span>
                                      <small>Attack: {nft.power} / 100</small>
                                    </span>
                                    <span className="ct-life">
                                      <span style={{ width: ((Number(nft.life) * 100) / 100).toString() + "%" }} className="ct-bar"></span>
                                      <small> Life: {nft.life} / 100</small>
                                    </span>
                                    <span className="ct-total">
                                      <span
                                        style={{ width: (((Number(nft.life) + Number(nft.power)) * 100) / 200).toString() + "%" }}
                                        className="ct-bar"
                                      ></span>
                                      <small> Power: {Number(nft.life) + Number(nft.power)} / 200</small>
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    className={"ct-check-selector" + (isSelected ? " ct-active" : "")}
                                    onClick={() => {
                                      if (isSelected) this.removeGenerationNFT(nft._nftID);
                                      else this.addGenerationNFT(nft._nftID);
                                    }}
                                  >
                                    {isSelected ? (
                                      <div>
                                        <span className="fas fa-check"></span>
                                        <span>generate</span>
                                      </div>
                                    ) : (
                                      <div>
                                        <span className="fas fa-times"></span>
                                        <span>remove</span>
                                      </div>
                                    )}
                                  </div>
                                  // <button
                                  //   className="ct-main-button"
                                  //   onClick={() => {
                                  //     if (this.props.blockChain.controller) {
                                  //       this.props.blockChain.controller.battleMiniGames?.generateRandomStats(
                                  //         nft._collection,
                                  //         nft._nftID,
                                  //         (error) => {
                                  //           if (error) toast.error("Tx error.");
                                  //           this.loadNFTs(nft._collection, this.state.loadedCollectionName);
                                  //           this.props.onLoadCustomerData(true);
                                  //         }
                                  //       );
                                  //     }
                                  //   }}
                                  // >
                                  //   Generate stats
                                  // </button>
                                )}
                              </div>
                            </div>
                          );
                        })}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <h5>Load NFTs using collections panel</h5>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.accountManagerShow ? (
          <div className="ct-account-management">
            <div className="ct-actions">
              <h4>Account</h4>
              <div className="ct-data">
                <span>Contract Balance: {Web3.utils.fromWei(this.state.gameData?.member?.balance || "0")} MTRG</span>
                <span>Gameplay Balance: {this.state.customerData?.inmutable?.balance} MTRG</span>
                <div className="ct-withdraw">
                  <label htmlFor="withdraw">Add contract funds</label>
                  <input
                    type="number"
                    placeholder="0 MTRG"
                    value={this.state.injectValue}
                    onChange={(e) => this.setState({ injectValue: e.target.value || "" })}
                  />
                  {tokenAllowance >= Number(this.state.injectValue) ? (
                    <button
                      onClick={() => {
                        if (this.props.blockChain.controller && Number(this.state.injectValue) > 0) {
                          this.props.blockChain.controller.battleMiniGames?.addToBalance(
                            Web3.utils.toWei(this.state.injectValue, "ether"),
                            (error) => {
                              if (error) toast.error("Tx error.");
                              this.preloadTokenAllowance();
                              this.loadBattlesData();
                              this.props.onLoadCustomerData(true);
                            }
                          );
                        }
                      }}
                      className="ct-main-button"
                    >
                      Add contract funds ({this.state.injectValue} MTRG)
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        if (
                          this.props.blockChain.controller &&
                          this.props.blockChain.controller.battleMiniGames?.address &&
                          Number(this.state.injectValue) > 0
                        ) {
                          this.props.blockChain.controller.token?.approve(
                            this.props.blockChain.controller.battleMiniGames?.address,
                            Number(this.state.injectValue),
                            (error) => {
                              if (error) toast.error("Tx error.");
                              this.preloadTokenAllowance();
                              this.loadBattlesData();
                              this.props.onLoadCustomerData(true);
                            }
                          );
                        }
                      }}
                      className="ct-main-button"
                    >
                      Approve contract funds ({this.state.injectValue} MTRG)
                    </button>
                  )}
                </div>
                <div className="ct-withdraw">
                  <label htmlFor="withdraw">Withdraw contract funds</label>
                  <input
                    type="number"
                    placeholder="0 MTRG"
                    value={this.state.withdrawValue}
                    onChange={(e) => this.setState({ withdrawValue: e.target.value || "" })}
                  />
                  <button
                    onClick={() => {
                      if (this.props.blockChain.controller && Number(this.state.withdrawValue) > 0) {
                        this.props.blockChain.controller.battleMiniGames?.withdrawBalance(
                          Web3.utils.toWei(this.state.withdrawValue, "ether"),
                          (error) => {
                            if (error) toast.error("Tx error.");
                            this.preloadGameData();
                            this.preloadTokenAllowance();
                            this.props.onLoadCustomerData(true);
                          }
                        );
                      }
                    }}
                    className="ct-main-button"
                  >
                    Withdraw contract funds ({this.state.withdrawValue} MTRG)
                  </button>
                </div>
                <div className="ct-withdraw">
                  <label htmlFor="withdraw">
                    Add gameplay funds <small>(Only in the battle field)</small>{" "}
                  </label>
                  <input
                    type="number"
                    disabled={
                      Number(Web3.utils.fromWei(this.state.gameData?.member?.balance || "0")) <= 0 || !this.state.customerData?.inmutable?.active
                    }
                    placeholder="0 MTRG"
                    value={this.state.gameplayValue}
                    onChange={(e) => this.setState({ gameplayValue: e.target.value || "" })}
                  />
                  <button
                    onClick={() => {
                      if (this.props.blockChain.controller && Number(this.state.gameplayValue) > 0) {
                        this.props.blockChain.controller.battleMiniGames?.addNFTsBalance(
                          Web3.utils.toWei(this.state.gameplayValue, "ether"),
                          (error) => {
                            if (error) toast.error("Tx error.");
                            setTimeout(() => this.preloadGameData(), 3000);
                            this.preloadTokenAllowance();
                            this.props.onLoadCustomerData(true);
                          }
                        );
                      }
                    }}
                    className="ct-main-button"
                    disabled={
                      Number(Web3.utils.fromWei(this.state.gameData?.member?.balance || "0")) <= 0 || !this.state.customerData?.inmutable?.active
                    }
                  >
                    Add gameplay funds ({this.state.gameplayValue} MTRG)
                  </button>
                </div>
                <p className="ct-mt-5">
                  <strong>
                    Before adding funds to the battle you must add funds to the contract. This will reduce the cost of gas in the network.
                  </strong>
                </p>
                <p>
                  <small>
                    All funds will be saved in the contract, only you can withdraw or add funds to your game account. The game implemented the
                    withdraw and inject funds system to save fees in the game battles.
                  </small>
                </p>
                <h4 className="ct-mt-5">Info</h4>
                <p>
                  <small>
                    You will add balance in the contract but it isn't the battle funds, when you join in the battle field you need to pay 1 MTRG from
                    you contract balance. All transactions related with your contract balance will be saved in the blockchain but when you decide to
                    enter in the battle the funds will be sent to a common contract managed by{" "}
                    <a href="https://aws.amazon.com/es/qldb/" target="_blank" rel="noopener noreferrer">
                      AWS QLDB
                    </a>{" "}
                    (Centralized) which allows us to make transactions quickly without affecting the user's gameplay. All actions in the game will be
                    saved on{" "}
                    <a href="https://aws.amazon.com/es/qldb/" target="_blank" rel="noopener noreferrer">
                      AWS QLDB
                    </a>{" "}
                    to maintain transaperency and immutable information.
                  </small>
                </p>
                <p>
                  <small>
                    <strong>Contract balance: </strong> you will manage your funds using the MeterIO blockchain. You can add or withdraw when you
                    want.
                  </small>
                </p>
                <p>
                  <small>
                    <strong>Gameplay balance: </strong> all funds will be saved on the blockchain but we will manage all funds using{" "}
                    <a href="https://aws.amazon.com/es/qldb/" target="_blank" rel="noopener noreferrer">
                      AWS QLDB
                    </a>{" "}
                    to improve all gameplay transactions. You can inject more funds when you want but you can withdraw it only in the end of the week
                    when the battle system close all fights.
                  </small>
                </p>
                <p>
                  <small>
                    This is a <strong>BETA</strong> version of the DAPP, please take care and don't worry if you have any problem, report it on the
                    telegram group and our developers will work to fix your problem, remember that all transactions will be saved in the blockchain
                    and all information will be saved on{" "}
                    <a href="https://aws.amazon.com/es/qldb/" target="_blank" rel="noopener noreferrer">
                      AWS QLDB
                    </a>{" "}
                    to protect your funds and your gameplay.
                  </small>
                </p>
                <span
                  className="ct-close-account fas fa-times"
                  onClick={() => {
                    this.setState({ accountManagerShow: false });
                  }}
                ></span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.balanceHistoryShow ? (
          <div className="ct-account-management">
            <div className="ct-actions">
              <h4>
                Balace History <small>Gameplay</small>
              </h4>
              <div className="ct-data">
                {this.state.balanceHistory.map((data, index) => {
                  const diff =
                    Number(Web3.utils.fromWei(data.balance || "0")) -
                    Number(Web3.utils.fromWei(this.state.balanceHistory[index + 1]?.balance || "0"));

                  return (
                    <div key={index + "-" + data.metadata.txId}>
                      <span>Version: {data.metadata.version}</span>
                      <span>
                        <strong>
                          Balance: {Number(Web3.utils.fromWei(data.balance || "0"))} <small>{diff ? (diff < 0 ? "-" : "+" + diff) : 0}</small>
                        </strong>{" "}
                      </span>
                      <span>TxId: {data.metadata.txId}</span>
                      <span>Date: {data.metadata.txTime}</span>
                    </div>
                  );
                })}
                <span
                  className="ct-close-account fas fa-times"
                  onClick={() => {
                    this.setState({ balanceHistoryShow: false });
                  }}
                ></span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.teamHistoryShow ? (
          <div className="ct-account-management">
            <div className="ct-actions">
              <h4>Change Team History</h4>
              <div className="ct-data">
                {this.state.teamHistory.map((data, index) => {
                  console.log(data);
                  return (
                    <div key={index + "-" + data.metadata.txId}>
                      <span>
                        <strong>Version: {data.metadata.version}</strong>{" "}
                      </span>
                      <div className="ct-team-data">
                        <span>
                          <strong>First member: </strong>
                        </span>
                        <span>Collection: {data.team.attack.collection}</span>
                        <span>ID: {data.team.attack.id}</span>
                        <span>Life: {data.team.attack.life}</span>
                        <span>Power: {data.team.attack.power}</span>
                        <span>
                          <strong>Secondary member: </strong>
                        </span>
                        <span>Collection: {data.team.defend.collection}</span>
                        <span>ID: {data.team.defend.id}</span>
                        <span>Life: {data.team.defend.life}</span>
                        <span>Power: {data.team.defend.power}</span>
                      </div>
                      <span>TxId: {data.metadata.txId}</span>
                      <span>Date: {data.metadata.txTime}</span>
                    </div>
                  );
                })}
                <span
                  className="ct-close-account fas fa-times"
                  onClick={() => {
                    this.setState({ teamHistoryShow: false });
                  }}
                ></span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.customerData?.inmutable?.underAttack ? (
          <div className="ct-under-attack">
            <div className="ct-container">
              <h4>Under Attack</h4>
              <small>
                If the opponent does not finish the battle after one hour you will be able to claim the battle rewards and finish the battle.
              </small>
              <h5>Start time: {((new Date().getTime() - this.state.customerData?.inmutable?.start) / 1000 / 60).toFixed(0)} minutes ago</h5>
              <h5>Battle amount: {this.state.customerData?.inmutable?.amount} MTRG</h5>
              <button
                onClick={() => this.claimBattleRewards()}
                disabled={(new Date().getTime() - this.state.customerData?.inmutable?.start) / 1000 / 60 < 60}
                className="ct-main-button"
              >
                Claim rewards
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.customerData?.inmutable?.attacking ? (
          <div className="ct-attack-cards">
            <div className="ct-cards">
              {CARD_OPTIONS.map((card) => {
                return (
                  <div
                    className="ct-card"
                    onClick={() => {
                      this.attack(card.option);
                    }}
                  >
                    <img src={card.image} alt="" />
                    <div className="ct-data">
                      <h5>{card.title}</h5>
                      <span>
                        Damage: + (
                        {Array.isArray(card.damage)
                          ? Math.floor((card.damage[0] * attackTeamAverage[0]) / 100) +
                            " - " +
                            Math.floor((card.damage[1] * attackTeamAverage[0]) / 100)
                          : Math.floor((card.damage * attackTeamAverage[0]) / 100)}
                        )
                      </span>
                      <span>
                        Life: + (
                        {Array.isArray(card.life)
                          ? Math.floor((card.life[0] * attackTeamAverage[1]) / 100) + " - " + Math.floor((card.life[1] * attackTeamAverage[1]) / 100)
                          : Math.floor((card.life * attackTeamAverage[1]) / 100)}
                        )
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.rules ? (
          <div className="ct-account-management ct-rules-container">
            <div className="ct-actions">
              <h4>Gameplay rules</h4>
              <div className="ct-data">
                <ul>
                  <li>You can't attack opponents with lower balance than you.</li>
                  <li>You can only get a maximum of 50% of the balance of other accounts per battle.</li>
                  <li>You have one hour to finish your battle, if you don't finish it your opponent will be able to claim all rewards.</li>
                  <li>You can change your team if you are not in battle.</li>
                  <li>you can fight against another enemy as long as neither of you is in another battle.</li>
                </ul>
                <span
                  className="ct-close-account fas fa-times"
                  onClick={() => {
                    this.setState({ rules: false });
                  }}
                ></span>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {this.state.loader ? (
          <div className="ct-loader-container">
            <div className="spinner"></div>
          </div>
        ) : (
          ""
        )}
        {this.state.winnerInfo
          ? (() => {
              const balanceDiff =
                this.state.customerData.inmutable.balance - Number(Web3.utils.fromWei(this.state.winnerInfo.initialData.attackTeam.balance || "0"));

              if (balanceDiff < 0) {
                return (
                  <div className="ct-winner-container">
                    <div className="ct-winner-info">
                      <h4>Loser</h4>
                      <div className="ct-data">
                        <span>
                          New balance: {this.state.customerData.inmutable.balance.toFixed(2)} MTRG{" "}
                          <small>({balanceDiff < 0 ? "-" + balanceDiff.toFixed(2) : "+" + balanceDiff.toFixed(2)} MTRG)</small>
                        </span>
                      </div>
                      <span
                        className="ct-close-winners fas fa-times"
                        onClick={() => {
                          this.setState({ winnerInfo: null });
                        }}
                      ></span>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className="ct-winner-container">
                    <div className="ct-winner-info">
                      <h4>Winner</h4>
                      <div className="ct-data">
                        <span>
                          New balance: {this.state.customerData.inmutable.balance.toFixed(2)} MTRG{" "}
                          <small>({balanceDiff < 0 ? balanceDiff.toFixed(2) : "+" + balanceDiff.toFixed(2)} MTRG)</small>
                        </span>
                      </div>
                      <span
                        className="ct-close-winners fas fa-times"
                        onClick={() => {
                          this.setState({ winnerInfo: null });
                        }}
                      ></span>
                    </div>
                  </div>
                );
              }
            })()
          : ""}
      </React.Fragment>
    );
  }
}
