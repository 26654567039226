import React from "react";
import Web3 from "web3";
import Select from "react-select";
import FTBLogo from "../../../assets/images/BB_LOGO.svg";
import IERC20 from "../../../assets/contracts/static/ERC20.json";
import MTRGLogo from "../../../assets/images/meter-network.png";
import UniversalNFTGeneralData from "../../../assets/contracts/UniversalNFTGeneral.json";
import Chart from "kaktana-react-lightweight-charts";

import { UtilsHelpers } from "../../../core/helpers/utils";
import { BlockChainState } from "../../../storage/state/blockChain/state";
import { ApplicationState } from "../../../storage/state/app/state";
import { AppErrorCode, Contract } from "../../../core/app";
import { UniversalNFTGeneral } from "../../../core/contracts/universalNFTGeneral";
import { ApiHelpers } from "../../../core/helpers/api";

import { toast } from "react-toastify";
import { LineStyle, UTCTimestamp } from "lightweight-charts";
import { textChangeRangeIsUnchanged } from "typescript";
import { Token } from "../../../core/contracts/token";
import { BlockChainHelpers } from "../../../core/helpers/chain";

import "./styles.css";

interface MiniBiddingProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onUpdate?: (error: AppErrorCode | null) => void;
  data: any;
}

interface MiniBiddingState {
  tokenAllowance: number;
  tokenInstance: Token | null;
  chainData: any;
}

export class MiniBiddingComponent extends React.PureComponent<MiniBiddingProps, MiniBiddingState> {
  chartContainer: any;
  chart: any;

  constructor(props: MiniBiddingProps) {
    super(props);

    this.state = { tokenAllowance: 0, tokenInstance: null, chainData: props.data };
  }

  async componentDidMount() {
    console.log("hola");
    this.preload(null);
  }

  private async preload(error: AppErrorCode | null) {
    if (this.state.chainData._token) {
      if (error) toast.error("Tx error.");

      const provider = await BlockChainHelpers.getProvider();

      let tokenAllowance = 0;
      let tokenInstance = null;

      if (
        provider &&
        this.props.blockChain &&
        this.props.blockChain.controller?._web3 &&
        this.props.blockChain.controller?.selectedAccount &&
        this.props.blockChain.controller.miniBidding?.address
      ) {
        try {
          if (this.state.chainData._token !== "0x0000000000000000000000000000000000000000") {
            tokenInstance = this.state.tokenInstance
              ? this.state.tokenInstance
              : new Token(
                  new provider.eth.Contract(IERC20 as any, this.state.chainData._token),
                  this.props.blockChain.controller?._web3,
                  this.props.blockChain.controller?.selectedAccount
                );

            console.log(tokenInstance);

            tokenAllowance = Number(
              Web3.utils.fromWei(
                await tokenInstance.allowance(this.props.blockChain.controller.selectedAccount, this.props.blockChain.controller.miniBidding?.address)
              )
            );
          }
        } catch (error) {
          console.log(error);
        }
      }

      this.setState({ tokenAllowance, tokenInstance });
    }
  }

  async preloadChainData() {
    setTimeout(async () => {
      if (this.props.blockChain.controller) {
        const data = await ApiHelpers.getUniversalMiniBid(this.props.data.bidId);
        if (data) this.setState({ chainData: { nftData: data.data.nfts, bidId: this.props.data.bidId, ...data.data.bid } }, () => this.preload(null));
      }
    }, 2000);
  }

  componentDidUpdate(prevProps: Readonly<MiniBiddingProps>, prevState: Readonly<MiniBiddingState>, snapshot?: any): void {
    if (
      (!prevProps.data._token && this.props.data._token) ||
      (!prevProps.data.nftData && this.props.data.nftData) ||
      (!prevProps.blockChain.controller?.selectedAccount && this.props.blockChain.controller?.selectedAccount)
    ) {
      this.preloadChainData();
    }
  }

  render() {
    console.log(this.state.chainData);
    const date = new Date().getTime() / 1000;
    const lastBid = Number(Web3.utils.fromWei(this.state.chainData._price || "0"));
    const initialPrice = Number(Web3.utils.fromWei(this.state.chainData._initialPrice || "0"));
    const tokenSymbol = UtilsHelpers.getTokenSymbolByAdress(this.state.chainData._token || "");
    const finishTime = (this.state.chainData._finish - date) / 60;

    const hasBid = this.state.chainData._bidder !== "0x0000000000000000000000000000000000000000";
    const isWinner = this.state.chainData._bidder?.toLocaleLowerCase() === this.props.blockChain.controller?.selectedAccount?.toLocaleLowerCase();
    const nextBid = Number(Web3.utils.fromWei(this.state.chainData._nextPrice || "0"));

    return (
      <div className="ct-bid">
        <div className="ct-image">
          {this.state.chainData?.nftData?._attributes?.image ? (
            <img src={this.state.chainData?.nftData?._attributes.image} alt="" />
          ) : (
            <span className="fas fa-question"></span>
          )}
          <div className={"ct-bidding-info" + (this.state.chainData._active ? "" : " ct-finished")}>
            <div className="ct-moment">
              <div className="ct-actions">
                <a href={this.state.chainData?.nftData?._attributes.image} target="_blank" rel="noopener noreferrer">
                  <span className="fas fa-search"></span>
                </a>
                <a
                  href={"https://tofunft.com/nft/meter/" + this.state.chainData._nft + "/" + this.state.chainData._nftID}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="fas fa-shopping-cart"></span>
                </a>
                <span onClick={() => this.preloadChainData()} className="fas fa-sync"></span>
              </div>
            </div>
            <div className="ct-bidding">
              <span>NFT: {this.state.chainData.nftData?._attributes?.name}</span>
              <span>Points: {this.state.chainData.nftData?._attributes?.attributes?.find((att: any) => att.trait_type === "Points").value}</span>
              <span>
                initial price: {initialPrice.toFixed(3)} {tokenSymbol}
              </span>
              <span>
                last bid: {lastBid.toFixed(3)} {tokenSymbol}
              </span>
              {hasBid && finishTime > 0 ? (
                <span>
                  finish on:{" "}
                  {finishTime < 0 ? "closed" : finishTime > 120 ? (finishTime / 60).toFixed(0) + " hours" : finishTime.toFixed(0) + " minutes"}
                </span>
              ) : (
                ""
              )}
              {finishTime < 0 && hasBid ? (
                <button
                  disabled={!this.state.chainData._active}
                  onClick={() => {
                    if (this.props.blockChain.controller) {
                      this.props.blockChain.controller.miniBidding?.finish(this.state.chainData.bidId, (error) => {
                        this.preload(error);
                      });
                    }
                  }}
                  className={"ct-main-button" + (isWinner ? " ct-winner-button" : "")}
                >
                  finish
                </button>
              ) : this.state.tokenAllowance >= nextBid ? (
                <button
                  disabled={!this.state.chainData._active || isWinner}
                  onClick={() => {
                    if (this.props.blockChain.controller) {
                      this.props.blockChain.controller.miniBidding?.bidding(this.state.chainData.bidId, this.state.chainData._nextPrice, (error) => {
                        this.preload(error);
                        this.preloadChainData();
                      });
                    }
                  }}
                  className={"ct-main-button" + (isWinner ? " ct-winner-button" : "")}
                >
                  {isWinner ? "winning" : "bid (" + nextBid.toFixed(3) + " " + tokenSymbol + ")"}
                </button>
              ) : (
                <button
                  disabled={!this.state.chainData._active || isWinner}
                  onClick={() => {
                    console.log(this.state.tokenInstance);
                    if (this.state.tokenInstance && this.props.blockChain.controller && this.props.blockChain.controller.miniBidding?.address) {
                      this.state.tokenInstance.approve(
                        this.props.blockChain.controller.miniBidding?.address,
                        Number(Web3.utils.fromWei(this.state.chainData._nextPrice)) * 1.1,
                        (error) => {
                          this.preload(error);
                          this.preloadChainData();
                        }
                      );
                    }
                  }}
                  className={"ct-main-button" + (isWinner ? " ct-winner-button" : "")}
                >
                  {isWinner ? "winning" : "approve (" + nextBid.toFixed(3) + " " + tokenSymbol + ")"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
