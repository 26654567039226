import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";
import { AbstractBattleMiniGames } from "../abstracts/battleMiniGame";

export class BattlesMiniGames extends ContractBaseModel<AbstractBattleMiniGames> {
  getGameData() {
    return this.contract.getGameData().call(this.config);
  }

  getAllNFTsStats(nft: string, ids: number[]) {
    return this.contract.getAllNFTsStats(nft, ids).call(this.config);
  }

  generateRandomStats(nft: string, id: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .generateRandomStats(nft, id)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }

  generateMultipleRandomStats(nft: string, ids: number[], callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .generateMultipleRandomStats(nft, ids)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }

  addToBalance(amount: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .addToBalance(amount)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }

  withdrawBalance(amount: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdrawBalance(amount)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }

  addNFTsBalance(amount: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .addNFTsBalance(amount)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }

  joinInTheParty(nfts: string[], ids: number[], anonymous: boolean, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .joinInTheParty(nfts, ids, anonymous)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }
}
