import { ContractBaseModel } from "../abstracts/contract";
import { AbstractDistibutor } from "../abstracts/distributor";
import { AppErrorCode } from "../app";

export class UniversalDistributor extends ContractBaseModel<AbstractDistibutor> {
  getData() {
    return this.contract.getData().call(this.config);
  }

  forceDistribution(token: string, amount: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .forceDistribution(token, amount)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }
}
