import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";
import {
  AbstractUniversalStakingPool,
  AbstractUniversalStakingV2Pool,
  OldAbstractUniversalStakingPool,
} from "../abstracts/universalStakingPool";

export class UniversalStakingPoolV2 extends ContractBaseModel<AbstractUniversalStakingV2Pool> {
  isValidToken(token: string) {
    return this.contract.isValidToken(token).call(this.config);
  }

  isValidCollection(collection: string) {
    return this.contract.isValidCollection(collection).call(this.config);
  }

  stakingData(address: string) {
    return this.contract.stakingData(address).call(this.config);
  }

  distribution(token: string) {
    return this.contract.distribution(token).call(this.config);
  }

  addRewards(
    token: string,
    amount: string,
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .addRewards(token, amount)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  addToken(token: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .addToken(token)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  addNFTs(token: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .addNFTs(token)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  removeNFTs(token: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .removeNFTs(token)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  removeToken(token: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .removeToken(token)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  stake(
    collection: string,
    ids: number[],
    callback: (error: AppErrorCode | null) => void
  ) {
    console.log(collection, ids);
    return this.contract
      .stake(collection, ids)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  startPool(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .startPool()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  unstake(
    collection: string,
    ids: number[],
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .unstake(collection, ids)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  updateStakingCycle(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .updateStakingCycle()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  startDistribution(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .startDistribution()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  finishDistribution(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .finishDistribution()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }
}
export class UniversalStakingPool extends ContractBaseModel<AbstractUniversalStakingPool> {
  isValidToken(token: string) {
    return this.contract.isValidToken(token).call(this.config);
  }

  isValidCollection(collection: string) {
    return this.contract.isValidCollection(collection).call(this.config);
  }

  getStakingData(address: string) {
    return this.contract.getStakingData(address).call(this.config);
  }

  distribution() {
    return this.contract.distribution().call(this.config);
  }

  stake(ids: number[], callback: (error: AppErrorCode | null) => void) {
    console.log(ids);
    return this.contract
      .stake(ids)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  distribute(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .distribute()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  unstake(ids: number[], callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .unstake(ids)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  withdraw(amount: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdraw(amount)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }
}

export class OldUniversalStakingPool extends ContractBaseModel<OldAbstractUniversalStakingPool> {
  getStakedNFTs() {
    return this.contract.getStakedNFTs(this.selectedAccount).call(this.config);
  }

  unstake(
    collection: string,
    ids: number[],
    callback: (error: AppErrorCode | null) => void
  ) {
    return this.contract
      .unstake(collection, ids)
      .send({ ...this.config, value: "100000000000000000" })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(
          AppErrorCode.GENERATE_RANDOM_STATS,
          receipt,
          callback
        );
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }
}
