import Web3 from "web3";
import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";
import { AbstractUniversalFactory } from "../abstracts/universalFactory";

export class UniversalFactory extends ContractBaseModel<AbstractUniversalFactory> {
  minterFee() {
    return this.contract.minterFee().call(this.config);
  }

  contractGenerationFee() {
    return this.contract.contractGenerationFee().call(this.config);
  }

  getContracts() {
    return this.contract.getContracts(this.selectedAccount).call(this.config);
  }

  hasKey() {
    return this.contract.hasKey(this.selectedAccount).call(this.config);
  }

  creator() {
    return this.contract.creator().call(this.config);
  }

  getInfo() {
    return this.contract.getInfo().call(this.config);
  }

  minterInfo() {
    return this.contract.minterInfo().call(this.config);
  }

  getMinterPrice(total: number) {
    return this.contract.getMinterPrice(total).call(this.config);
  }

  getData(tokens: string[]) {
    return this.contract.getData(tokens).call(this.config);
  }

  getCustomerContracts(customer: string) {
    return this.contract.getCustomerContracts(customer).call(this.config);
  }

  generateNFTContract(name: string, symbol: string, baseUri: string, token: string, callback: (error: AppErrorCode | null, receipt: any) => void) {
    return this.contract
      .generateNFTContract(name, symbol, baseUri, token)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => {
        this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback);
      });
  }

  mintGeneralNFT(softId: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .mintGeneralNFT(softId)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  distribute(token: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .distribute(token)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  mintKey(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .mintKey()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  withdraw(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdraw()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }
}
