import React from "react";
import Web3 from "web3";
import Select from "react-select";
import FTBLogo from "../../../assets/images/BB_LOGO.svg";
import ERC721 from "../../../assets/contracts/UniversalNFTGeneral.json";
import MTRGLogo from "../../../assets/images/meter-network.png";
import UniversalNFTGeneralData from "../../../assets/contracts/UniversalNFTGeneral.json";
import UniversalStakingPoolV1DeprecatedData from "../../../assets/contracts/UniversalStakingPoolV1Deprecated.json";
import UniversalStakingPoolV2Data from "../../../assets/contracts/UniversalStakingPoolV1Deprecated.json";
import UniversalStakingPoolData from "../../../assets/contracts/UniversalStakingPoolV1Deprecated.json";
import UniversalStakingPoolOld from "../../../assets/contracts/UniversalStakingPoolV1.json";
import Chart from "kaktana-react-lightweight-charts";

import { UtilsHelpers } from "../../../core/helpers/utils";
import { BlockChainState } from "../../../storage/state/blockChain/state";
import { ApplicationState } from "../../../storage/state/app/state";
import { AppErrorCode, Contract } from "../../../core/app";
import { UniversalNFTGeneral } from "../../../core/contracts/universalNFTGeneral";
import { ApiHelpers } from "../../../core/helpers/api";
import { OldUniversalStakingPool, UniversalStakingPool } from "../../../core/contracts/universalStakingPool";

import { toast } from "react-toastify";

import "./styles.css";
import { textChangeRangeIsUnchanged } from "typescript";

interface StakingPoolProps {
  pool: any;
  appState: ApplicationState;
  blockChain: BlockChainState;
}

interface StakerData {
  nftsPower: string;
  timePower: string;
  definitivePower: string;
  power: string;
  totalPoints: string;
  savedRewards: string;
  stakedNFTs: any[];
  rewards: { owner: string; points: string; amount: string };
  poolData: {
    owner: string;
    collection: string;
    token: string;
    cycleTime: string;
    poolStartTime: number;
    lastCycleStart: number;
    totalCycles: string;
    totalNFTs: string;
    undistributedRewards: string;
    distributedRewards: string;
  };
  staking: {
    owner: string;
    nfts: string;
    updateTime: string;
  };
}

interface StakingPoolState {
  selectedToken: string;
  pool: any | null;
  adminConsole: boolean;
  stakerData: StakerData | null;
  nftsLoader: boolean;
  nftsPanel: boolean;
  statisticsPanel: boolean;
  preloadedNFTs: any[];
  instance: any | null;
  approvedNFTs: boolean;
  nftInstance: any;
  old: boolean;
}

const chartsConfig = [
  {
    topColor: "rgba(67, 83, 254, 0.7)",
    bottomColor: "rgba(67, 83, 254, 0.3)",
    lineColor: "rgba(67, 83, 254, 1)",
    lineWidth: 2,
    priceScaleId: "left",
  },
  {
    topColor: "rgba(255, 192, 0, 0.7)",
    bottomColor: "rgba(255, 192, 0, 0.3)",
    lineColor: "rgba(255, 192, 0, 1)",
    lineWidth: 2,
  },
];

export class StakingPoolComponent extends React.PureComponent<StakingPoolProps, StakingPoolState> {
  chartContainer: any;
  chart: any;

  constructor(props: StakingPoolProps) {
    super(props);

    this.state = {
      selectedToken: "",
      pool: props.pool,
      adminConsole: false,
      statisticsPanel: false,
      stakerData: null,
      nftsLoader: false,
      nftsPanel: false,
      preloadedNFTs: [],
      instance: null,
      approvedNFTs: false,
      nftInstance: null,
      old: false,
    };

    this.chartContainer = React.createRef();
    this.chart = React.createRef();

    this.openStatistics = this.openStatistics.bind(this);
  }

  async componentDidMount() {
    if (this.props.blockChain.controller) this.preloadInstance();
  }

  async preloadInstance() {
    if (this.props.blockChain.controller && this.props.blockChain.controller._web3 && this.props.blockChain.controller.selectedAccount) {
      if (this.state.pool._version === "V1") {
        const instance = new UniversalStakingPool(
          new this.props.blockChain.controller._web3.eth.Contract(UniversalStakingPoolV1DeprecatedData as any, this.state.pool._address),
          this.props.blockChain.controller._web3,
          this.props.blockChain.controller.selectedAccount
        );

        if (instance) this.setState({ instance, old: true }, () => this.preUpdatePool());
      }
    }
  }

  async autoLoadStaker() {
    if (this.props.blockChain.controller?.selectedAccount && this.state.instance) {
      const stakerInfo = await this.state.instance.getStakingData(this.props.blockChain.controller?.selectedAccount);
      return stakerInfo;
    } else return null;
  }

  async preUpdatePool() {
    const preloadedPool = await ApiHelpers.getStakingPool(this.props.pool._address);
    if (preloadedPool.data) this.setState({ stakerData: await this.autoLoadStaker(), pool: preloadedPool.data });
  }

  async preloadStakingPoolNFTs() {
    const stakerData = await this.autoLoadStaker();

    this.setState({ stakerData, nftsPanel: true, nftsLoader: true }, async () => {
      if (this.props.blockChain.controller?._web3 && this.props.blockChain.controller.selectedAccount) {
        const instance = new UniversalNFTGeneral(
          new this.props.blockChain.controller._web3.eth.Contract(UniversalNFTGeneralData.abi as any, this.props.pool._collection),
          this.props.blockChain.controller._web3,
          this.props.blockChain.controller.selectedAccount
        );

        const approval = await instance.isApprovedForAll(this.props.blockChain.controller.selectedAccount, this.state.pool._address);

        const ownerNFTs = await this.props.blockChain.controller.universalQueries?.getAllCustomerNFTs(
          this.props.pool._collection,
          this.props.blockChain.controller.selectedAccount
        );

        const preloadedNFTs = await ApiHelpers.preloadExternalNFTs(this.state.pool._collection, ownerNFTs.concat(this.state.stakerData?.stakedNFTs));

        this.setState({
          preloadedNFTs: Array.isArray(preloadedNFTs.data)
            ? preloadedNFTs.data.map((nft: any) => {
                return {
                  ...nft,
                  isStaked:
                    this.state.stakerData?.stakedNFTs.findIndex((id) => {
                      return Number(id) === Number(nft._nftID);
                    }) !== -1,
                };
              })
            : [],
          approvedNFTs: approval,
          nftsLoader: false,
          nftInstance: instance,
        });
      }
    });
  }

  async downloadDistribution() {
    let distribution = await this.state.instance?.distribution();

    if (distribution && Array.isArray(distribution)) {
      const blob = new Blob([JSON.stringify(distribution)], {
        type: "application/json;charset=utf-8",
      });

      const url = window.URL || window.webkitURL;
      const link = url.createObjectURL(blob);
      const a = document.createElement("a");

      a.download = "rewards.json";
      a.href = link;

      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);
    }
  }

  async openStatistics() {
    // let tokens: any = {};
    // if (Object.keys(this.state.tokensRegisters).length === 0) {
    //   const registers = await ApiHelpers.getStakingPoolRegisters(this.props.pool._address);
    //   if (registers && Array.isArray(registers)) {
    //     for (let i = 0; i < registers[0]?._tokens.length; i++) {
    //       tokens[registers[0]._tokens[i].symbol] = registers.reverse().map((register: any) => {
    //         return {
    //           value: register._tokens[i].balance,
    //           time: (register._date / 1000) as UTCTimestamp,
    //         };
    //       });
    //     }
    //   }
    // }
    // this.setState({
    //   statisticsPanel: !this.state.statisticsPanel,
    //   tokensRegisters: tokens,
    // });
  }

  render() {
    const nextDistributionTime =
      (new Date((Number(this.state.stakerData?.poolData.cycleTime) + Number(this.state.stakerData?.poolData.lastCycleStart)) * 1000).getTime() -
        new Date().getTime()) /
      1000 /
      60;

    return (
      <div className="ct-nft-staking-pool">
        <div className="ct-header">
          <div className="ct-tokens-list">
            <span>
              <img src={UtilsHelpers.getCollectionImageByAddress(this.state.pool?._collection || "")} alt="" />{" "}
            </span>
            <span>
              <img src={UtilsHelpers.getTokenImageBySymbol("FTB")} alt="" />
            </span>
          </div>
          <div className="ct-rewards">
            <div className="ct-update">
              <small>REWARDS {Number(Web3.utils.fromWei(this.state.stakerData?.poolData.undistributedRewards || "0")).toFixed(3)} FTB</small>
              <span onClick={() => this.preUpdatePool()} className="fas fa-sync"></span>
              <span onClick={() => this.downloadDistribution()} className="fas fa-download"></span>
              <span
                onClick={() => {
                  if (this.state.instance) {
                    this.state.instance.distribute((error: any) => {
                      if (error) toast.error("[STAKING] distribution error.");
                      else this.preUpdatePool();
                    });
                  }
                }}
                className="fas fa-project-diagram"
              ></span>
            </div>

            <a className="ct-address" href={"http://scan.meter.io/address/" + this.props.pool?._address} target="_blank" rel="noopener noreferrer">
              {this.props.pool?._address}
            </a>
          </div>
          {this.state.old ? (
            <div className="ct-old-pool">
              <h4>We are updating the staking pool</h4>
              <p>In the next version you will be able to earn multiple tokens (MTRG, VOLT and FTB) in only one pool</p>
              <p>All rewards while we finish the development will be added in the new pool</p>
              <p>
                <strong onClick={() => (this.state.nftsPanel ? this.setState({ nftsPanel: false }) : this.preloadStakingPoolNFTs())}>
                  unstake your NFTs
                </strong>
              </p>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="ct-staking-info">
          <div className="ct-staker">
            <p>
              <strong>total nfts: </strong> {this.state.stakerData?.staking.nfts}
            </p>

            <p>
              <strong>nfts power: </strong> {Number(this.state.stakerData?.nftsPower) / 10000} %
            </p>

            <p>
              <strong>time power: </strong> {Number(this.state.stakerData?.timePower) / 10000} %
            </p>

            <p>
              <strong>pool percentage power: </strong> {this.state.stakerData?.power} %
            </p>
            <p>
              <strong>update time: </strong>{" "}
              {Number(this.state.stakerData?.staking.updateTime) !== 0
                ? new Date(Number(this.state.stakerData?.staking.updateTime || 0) * 1000).toUTCString()
                : 0}
            </p>
            <p>
              <strong>rewards ftb: </strong>
              {Web3.utils.fromWei(this.state.stakerData?.rewards?.amount || "0", "ether")}
            </p>
            {/* <p>
              <strong>saved rewards: </strong> {Number(Web3.utils.fromWei(this.state.stakerData?.savedRewards || "0", "ether")).toFixed(3)} FTB
            </p> */}

            <small>Your staking time will restart when you unstake.</small>
          </div>

          <div className="ct-actions">
            <p>
              <strong>Total NFTs on the pool:</strong> {this.state.stakerData?.poolData.totalNFTs}
            </p>
            <p>
              <strong>Total distributions:</strong> {this.state.stakerData?.poolData.totalCycles}
            </p>
            <p>
              <strong>Cycle time: </strong> {(Number(this.state.stakerData?.poolData.cycleTime) / 60 / 60 / 24).toFixed(0)} Days
            </p>
            <p>
              <strong>Last cycle start: </strong>
              {new Date(Number(this.state.stakerData?.poolData.lastCycleStart) * 1000).toUTCString()}
            </p>
            <p>
              <strong>Pool start time: </strong>
              {this.state.stakerData?.poolData.poolStartTime ? new Date(this.state.stakerData?.poolData?.poolStartTime * 1000).toUTCString() : 0}
            </p>

            <p className="ct-mb-5">
              <strong>Next distribution: </strong>{" "}
              {nextDistributionTime < 0
                ? "The admin can distribute all rewards"
                : nextDistributionTime > 120
                ? (nextDistributionTime / 60).toFixed(0) + " hours"
                : nextDistributionTime.toFixed(0) + " minutes"}{" "}
            </p>

            <small onClick={() => (this.state.nftsPanel ? this.setState({ nftsPanel: false }) : this.preloadStakingPoolNFTs())}>
              Load my NFTs (stake or unstake) <span className="fas fa-chevron-down"></span>
            </small>
            <small onClick={() => this.openStatistics()}>
              Load statistics <span className="fas fa-chevron-down"></span>
            </small>
          </div>
        </div>

        {this.state.nftsPanel ? (
          <div className="ct-nfts-panel">
            {this.state.nftsLoader ? (
              <div className="ct-nfts-loader">
                <div className="spinner"></div>
              </div>
            ) : (this.state.preloadedNFTs?.length || 0) > 0 ? (
              <React.Fragment>
                <div className="ct-nfts-list">
                  {this.state.preloadedNFTs?.map((nft, index) => {
                    if (nft?._id) {
                      return (
                        <div
                          key={nft._id + index}
                          onClick={() => {
                            if (this.state.instance) {
                              if (this.state.approvedNFTs) {
                                if (nft?.isStaked) this.state.instance.unstake([nft._nftID], () => this.preloadStakingPoolNFTs());
                                else this.state.instance.stake([nft._nftID], () => this.preloadStakingPoolNFTs());
                              } else this.state.nftInstance.setApprovalForAll(this.props.pool._address, true, () => this.preloadStakingPoolNFTs());
                            }
                          }}
                          className={"ct-loaded-nft" + (this.state.approvedNFTs ? (nft?.isStaked ? " ct-staked" : "") : " ct-non-approved")}
                        >
                          {nft?.isStaked ? <span className="fas fa-lock"></span> : <span className="fas fa-plane"></span>}

                          <img src={nft?._attributes?.image} alt="" />
                        </div>
                      );
                    } else {
                      return "";
                    }
                  })}
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {/*
        {this.state.statisticsPanel ? (
          <div className="ct-statistics-panel">
            {Object.keys(this.state.tokensRegisters).length > 0 ? (
              <Chart
                options={{
                  layout: {
                    backgroundColor: "#fff",
                    textColor: "#000",
                  },
                  leftPriceScale: {
                    visible: true,
                  },
                  rightPriceScale: {
                    scaleMargins: {
                      top: 0.1,
                      bottom: 0.1,
                    },
                    visible: true,
                    autoScale: false,
                  },
                  grid: {
                    vertLines: {
                      color: "rgba(197, 203, 206, 0.4)",
                      style: LineStyle.Dotted,
                    },
                    horzLines: {
                      color: "rgba(197, 203, 206, 0.4)",
                      style: LineStyle.Dotted,
                    },
                  },
                }}
                areaSeries={Object.keys(this.state.tokensRegisters).map((token, index) => {
                  return {
                    ...chartsConfig[index],
                    data: this.state.tokensRegisters[token],
                    topColor: "rgba(67, 83, 254, 0.7)",
                    bottomColor: "rgba(67, 83, 254, 0.3)",
                    lineColor: "rgba(67, 83, 254, 1)",
                    lineWidth: 2,
                    title: token,
                  };
                })}
                autoWidth
                height={320}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )} */}
      </div>
    );
  }
}

export class OldStakingPoolComponent extends React.PureComponent<StakingPoolProps, StakingPoolState> {
  chartContainer: any;
  chart: any;

  constructor(props: StakingPoolProps) {
    super(props);

    this.state = {
      selectedToken: "",
      pool: props.pool,
      adminConsole: false,
      statisticsPanel: false,
      stakerData: null,
      nftsLoader: false,
      nftsPanel: true,
      preloadedNFTs: props.pool.nfts,
      instance: null,
      approvedNFTs: false,
      nftInstance: null,
      old: false,
    };
  }

  async componentDidMount() {
    if (this.props.blockChain.controller) this.preloadInstance();
  }

  async preloadInstance() {
    if (this.props.blockChain.controller && this.props.blockChain.controller._web3 && this.props.blockChain.controller.selectedAccount) {
      const instance = new OldUniversalStakingPool(
        this.state.pool.instance,
        this.props.blockChain.controller._web3,
        this.props.blockChain.controller.selectedAccount
      );

      if (instance) this.setState({ instance });
    }
  }

  updateNFTs() {
    this.state.instance.methods.getStakedNFTs().then(async (data: any) => {
      if (Array.isArray(data)) {
        const preloadedNFTs = await ApiHelpers.preloadExternalNFTs(data[0]?.collection, data[0]?.nfts);
        this.setState({ preloadedNFTs });
      } else this.setState({ preloadedNFTs: [] });
    });
  }

  render() {
    if (this.state.preloadedNFTs.length > 0) {
      return (
        <div className="ct-nft-staking-pool">
          <div className="ct-header">
            <div className="ct-tokens-list">
              <span className="fas fa-question"></span>
              <span>
                <img src={UtilsHelpers.getTokenImageBySymbol("FTB")} alt="" />
              </span>
            </div>
            <div className="ct-rewards">
              <a className="ct-address" href={"http://scan.meter.io/address/" + this.props.pool?._address} target="_blank" rel="noopener noreferrer">
                {this.props.pool?.address}
              </a>
            </div>
          </div>
          {this.state.nftsPanel ? (
            <div className="ct-nfts-panel">
              {this.state.nftsLoader ? (
                <div className="ct-nfts-loader">
                  <div className="spinner"></div>
                </div>
              ) : (this.state.preloadedNFTs?.length || 0) > 0 ? (
                <React.Fragment>
                  <div className="ct-nfts-list">
                    {this.state.preloadedNFTs?.map((nft, index) => {
                      if (nft?._id) {
                        return (
                          <div
                            key={nft._id + index}
                            onClick={() => {
                              this.state.instance.unstake(nft._collection, [nft._nftID], (error: any) => {
                                if (error) toast.error("[STAKING POOL] Tx error.");
                                else this.updateNFTs();
                              });
                            }}
                            className={"ct-loaded-nft" + (this.state.approvedNFTs ? (nft?.isStaked ? " ct-staked" : "") : " ct-non-approved")}
                          >
                            {nft?.isStaked ? <span className="fas fa-lock"></span> : <span className="fas fa-plane"></span>}

                            <img src={nft?._attributes?.image} alt="" />
                          </div>
                        );
                      } else {
                        return "";
                      }
                    })}
                  </div>
                </React.Fragment>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      );
    } else return "";
  }
}
