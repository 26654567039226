import Web3 from "web3";
import { UniversalFactory } from "../contracts/universalFactory";

interface UniversalNFTData {
  buyBurnPercentage: number;
  price: number;
  recolectorPercentage: number;
  stakingPercentage: number;
  contracts: number[];
  hasKey: boolean;
}

export class UniverseNFTController {
  data: UniversalNFTData | null = null;

  constructor(public contract: UniversalFactory) {}

  async initialize() {
    const data = await this.contract.getInfo();
    const hasKey = await this.contract.hasKey();

    if (data) {
      this.data = {
        contracts: [],
        buyBurnPercentage: Number(data.buyBurnPercentage),
        price: hasKey ? 0 : Number(Web3.utils.fromWei(data.price)),
        recolectorPercentage: Number(data.recolectorPercentage),
        stakingPercentage: Number(data.stakingPercentage),
        hasKey,
      };
    }
  }
}
