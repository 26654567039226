import Web3 from "web3";
import { UniversalMinter } from "../contracts/universalMinter";

interface UniversalMintingData {
  buyBackFee: number;
  buyBurnToken: string;
  hasKey: boolean;
  keyPrice: number;
  keysSupply: number;
  maxKeys: number;
  stakingPool: string;
  stakingPoolFee: number;
  tokens: number[];
}

export class UniverseMintingController {
  data: UniversalMintingData | null = null;

  constructor(public contract: UniversalMinter) {}

  async initialize() {
    const data = await this.contract.getInfo();

    if (data) {
      this.data = {
        buyBackFee: Number(data.buyBackFee),
        buyBurnToken: data.buyBurnToken,
        hasKey: data.hasKey,
        keyPrice: Number(Web3.utils.fromWei(data.keyPrice)),
        keysSupply: Number(data.keysSupply),
        maxKeys: Number(data.maxKeys),
        stakingPool: data.stakingPool,
        stakingPoolFee: Number(data.stakingPoolFee),
        tokens: data.tokens,
      };
    }
  }
}
