import React from "react";
import Web3 from "web3";
import Select from "react-select";
import FTBLogo from "../../../assets/images/BB_LOGO.svg";
import ERC721 from "../../../assets/contracts/UniversalNFTGeneral.json";
import MTRGLogo from "../../../assets/images/meter-network.png";
import VoltLogo from "../../../assets/images/VOLT.png";
import UniversalNFTGeneralData from "../../../assets/contracts/UniversalNFTGeneral.json";
import PoolData from "../../../assets/contracts/UniversalStakingPoolV2.json";
import UniversalStakingPoolV2Data from "../../../assets/contracts/UniversalStakingPoolV1Deprecated.json";
import UniversalStakingPoolData from "../../../assets/contracts/UniversalStakingPoolV1Deprecated.json";
import UniversalStakingPoolOld from "../../../assets/contracts/UniversalStakingPoolV1.json";
import Chart from "kaktana-react-lightweight-charts";

import { UtilsHelpers } from "../../../core/helpers/utils";
import { BlockChainState } from "../../../storage/state/blockChain/state";
import { ApplicationState } from "../../../storage/state/app/state";
import { appConfig, AppErrorCode, AppMode, Contract } from "../../../core/app";
import { UniversalNFTGeneral } from "../../../core/contracts/universalNFTGeneral";
import { ApiHelpers } from "../../../core/helpers/api";
import { OldUniversalStakingPool, UniversalStakingPool, UniversalStakingPoolV2 } from "../../../core/contracts/universalStakingPool";
import { SendTokenStakingRewards } from "../../molecules/token/stakingRewards";

import { toast } from "react-toastify";

import "./styles.css";

interface StakingPoolProps {
  pool: any;
  appState: ApplicationState;
  blockChain: BlockChainState;
}

interface StakerData {
  cycleData: {
    id: string;
    startTime: string;
    finishTime: string;
    totalNFTs: string;
    holdings: { amount: string; token: string }[];
  };
  cycleRewards: { amount: string; token: string }[];
  estimatedRewards: { amount: string; token: string }[];
  poolData: {
    collections: string[];
    tokens: string[];
    cycle: string;
    cycleTime: string;
    distributionTime: string;
    distributor: string;
    distributorFee: string;
    factory: string;
    owner: string;
    poolStartTime: string;
    state: string;
    totalNFTs: string;
  };
  stakePower: string;
  stakedNFTs: Array<{ collection: string; ids: number[] }>;
  staking: {
    cycle: string;
    cycleStartTime: string;
    nfts: string;
    owner: string;
  };
  stakingTime: string;
  timePower: string;
}

interface StakingPoolState {
  selectedToken: string;
  pool: any | null;
  adminConsole: boolean;
  stakerData: StakerData | null;
  nftsLoader: boolean;
  nftsPanel: boolean;
  statisticsPanel: boolean;
  preloadedNFTs: any[];
  instance: any | null;
  approvedNFTs: boolean;
  nftInstance: any;
  collectionData: any;
}

const chartsConfig = [
  {
    topColor: "rgba(67, 83, 254, 0.7)",
    bottomColor: "rgba(67, 83, 254, 0.3)",
    lineColor: "rgba(67, 83, 254, 1)",
    lineWidth: 2,
    priceScaleId: "left",
  },
  {
    topColor: "rgba(255, 192, 0, 0.7)",
    bottomColor: "rgba(255, 192, 0, 0.3)",
    lineColor: "rgba(255, 192, 0, 1)",
    lineWidth: 2,
  },
];

export class StakingPoolV2Component extends React.PureComponent<StakingPoolProps, StakingPoolState> {
  chartContainer: any;
  chart: any;

  constructor(props: StakingPoolProps) {
    super(props);

    this.state = {
      selectedToken: "",
      pool: props.pool,
      adminConsole: false,
      statisticsPanel: false,
      stakerData: null,
      nftsLoader: false,
      nftsPanel: false,
      preloadedNFTs: [],
      instance: null,
      approvedNFTs: false,
      nftInstance: null,
      collectionData: null,
    };

    this.chartContainer = React.createRef();
    this.chart = React.createRef();

    this.openStatistics = this.openStatistics.bind(this);
  }

  async componentDidMount() {
    if (this.props.blockChain.controller) this.preloadInstance();
  }

  async preloadInstance() {
    if (this.props.blockChain.controller && this.props.blockChain.controller._web3 && this.props.blockChain.controller.selectedAccount) {
      try {
        const instance = new UniversalStakingPoolV2(
          new this.props.blockChain.controller._web3.eth.Contract(PoolData.abi as any, this.state.pool._address),
          this.props.blockChain.controller._web3,
          this.props.blockChain.controller.selectedAccount
        );
        if (instance) this.setState({ instance }, () => this.preUpdatePool());
      } catch (error) {
        console.log(error);
      }
    }
  }

  async autoLoadStaker() {
    if (this.props.blockChain.controller?.selectedAccount && this.state.instance) {
      const stakerInfo = await this.state.instance.stakingData(this.props.blockChain.controller?.selectedAccount);
      console.log(stakerInfo);
      return stakerInfo;
      return null;
    } else return null;
  }

  async preUpdatePool() {
    const preloadedPool = await ApiHelpers.getStakingPool(this.props.pool._address);
    if (preloadedPool.data) {
      this.setState({
        stakerData: await this.autoLoadStaker(),
        pool: preloadedPool.data,
      });
    }
  }

  async preloadStakingPoolNFTs() {
    const stakerData = await this.autoLoadStaker();

    if (this.props.pool._collections?.length > 0) {
      this.setState({ stakerData, nftsPanel: true, nftsLoader: true }, async () => {
        const allData = [];
        console.log("hola");
        console.log(this.props.pool._collections);
        for (let i = 0; i < this.props.pool._collections.length; i++) {
          allData.push(
            new Promise(async (res) => {
              if (this.props.blockChain.controller && this.props.blockChain.controller._web3 && this.props.blockChain.controller.selectedAccount) {
                const instance = new UniversalNFTGeneral(
                  new this.props.blockChain.controller._web3.eth.Contract(UniversalNFTGeneralData.abi as any, this.props.pool._collections[i]),
                  this.props.blockChain.controller._web3,
                  this.props.blockChain.controller.selectedAccount
                );

                const approval = await instance.isApprovedForAll(this.props.blockChain.controller.selectedAccount, this.state.pool._address);

                const ownerNFTs = await this.props.blockChain.controller.universalQueries?.getAllCustomerNFTs(
                  this.props.pool._collections[i],
                  this.props.blockChain.controller.selectedAccount
                );

                const nfts = this.state.stakerData?.stakedNFTs;

                if (nfts) {
                  const stakedNFTs = Array.isArray(this.state.stakerData?.stakedNFTs)
                    ? this.state.stakerData?.stakedNFTs.find(
                        (data) => data.collection.toLocaleLowerCase() === this.props.pool._collections[i].toLocaleLowerCase()
                      )?.ids
                    : [];

                  const preloadedNFTs = await ApiHelpers.preloadExternalNFTs(this.props.pool._collections[i], ownerNFTs.concat(stakedNFTs));

                  res({ preloadedNFTs, approval, instance, stakedNFTs });
                }
              }
            })
          );

          this.setState({
            preloadedNFTs: await Promise.all(allData),
            nftsLoader: false,
          });
        }
      });
    }
  }

  async downloadDistribution(token: string) {
    let distribution = await this.state.instance?.distribution(token);

    if (distribution && Array.isArray(distribution)) {
      let parsedDistribution = distribution.map((owner) => {
        return {
          address: owner[0],
          token: owner[1],
          poolPower: owner[2] + "%",
          rewards: Web3.utils.fromWei(owner[3]) + UtilsHelpers.getTokenSymbolByAdress(owner[1]),
        };
      });

      const blob = new Blob([JSON.stringify(parsedDistribution)], {
        type: "application/json;charset=utf-8",
      });

      const url = window.URL || window.webkitURL;
      const link = url.createObjectURL(blob);
      const a = document.createElement("a");

      a.download = "rewards.json";
      a.href = link;

      document.body.appendChild(a);

      a.click();

      document.body.removeChild(a);
    }
  }

  async autoUpdateCollection(collection: string) {
    let selectedCollectionData = {
      name: "",
      symbol: "",
      totalSupply: 0,
      address: collection,
    };

    if (Web3.utils.isAddress(collection) && this.props.blockChain.controller?._web3 && this.props.blockChain.controller?.selectedAccount) {
      const validationContract = new UniversalNFTGeneral(
        await new this.props.blockChain.controller._web3.eth.Contract(ERC721.abi as any, collection),

        this.props.blockChain.controller?._web3,
        this.props.blockChain.controller?.selectedAccount
      );

      try {
        const symbol = await validationContract.symbol();
        const name = await validationContract.name();
        const totalSupply = await validationContract.totalSupply();

        selectedCollectionData = {
          ...selectedCollectionData,
          symbol,
          name,
          totalSupply,
        };
      } catch (error) {
        console.log("invalid Collection");
      }
    }

    this.setState({
      collectionData: { ...selectedCollectionData, address: collection },
    });
  }

  async openStatistics() {
    // let tokens: any = {};
    // if (Object.keys(this.state.tokensRegisters).length === 0) {
    //   const registers = await ApiHelpers.getStakingPoolRegisters(this.props.pool._address);
    //   if (registers && Array.isArray(registers)) {
    //     for (let i = 0; i < registers[0]?._tokens.length; i++) {
    //       tokens[registers[0]._tokens[i].symbol] = registers.reverse().map((register: any) => {
    //         return {
    //           value: register._tokens[i].balance,
    //           time: (register._date / 1000) as UTCTimestamp,
    //         };
    //       });
    //     }
    //   }
    // }
    // this.setState({
    //   statisticsPanel: !this.state.statisticsPanel,
    //   tokensRegisters: tokens,
    // });
  }

  render() {
    const nextDistributionTime =
      (new Date((Number(this.state.stakerData?.poolData.cycleTime) + Number(this.state.stakerData?.cycleData.startTime)) * 1000).getTime() -
        new Date().getTime()) /
      1000 /
      60;

    const distributionStepTime =
      Number(this.state.stakerData?.cycleData.finishTime) !== 0
        ? (new Date(
            (Number(this.state.stakerData?.poolData.distributionTime) + Number(this.state.stakerData?.cycleData.finishTime)) * 1000
          ).getTime() -
            new Date().getTime()) /
          1000 /
          60
        : 0;

    return (
      <div className="ct-nft-staking-pool">
        <div className="ct-header">
          <div className="ct-tokens-list">
            {this.state.pool._collections.map((collection: string, index: number) => {
              return (
                <span key={index}>
                  <img src={UtilsHelpers.getCollectionImageByAddress(collection)} alt="" />{" "}
                </span>
              );
            })}
          </div>
          <div className="ct-rewards">
            <div className="ct-update">
              {this.state.stakerData?.cycleData.holdings?.map((data: any, index) => {
                return (
                  <small key={index}>
                    REWARDS {Number(Web3.utils.fromWei(data.amount)).toFixed(3)} {UtilsHelpers.getTokenSymbolByAdress(data.token)}
                  </small>
                );
              })}
            </div>

            <a className="ct-address" href={"http://scan.meter.io/address/" + this.props.pool?._address} target="_blank" rel="noopener noreferrer">
              {this.props.pool?._address}
            </a>
          </div>
          {this.state.stakerData?.poolData.state === "2" ? (
            <div className="ct-distribution-pool">
              <h4>Distribution moment</h4>
              <p>
                Withdraw your rewards using the{" "}
                <span
                  onClick={() => {
                    if (this.state.instance) {
                      this.state.instance.updateStakingCycle(() => this.preUpdatePool());
                    }
                  }}
                  className="fas fa-money-bill"
                ></span>{" "}
                button
              </p>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="ct-staking-info">
          <div className="ct-staker">
            <p>
              <strong>total nfts: </strong> {this.state.stakerData?.staking.nfts}
            </p>

            <p>
              <strong>nfts power: </strong> {Number(this.state.stakerData?.stakePower) / 10000} %
            </p>

            <p>
              <strong>time power: </strong> {Number(this.state.stakerData?.timePower) / 10000} %
            </p>
            {this.state.stakerData?.estimatedRewards?.map((data, index) => {
              return (
                <p key={index}>
                  <strong>rewards estimation (100% of the time) {UtilsHelpers.getTokenSymbolByAdress(data.token)}: </strong>
                  {Web3.utils.fromWei(data?.amount || "0", "ether")}
                </p>
              );
            })}
            {this.state.stakerData?.cycleRewards?.map((data, index) => {
              return (
                <p key={index}>
                  <strong>real rewards {UtilsHelpers.getTokenSymbolByAdress(data.token)}: </strong>
                  {Web3.utils.fromWei(data?.amount || "0", "ether")} {UtilsHelpers.getTokenSymbolByAdress(data.token)}
                  <span
                    className="fas fa-money-bill ct-ml-5"
                    onClick={() => {
                      if (this.state.instance) {
                        this.state.instance.updateStakingCycle(() => this.preUpdatePool());
                      }
                    }}
                  ></span>
                </p>
              );
            })}

            {/* <p>
              <strong>saved rewards: </strong> {Number(Web3.utils.fromWei(this.state.stakerData?.savedRewards || "0", "ether")).toFixed(3)} FTB
            </p> */}

            <small>Your staking time will restart when you unstake.</small>
          </div>

          <div className="ct-actions">
            <p>
              <strong>Total NFTs on the pool:</strong> {this.state.stakerData?.poolData.totalNFTs}
            </p>
            <p>
              <strong>Total distributions:</strong> {this.state.stakerData?.poolData.cycle}
            </p>
            <p>
              <strong>Cycle time: </strong> {(Number(this.state.stakerData?.poolData.cycleTime) / 60 / 60 / 24).toFixed(0)} Days
            </p>
            <p>
              <strong>Pool start time: </strong>
              {this.state.stakerData?.poolData.poolStartTime
                ? new Date(Number(this.state.stakerData?.poolData?.poolStartTime) * 1000).toUTCString()
                : 0}
            </p>

            <p className="ct-mb-5">
              <strong>Next distribution: </strong>{" "}
              {nextDistributionTime < 0
                ? "The admin can distribute all rewards"
                : nextDistributionTime > 120
                ? (nextDistributionTime / 60).toFixed(0) + " hours"
                : nextDistributionTime.toFixed(0) + " minutes"}{" "}
            </p>

            {distributionStepTime !== 0 ? (
              <p className="ct-mb-5">
                <strong>You can withdraw your rewards for: </strong>{" "}
                {distributionStepTime < 0
                  ? "The admin can close the distribution, take your rewards now."
                  : distributionStepTime > 120
                  ? (distributionStepTime / 60).toFixed(0) + " hours"
                  : distributionStepTime.toFixed(0) + " minutes"}{" "}
              </p>
            ) : (
              ""
            )}

            <small onClick={() => (this.state.nftsPanel ? this.setState({ nftsPanel: false }) : this.preloadStakingPoolNFTs())}>
              Load my NFTs (stake or unstake) <span className="fas fa-chevron-down"></span>
            </small>
            <small onClick={() => this.openStatistics()}>
              Load statistics <span className="fas fa-chevron-down"></span>
            </small>
            {this.state.stakerData?.poolData.owner.toLocaleLowerCase() === this.props.blockChain.controller?.selectedAccount?.toLocaleLowerCase() ? (
              <small onClick={() => this.setState({ adminConsole: !this.state.adminConsole })}>
                Admin panel <span className="fas fa-chevron-down"></span>
              </small>
            ) : (
              ""
            )}
          </div>
        </div>

        {this.state.adminConsole ? (
          <div className="ct-configuration">
            <div className="ct-token">
              <h5>Add new token</h5>
              <strong>
                Selected token:{" "}
                <a className="ct-address" href={"http://scan.meter.io/address/" + this.state.selectedToken} target="_blank" rel="noopener noreferrer">
                  {this.state.selectedToken}
                </a>
              </strong>
              {appConfig.mode === AppMode.TEST_METER ? (
                <Select
                  className="ct-token-selector"
                  isMulti={false}
                  placeholder="Select your token..."
                  onChange={(e) => this.setState({ selectedToken: e ? e.value : "" })}
                  options={[
                    {
                      value: "0x1D12E9f21dc4145689D8B9f1d3C1Eb6Ed6e6cC68",
                      key: "0x1D12E9f21dc4145689D8B9f1d3C1Eb6Ed6e6cC68",
                      label: (
                        <div className="ct-token-option">
                          <img src={FTBLogo} alt="" />
                          FTB
                        </div>
                      ),
                    },
                    {
                      value: "0x8a419ef4941355476cf04933e90bf3bbf2f73814",
                      key: "0x8a419ef4941355476cf04933e90bf3bbf2f73814",
                      label: (
                        <div className="ct-token-option">
                          <img src={MTRGLogo} alt="" />
                          MTRG
                        </div>
                      ),
                    },
                    {
                      value: "0x6af7f7cddfd0d69ba9b03863347bd762bd70f8dc",
                      key: "0x6af7f7cddfd0d69ba9b03863347bd762bd70f8dc",
                      label: (
                        <div className="ct-token-option">
                          <img src={VoltLogo} alt="" />
                          VOLT
                        </div>
                      ),
                    },
                  ]}
                />
              ) : (
                ""
              )}

              {appConfig.mode === AppMode.METER ? (
                <Select
                  className="ct-token-selector"
                  isMulti={false}
                  placeholder="Select your token..."
                  onChange={(e) => this.setState({ selectedToken: e ? e.value : "" })}
                  options={[
                    {
                      value: "0x6cfe9adad5215195c1aa9755daed29360e6ab986",
                      key: "0x6cfe9adad5215195c1aa9755daed29360e6ab986",
                      label: (
                        <div className="ct-token-option">
                          <img src={FTBLogo} alt="" />
                          FTB
                        </div>
                      ),
                    },
                    {
                      value: "0x228ebbee999c6a7ad74a6130e81b12f9fe237ba3",
                      key: "0x228ebbee999c6a7ad74a6130e81b12f9fe237ba3",
                      label: (
                        <div className="ct-token-option">
                          <img src={MTRGLogo} alt="" />
                          MTRG
                        </div>
                      ),
                    },
                    {
                      value: "0x8df95e66cb0ef38f91d2776da3c921768982fba0",
                      key: "0x8df95e66cb0ef38f91d2776da3c921768982fba0",
                      label: (
                        <div className="ct-token-option">
                          <img src={VoltLogo} alt="" />
                          VOLT
                        </div>
                      ),
                    },
                  ]}
                />
              ) : (
                ""
              )}

              {appConfig.mode === AppMode.DEV ? (
                <Select
                  className="ct-token-selector"
                  isMulti={false}
                  placeholder="Select your token..."
                  onChange={(e) => this.setState({ selectedToken: e ? e.value : "" })}
                  options={[
                    {
                      value: "0x6cfe9adad5215195c1aa9755daed29360e6ab986",
                      key: "0x6cfe9adad5215195c1aa9755daed29360e6ab986",
                      label: (
                        <div className="ct-token-option">
                          <img src={FTBLogo} alt="" />
                          FTB
                        </div>
                      ),
                    },
                    {
                      value: "0x228ebbee999c6a7ad74a6130e81b12f9fe237ba3",
                      key: "0x228ebbee999c6a7ad74a6130e81b12f9fe237ba3",
                      label: (
                        <div className="ct-token-option">
                          <img src={MTRGLogo} alt="" />
                          MTRG
                        </div>
                      ),
                    },
                    {
                      value: this.props.appState.appData?.contractsAddress[Contract.TOKEN] || "0",
                      key: this.props.appState.appData?.contractsAddress[Contract.TOKEN] || "0",
                      label: (
                        <div className="ct-token-option">
                          <img src={MTRGLogo} alt="" />
                          TestMTRG
                        </div>
                      ),
                    },
                  ]}
                />
              ) : (
                ""
              )}

              <button
                onClick={() => {
                  if (this.state.selectedToken && this.state.instance) {
                    this.state.instance.addToken(this.state.selectedToken, () => setTimeout(() => this.preUpdatePool(), 1000));
                  }
                }}
                className="ct-main-button"
              >
                Add token
              </button>

              <h5>Active tokens</h5>
              <div className="ct-active-tokens-list">
                {this.state.pool?._tokens.map((token: string, index: number) => {
                  return (
                    <div
                      key={index + this.state.pool?._tokens[index]}
                      onClick={() => {
                        if (this.state.instance) {
                          this.state.instance.removeToken(this.state.pool?._tokens[index], () => setTimeout(() => this.preUpdatePool(), 1000));
                        }
                      }}
                    >
                      <span className="fas fa-dollar-sign"></span>
                      {UtilsHelpers.getTokenSymbolByAdress(token)}
                      <span className="fas fa-times"></span>
                    </div>
                  );
                })}
              </div>
              <small className="ct-mt-5">Click in the token to remove it from your staking pool.</small>
            </div>
            <div className="ct-collection">
              <h5>Add new collection</h5>
              <small>Name: {this.state.collectionData?.name}</small>
              <small>Symbol: {this.state.collectionData?.symbol}</small>
              <small>Total supply: {this.state.collectionData?.totalSupply}</small>
              <a href={"http://scan.meter.io/address/" + this.state.collectionData?.address} target="_blank" rel="noopener noreferrer">
                Collection contract: {this.state.collectionData?.address}
              </a>
              <input
                className="ct-collection-selector"
                type="text"
                placeholder={"Collection address..."}
                value={this.state.collectionData?.address || ""}
                onChange={(e) => this.autoUpdateCollection(e.target.value)}
              />
              <button
                onClick={() => {
                  if (this.state.collectionData?.address && this.state.instance) {
                    this.state.instance.addNFTs(this.state.collectionData.address, () => setTimeout(() => this.preUpdatePool(), 1000));
                  }
                }}
                className="ct-main-button"
              >
                Add collection
              </button>
              <small>Before adding the collection please check if the name and symbol are correct.</small>
              <h5>Active collections</h5>
              <div className="ct-active-collections-list">
                {this.state.pool?._collections.map((collection: string, index: number) => {
                  return (
                    <div
                      onClick={() => {
                        if (this.state.instance) {
                          this.state.instance.removeNFTs(this.state.pool?._collections[index], () => setTimeout(() => this.preUpdatePool(), 1000));
                        }
                      }}
                      key={index + collection}
                    >
                      {UtilsHelpers.getCollectionNameByAddress(collection)} <span className="fas fa-times"></span>
                    </div>
                  );
                })}
              </div>
              <small className="ct-mt-5">Click in the collection to remove it from your staking pool.</small>
            </div>
            <div className="ct-distribution">
              <button
                className="ct-main-button"
                onClick={() => {
                  if (this.state.instance) {
                    if (Number(this.state.stakerData?.poolData.state) === 0) {
                      this.state.instance.startPool(() => setTimeout(() => this.preUpdatePool(), 1000));
                    } else {
                      if (Number(this.state.stakerData?.poolData.state) === 1) {
                        this.state.instance.startDistribution(() => setTimeout(() => this.preUpdatePool(), 1000));
                      } else if (Number(this.state.stakerData?.poolData.state) === 2) {
                        this.state.instance.finishDistribution(() => setTimeout(() => this.preUpdatePool(), 1000));
                      }
                    }
                  }
                }}
              >
                {Number(this.state.stakerData?.poolData.state) === 0
                  ? "Start pool"
                  : Number(this.state.stakerData?.poolData.state) === 2
                  ? "Start new cycle, close distributions"
                  : "Finish cycle, start distribution"}
              </button>
              {Number(this.state.stakerData?.poolData.state) === 0 ? (
                ""
              ) : Number(this.state.stakerData?.poolData.state) === 1 ? (
                <small>
                  You can finish the cycle when the time is over (In{" "}
                  {nextDistributionTime < 0
                    ? "0 seconds"
                    : nextDistributionTime > 120
                    ? (nextDistributionTime / 60).toFixed(0) + " hours"
                    : nextDistributionTime.toFixed(0) + " minutes"}
                  )
                </small>
              ) : (
                <small>
                  If you close the distribution and the users dont withdraw their rewards you will send the rewards to the burning address. ({" "}
                  {distributionStepTime < 0
                    ? "The admin can close the distribution, take your rewards now."
                    : distributionStepTime > 120
                    ? (distributionStepTime / 60).toFixed(0) + " hours"
                    : distributionStepTime.toFixed(0) + " minutes"}{" "}
                  to close the distributions)
                </small>
              )}

              {this.state.pool?._tokens.map((token: string, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <SendTokenStakingRewards
                      key={index + "send"}
                      address={token}
                      onError={() => {}}
                      instance={this.state.instance}
                    ></SendTokenStakingRewards>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )}

        {this.state.nftsPanel ? (
          <div className="ct-nfts-panel">
            {(this.state.preloadedNFTs?.length || 0) > 0 ? (
              <React.Fragment>
                <div className="ct-nfts-list">
                  {this.state.preloadedNFTs?.map((nft, index) => {
                    const approved = nft.approval;
                    const instance = nft.instance;
                    const list = nft.preloadedNFTs?.data || [];
                    const stakedNFTs = nft.stakedNFTs || [];

                    return (
                      <div className="ct-collection-container" key={index}>
                        {list.map((nftCard: any) => {
                          const isStaked = stakedNFTs.indexOf(nftCard._nftID?.toString()) !== -1;

                          if (nftCard) {
                            return (
                              <div
                                key={nftCard._nftID + index}
                                onClick={() => {
                                  if (instance) {
                                    if (approved) {
                                      if (isStaked)
                                        this.state.instance.unstake(nftCard._collection, [nftCard._nftID], () => this.preloadStakingPoolNFTs());
                                      else this.state.instance.stake(nftCard._collection, [nftCard._nftID], () => this.preloadStakingPoolNFTs());
                                    } else instance.setApprovalForAll(this.props.pool._address, true, () => this.preloadStakingPoolNFTs());
                                  }
                                }}
                                className={"ct-loaded-nft" + (approved ? (isStaked ? " ct-staked" : "") : " ct-non-approved")}
                              >
                                {!approved ? (
                                  <div className="ct-no-approved">
                                    <h4>Approve your NFTs</h4>
                                  </div>
                                ) : (
                                  ""
                                )}

                                {isStaked ? <span className="fas fa-lock"></span> : <span className="fas fa-plane"></span>}

                                <img src={nftCard?._attributes?.image} alt="" />
                              </div>
                            );
                          } else return "";
                        })}
                      </div>
                    );
                  })}
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {/*
        {this.state.statisticsPanel ? (
          <div className="ct-statistics-panel">
            {Object.keys(this.state.tokensRegisters).length > 0 ? (
              <Chart
                options={{
                  layout: {
                    backgroundColor: "#fff",
                    textColor: "#000",
                  },
                  leftPriceScale: {
                    visible: true,
                  },
                  rightPriceScale: {
                    scaleMargins: {
                      top: 0.1,
                      bottom: 0.1,
                    },
                    visible: true,
                    autoScale: false,
                  },
                  grid: {
                    vertLines: {
                      color: "rgba(197, 203, 206, 0.4)",
                      style: LineStyle.Dotted,
                    },
                    horzLines: {
                      color: "rgba(197, 203, 206, 0.4)",
                      style: LineStyle.Dotted,
                    },
                  },
                }}
                areaSeries={Object.keys(this.state.tokensRegisters).map((token, index) => {
                  return {
                    ...chartsConfig[index],
                    data: this.state.tokensRegisters[token],
                    topColor: "rgba(67, 83, 254, 0.7)",
                    bottomColor: "rgba(67, 83, 254, 0.3)",
                    lineColor: "rgba(67, 83, 254, 1)",
                    lineWidth: 2,
                    title: token,
                  };
                })}
                autoWidth
                height={320}
              />
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )} */}
      </div>
    );
  }
}
