import Web3 from "web3";
import React from "react";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { TokenController } from "../../core/modules/token";
import { appConfig, AppErrorCode, Contract } from "../../core/app";
import { UniverseNFTController } from "../../core/modules/universeNFT";
import { ApiHelpers } from "../../core/helpers/api";

interface AllCollectionsComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadBlockChain: () => void;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface Collection {
  _address: string;
  _gallery: Array<any>;
  _id: string;
  _image: string;
  _initialPrice: number;
  _maxSupply: number;
  _name: string;
  _owner: string;
  _price: number;
  _symbol: string;
  _time: string;
  _protected: boolean;
  _hide: boolean;
  _official: boolean;
  _alternativeLink: string;
}

interface AllCollectionsComponentState {
  collections: Collection[];
  universalNFT: null | UniverseNFTController;
}

export class AllCollectionsPage extends React.PureComponent<AllCollectionsComponentProps, AllCollectionsComponentState> {
  constructor(props: AllCollectionsComponentProps) {
    super(props);

    this.state = {
      collections: [],
      universalNFT: null,
    };
  }

  async componentDidMount() {
    this.preloadControllers();
    this.props.onToggleLoader(false);
  }

  async preloadControllers(closeLoader: boolean = false) {
    this.setState({ collections: await ApiHelpers.lastCollections() });
  }

  componentDidUpdate(prevProps: AllCollectionsComponentProps) {
    if (!prevProps.blockChain.controller?.selectedAccount && this.props.blockChain.controller?.selectedAccount) {
      this.preloadControllers();
    }
  }

  private async _onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this.preloadControllers();
  }

  render() {
    return (
      <React.Fragment>
        <div className="ct-my-collections">
          {/* 
          {this.state.collections.length > 0 ? (
            <React.Fragment>
              <div className="ct-last-collections">
                {[this.state.collections[0], this.state.collections[1]].map((collection) => {
                  console.log(collection);
                  if (collection) {
                    return (
                      <div className="ct-last-collection">
                        <img src={collection._image} alt="" />
                        <div className="ct-info">
                          <h4>{collection._name}</h4>
                        </div>
                      </div>
                    );
                  } else return "";
                })}
              </div>
            </React.Fragment>
          ) : (
            ""
          )} */}

          <div className="ct-max-container ct-collections-list">
            {this.state.collections.length > 0 ? (
              this.state.collections.map((collection: Collection, index: number) => {
                if (collection._hide) {
                  return "";
                } else {
                  return (
                    <div
                      className={
                        "ct-minted ct-minted-collection" +
                        (collection._official ? " ct-official" : collection._protected ? " ct-protected" : " ct-unprotected")
                      }
                      key={index}
                    >
                      <div className="ct-info">
                        <h3>{collection._name}</h3>
                        <a
                          href={collection._alternativeLink ? collection._alternativeLink : "/collection?address=" + collection._address}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          minting page
                        </a>
                      </div>
                      <img src={collection._image} alt="" />
                    </div>
                  );
                }
              })
            ) : (
              <h4>Go to the minting page. You don't have any collection</h4>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
