import React from "react";
import Dropzone from "react-dropzone";
import Select from "react-select";
import * as Scroll from "react-scroll";

import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { TokenController } from "../../core/modules/token";
import { UtilsHelpers } from "../../core/helpers/utils";
import { appConfig, AppErrorCode, AppMode, Contract } from "../../core/app";
import { UniverseNFTController } from "../../core/modules/universeNFT";
import { ApiHelpers } from "../../core/helpers/api";
import { toast } from "react-toastify";

import Anim1 from "../../assets/images/animations/1.png";
import Anim2 from "../../assets/images/animations/2.jpg";
import Anim3 from "../../assets/images/animations/3.gif";
import Anim4 from "../../assets/images/animations/4.gif";
import Anim5 from "../../assets/images/animations/5.jpg";
import Anim6 from "../../assets/images/animations/6.jpg";
import Anim7 from "../../assets/images/animations/7.png";
import Anim8 from "../../assets/images/animations/8.gif";
import Anim9 from "../../assets/images/animations/9.png";
import Anim10 from "../../assets/images/animations/10.png";
import Anim11 from "../../assets/images/animations/11.png";
import Anim12 from "../../assets/images/animations/12.jpg";
import Anim13 from "../../assets/images/animations/13.png";
import Anim14 from "../../assets/images/animations/14.jpg";
import Anim15 from "../../assets/images/animations/15.png";
import Anim16 from "../../assets/images/animations/16.png";

import VoltLogo from "../../assets/images/VOLT.png";
import FTBLogo from "../../assets/images/BB_LOGO.svg";
import MTRGLogo from "../../assets/images/meter-network.png";
import PromotionImage from "../../assets/images/universities.jpg";
import { UniverseMintingController } from "../../core/modules/universalMinting";

interface CollectionGeneratorComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadBlockChain: () => void;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface NFT {
  id: number;
  collection: string;
  name: string;
  image: string;
  metadata: { type: string; data: string }[];
}

interface CollectionGeneratorComponentState {
  tokenAllowance: number;
  mintingAllowance: number;
  protectedAllowance: number;
  imageSelector: number;
  maxSupply: number;
  initialPrice: number;
  baseUri: string;
  symbol: string;
  collectionName: string;
  error: null | string;
  token: null | TokenController;
  universalNFT: null | UniverseNFTController;
  universalMinting: null | UniverseMintingController;
  selectedImages: File[];
  shuffleInterval: any;
  softNFTs: NFT[];
  lastMintedNFTs: any[];
  selectedToken: string;
  ownerCollections: any[];
}

export class CollectionGeneratorPage extends React.PureComponent<CollectionGeneratorComponentProps, CollectionGeneratorComponentState> {
  constructor(props: CollectionGeneratorComponentProps) {
    super(props);

    this.state = {
      collectionName: "",
      baseUri: "",
      symbol: "",
      selectedToken: "",
      maxSupply: 0,
      initialPrice: 0,
      tokenAllowance: 0,
      mintingAllowance: 0,
      protectedAllowance: 0,
      universalMinting: null,
      imageSelector: 0,
      token: null,
      universalNFT: null,
      shuffleInterval: null,
      error: null,
      selectedImages: [],
      softNFTs: [],
      lastMintedNFTs: [],
      ownerCollections: [],
    };
  }

  async componentDidMount() {
    this.preloadControllers();
    this.loadAndSetOwnerCollections();
    this.loadAndSetLastMinted();
    this.randomizeMintedNFTs();

    this.props.onToggleLoader(false);
  }

  async preloadControllers(closeLoader: boolean = false) {
    let token = null;
    let tokenAllowance = 0;
    let protectedAllowance = 0;
    let mintingAllowance = 0;
    let universalFactory = null;
    let universalNFT = null;

    if (this.props.blockChain.controller?.token && this.props.appState.appData) {
      token = new TokenController(this.props.blockChain.controller.token);

      tokenAllowance = UtilsHelpers.normalizeWei(
        await this.props.blockChain.controller.token.allowance(
          this.props.blockChain.controller.token.selectedAccount,
          this.props.appState.appData?.contractsAddress[Contract.UNIVERSAL_FACTORY]
        )
      );

      mintingAllowance = UtilsHelpers.normalizeWei(
        await this.props.blockChain.controller.token.allowance(
          this.props.blockChain.controller.token.selectedAccount,
          this.props.appState.appData?.contractsAddress[Contract.UNIVERSAL_MINTER]
        )
      );
    }

    if (this.props.blockChain.controller?.universalFactory) {
      universalFactory = new UniverseNFTController(this.props.blockChain.controller.universalFactory);
      await universalFactory.initialize();
    }

    if (this.props.blockChain.controller?.universalMinter) {
      universalNFT = new UniverseMintingController(this.props.blockChain.controller.universalMinter);
      await universalNFT.initialize();
    }

    this.setState(
      {
        token,
        tokenAllowance,
        protectedAllowance,
        universalNFT: universalFactory,
        universalMinting: universalNFT,
        mintingAllowance,
      },
      () => {
        if (closeLoader) this.props.onToggleLoader(false);
      }
    );
  }

  async loadAndSetOwnerCollections() {
    if (this.props.blockChain.controller?.selectedAccount) {
      const collections = await ApiHelpers.collectionsByOwner(this.props.blockChain.controller.selectedAccount);
      if (Array.isArray(collections)) this.setState({ ownerCollections: collections });
    }
  }

  componentWillUnmount() {
    if (this.state.shuffleInterval) clearInterval(this.state.shuffleInterval);
  }

  componentDidUpdate(prevProps: CollectionGeneratorComponentProps) {
    if (!prevProps.blockChain.controller?.selectedAccount && this.props.blockChain.controller?.selectedAccount) {
      this.preloadControllers();
      this.loadAndSetOwnerCollections();
    }
  }

  async onDropImage(files: File[]) {
    if (UtilsHelpers.validateFiles(files, ["image/png", "image/jpeg", "image/jpg"])) {
      if (UtilsHelpers.validateFilesSize(files, 5485760)) {
        const promises = [];

        for (let i = 0; i < files.length; i++) {
          const fileReader = new FileReader();

          promises.push(
            new Promise((res) => {
              fileReader.onload = () => {
                res({
                  image: fileReader.result,
                  id: i,
                  name: i + 1,
                } as unknown);
              };

              fileReader.readAsDataURL(files[i]);
            })
          );
        }

        const resolved = await Promise.all(promises);

        this.setState({
          selectedImages: files,
          imageSelector: 2,
          softNFTs: resolved as NFT[],
        });
      } else this.setState({ error: "Invalid file size." });
    } else this.setState({ error: "Invalid file extensions." });
  }

  async softMint(protection: boolean = false) {
    const multipart = new FormData();

    const collection = {
      name: this.state.collectionName,
      symbol: this.state.symbol,
      maxSupply: this.state.maxSupply,
      initialPrice: this.state.initialPrice,
      baseUri: this.state.baseUri,
      token: this.state.selectedToken,
      image: "",
    };

    for (let i = 0; i < this.state.softNFTs.length; i++) {
      multipart.append("images", this.state.selectedImages[i], i.toString());
    }

    // Upload images
    const uploadImage = await ApiHelpers.uploadImages(multipart);

    if (uploadImage && uploadImage.data && Array.isArray(uploadImage.data)) {
      collection.image = uploadImage.data[0]?.path || "";

      if (protection) {
      } else {
        if (this.props.blockChain.controller?.universalFactory) {
          try {
            this.props.blockChain.controller.universalFactory.generateNFTContract(
              collection.name,
              collection.symbol,
              collection.baseUri,
              collection.token,
              (error, receipt) => {
                if (error) {
                  toast.error("Invalid creation. Try later or send an email to admin@businessbuilders.city");
                } else {
                  ApiHelpers.softCreatedCollection(
                    collection.image,
                    collection.name,
                    collection.symbol,
                    collection.baseUri,
                    collection.token,
                    receipt.events?.NewUniversalNFT?.returnValues[0]
                  )
                    .then(() => {
                      setTimeout(() => {
                        this._onUpdateData(error);
                        toast.success("Created collection. Go to my collection section.");
                      }, 3000);
                    })
                    .catch(() => {
                      toast.info("The server isn't working properly but your contract was created correctly. Go to the contracts section.");
                    });
                }
              }
            );
          } catch (error) {
            console.log(error);

            toast.error("Invalid collection creation. Please contact the developer and report the error");
          }
        }
      }
    } else toast.error("Invalid image uploading. Please, try later.");
  }

  async loadAndSetLastMinted() {
    const images = [Anim1, Anim2, Anim3, Anim4, Anim5, Anim6, Anim7, Anim8, Anim9, Anim10, Anim11, Anim12, Anim13, Anim14, Anim15, Anim16];

    this.setState({
      lastMintedNFTs: [...images, ...images, ...images, ...images, ...images],
    });
  }

  randomizeMintedNFTs() {
    const shuffleInterval = setInterval(() => {
      let shuffledArray = [...this.state.lastMintedNFTs];
      let currentIndex = shuffledArray.length;
      let randomIndex = 0;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [shuffledArray[currentIndex], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[currentIndex]];
      }

      this.setState({ lastMintedNFTs: shuffledArray });
    }, 5000);

    this.setState({ shuffleInterval });
  }

  async _onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    await this.preloadControllers();
    this.loadAndSetOwnerCollections();
  }

  render() {
    return (
      <React.Fragment>
        <div className="ct-universal-minter">
          <div className="ct-minted-animations">
            <div className="ct-list">
              {this.state.lastMintedNFTs.map((nft, index) => {
                return (
                  <div key={index} className="ct-nft">
                    <img src={nft} alt="" />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="ct-minted-shadow"></div>

          <div className="ct-pt-20 ct-max-container ct-universal-minter-container">
            <div className="ct-soft-mint">
              <h3>create your own collection</h3>

              <div className="ct-new-nft">
                <div className="ct-normal-data">
                  <div className="ct-collection">
                    <label htmlFor="ct-collection">
                      <strong>collection name</strong>
                    </label>
                    <input
                      type="text"
                      name="ct-collection"
                      id="ct-collection"
                      placeholder="name"
                      value={this.state.collectionName}
                      onChange={(e) => {
                        this.setState({ collectionName: e.target.value });
                      }}
                    />
                  </div>
                  <div className="ct-symbol">
                    <label htmlFor="ct-symbol">
                      <strong>collection symbol</strong>
                    </label>
                    <input
                      type="text"
                      name="ct-symbol"
                      id="ct-symbol"
                      placeholder="symbol"
                      value={this.state.symbol}
                      onChange={(e) => {
                        this.setState({ symbol: e.target.value });
                      }}
                    />
                  </div>
                  <div className="ct-uri">
                    <label htmlFor="ct-uri">
                      <strong>Base URL</strong>
                    </label>
                    <input
                      type="text"
                      name="ct-uri"
                      id="ct-uri"
                      placeholder="https://mycollection.com/"
                      value={this.state.baseUri}
                      onChange={(e) => {
                        this.setState({ baseUri: e.target.value });
                      }}
                    />
                  </div>
                  <div className="ct-token">
                    <label htmlFor="ct-token">
                      <strong>Token</strong>
                    </label>
                    <Select
                      className="ct-token-selector"
                      isMulti={false}
                      placeholder="Select your token..."
                      onChange={(e) => this.setState({ selectedToken: e ? e.value : "" })}
                      options={[
                        {
                          value: "0x6cfe9adad5215195c1aa9755daed29360e6ab986",
                          label: (
                            <div className="ct-token-option">
                              <img src={FTBLogo} alt="" />
                              FTB
                            </div>
                          ),
                        },
                        {
                          value:
                            appConfig.mode === AppMode.DEV
                              ? this.props.appState.appData?.contractsAddress[Contract.TOKEN] || ""
                              : appConfig.mode === AppMode.TEST_METER
                              ? "0x8a419ef4941355476cf04933e90bf3bbf2f73814"
                              : "0x228ebBeE999c6a7ad74A6130E81b12f9Fe237Ba3",
                          label: (
                            <div className="ct-token-option">
                              <img src={MTRGLogo} alt="" />
                              MTRG
                            </div>
                          ),
                        },
                        {
                          value: "0x8df95e66cb0ef38f91d2776da3c921768982fba0",
                          label: (
                            <div className="ct-token-option">
                              <img src={VoltLogo} alt="" />
                              VOLT
                            </div>
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
                {this.state.imageSelector !== 2 ? (
                  <div className="ct-image-selector">
                    <button onClick={() => this.setState({ imageSelector: 0 })}>Upload image</button>
                    <button disabled onClick={() => this.setState({ imageSelector: 1 })}>
                      Use image URL
                    </button>
                  </div>
                ) : (
                  ""
                )}
                {this.state.imageSelector === 2 ? (
                  <div className="ct-selected-images">
                    {this.state.softNFTs
                      ? this.state.softNFTs.map((nft: NFT, index: number) => {
                          return (
                            <div className="ct-nft-creator" key={"ct-image-element-" + index}>
                              <div className="ct-selected-image">
                                <img src={nft.image} id={"ct-image-element-" + index} alt="" />
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>
                ) : (
                  <div className="ct-image">
                    {this.state.imageSelector === 0 ? (
                      <div className="ct-dropzone">
                        <Dropzone onDrop={(file) => this.onDropImage(file)}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="ct-image-dropper">
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>
                                  Drop or click <br />
                                  Select your image
                                </p>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        {this.state.error ? <strong>Error: {this.state.error}</strong> : ""}
                        <small>
                          <strong>Max size: </strong> 5 MB
                        </small>
                        <small>
                          <strong>Max files: </strong> 1
                        </small>
                        <small>
                          <strong>Valid files: </strong> png, jpeg, jpg
                        </small>
                        <small>
                          <strong>The best size: </strong> 1080 x 720
                        </small>
                        <small className="ct-mt-5">
                          <strong>This will be the collection representative image. It is not related to the NFTs. </strong>
                        </small>
                      </div>
                    ) : this.state.imageSelector === 1 ? (
                      <div className="ct-url">
                        <div className="ct-urls">
                          <input type="text" placeholder="Image url" />
                        </div>
                        <button>Add URL</button>
                        <button>Generate URL seceunce.</button>
                        <small>
                          Use {"{data}"} to generate many urls. Example https://domain.com/{"{id}"}
                        </small>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
              <div className="ct-actions">
                <h4>contract creation information</h4>
                <p>
                  <strong>Protected collection:</strong> All funds will be locked on the contract while you are selling you NFTs, the users will be
                  able to vote and unlock or not your funds.{" "}
                </p>
                <p>
                  <strong>Unprotected collection: </strong>You will be able to withdraw all collected funds when you want.
                </p>
                <button
                  className="ct-mt-5 ct-main-button"
                  disabled={this.state.softNFTs.length === 0}
                  onClick={() =>
                    this.setState({
                      selectedImages: [],
                      softNFTs: [],
                      imageSelector: 0,
                      collectionName: "",
                      maxSupply: 0,
                      baseUri: "",
                    })
                  }
                >
                  Cancel
                </button>
                {(this.state.universalNFT?.data?.price || 0) > this.state.tokenAllowance ? (
                  <button
                    className="ct-mt-5 ct-main-button"
                    onClick={() => {
                      if (this.props.appState.appData) {
                        this.props.blockChain.controller?.token?.approve(
                          this.props.appState.appData?.contractsAddress[Contract.UNIVERSAL_FACTORY],
                          this.state.universalNFT?.data?.price || 0,
                          (error) => {
                            this._onUpdateData(error);
                          }
                        );
                      }
                    }}
                    disabled={!this.state.baseUri || !this.state.collectionName}
                  >
                    Approve unprotected ({this.state.universalNFT?.data?.price} MTRG)
                  </button>
                ) : (
                  <button
                    className="ct-mt-5 ct-main-button"
                    onClick={() => this.softMint()}
                    disabled={!this.state.baseUri || !this.state.collectionName}
                  >
                    Generate contract ({this.state.universalNFT?.data?.price} MTRG)
                  </button>
                )}
              </div>
              <div className="ct-go-to-mint">
                {this.props.blockChain.controller?.selectedAccount ? (
                  <Scroll.Link activeClass="active" to="test1" spy={true} smooth={true} offset={-100} duration={500}>
                    <button className="ct-main-button ct-go-to">Go to collections section</button>
                  </Scroll.Link>
                ) : (
                  <div className="ct-connect">
                    <h4>Connect your wallet and mint your own NFTs</h4>
                    <button className="ct-main-button" onClick={() => this.props.onLoadBlockChain()}>
                      Connect
                    </button>
                  </div>
                )}
              </div>
              {this.state.universalMinting?.data ? (
                <div className="ct-universal-key">
                  <div className="ct-key">
                    <img src={Anim3} alt="Lighting key" />
                  </div>
                  <div className="ct-data">
                    <h4>
                      Universal Key{" "}
                      <small>
                        ({this.state.universalMinting.data.keysSupply} / {this.state.universalMinting.data.maxKeys})
                      </small>
                    </h4>
                    <small>Generate collections fully free.</small>
                    <a href="https://docsuninft.businessbuilders.city/meter/universal-keys" target="_blank" rel="noopener noreferrer">
                      read more
                    </a>
                    {this.state.mintingAllowance >= this.state.universalMinting.data.keyPrice ? (
                      <button
                        onClick={() => {
                          if (this.props.blockChain.controller) {
                            this.props.blockChain.controller.universalMinter?.mintKey((error) => {
                              this._onUpdateData(error);
                            });
                          }
                        }}
                      >
                        buy ({this.state.universalMinting?.data?.keyPrice.toFixed(3)} MTRG)
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          if (
                            this.props.blockChain.controller &&
                            this.props.blockChain.controller.universalMinter &&
                            this.state.universalMinting?.data
                          ) {
                            this.props.blockChain.controller.token?.approve(
                              this.props.blockChain.controller.universalMinter?.address,
                              this.state.universalMinting?.data?.keyPrice + this.state.universalMinting?.data?.keyPrice * 0.1,
                              (error) => {
                                this._onUpdateData(error);
                              }
                            );
                          }
                        }}
                      >
                        approve ({this.state.universalMinting?.data?.keyPrice.toFixed(3)} MTRG)
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <Scroll.Element name="test1" />

          <div className="ct-promotor ct-max-container">
            <div className="ct-promotion-container">
              <div className="ct-image">
                <img src={PromotionImage} alt="" />
              </div>
              <div className="ct-data">
                <h4>FTB and DLCs burning system</h4>
                <p>Promote your project using our fully decentralized promotion system (100% tokens burned).</p>
                <button disabled className="ct-main-button">
                  comming soon
                </button>
              </div>
            </div>
          </div>

          {this.props.blockChain?.controller?.selectedAccount ? (
            <div className="ct-my-collections ct-max-container">
              <div className="ct-collections-container">
                <h4>created collections</h4>
                {this.state.ownerCollections.map((collection, index) => {
                  return (
                    <div key={index + "-" + collection._name} className={"ct-collection" + (collection._protected ? " ct-protected" : "")}>
                      <img src={collection._image} alt="" />
                      <div className="ct-data">
                        <h4>
                          {collection._name}
                          <small>({collection._symbol})</small>
                        </h4>
                        <a
                          href={collection._alternativeLink ? collection._alternativeLink : "/collection?address=" + collection._address}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          minting page
                        </a>
                        <a href={"/collection-admin?address=" + collection._address} target="_blank" rel="noopener noreferrer">
                          admin page
                        </a>
                        {collection._protected ? <span className="fas fa-user-shield"></span> : ""}
                        <span></span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </React.Fragment>
    );
  }
}
