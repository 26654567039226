import { AnyLengthString } from "aws-sdk/clients/comprehendmedical";
import React from "react";
import Web3 from "web3";
import { UniversalStakingPoolV2 } from "../../../core/contracts/universalStakingPool";
import { BlockChainHelpers } from "../../../core/helpers/chain";
import { UtilsHelpers } from "../../../core/helpers/utils";

import IERC20 from "../../../assets/contracts/static/ERC20.json";
import { Token } from "../../../core/contracts/token";
import { toast } from "react-toastify";

import "./stakingRewards.css";

interface ComponentProps {
  address: string;
  instance: UniversalStakingPoolV2;
  onError: () => void;
}

interface ComponentState {
  allowance: string;
  amount: string;
  tokenInstance: Token | null;
}

export class SendTokenStakingRewards extends React.PureComponent<
  ComponentProps,
  ComponentState
> {
  constructor(props: ComponentProps) {
    super(props);

    this.state = {
      allowance: "0",
      amount: "0",
      tokenInstance: null,
    };
  }

  async componentDidMount() {
    this.preloadComponent();
  }

  async preloadComponent() {
    let tokenInstance = null;
    let allowance = "0";

    const provider = await BlockChainHelpers.getProvider();

    if (this.props.instance.web3 && provider) {
      tokenInstance = new Token(
        new provider.eth.Contract(IERC20 as any, this.props.address),
        this.props.instance.web3,
        this.props.instance.selectedAccount
      );

      allowance = await tokenInstance.allowance(
        this.props.instance.selectedAccount,
        this.props.instance.address
      );
    }

    this.setState({ tokenInstance, allowance });
  }

  render() {
    const amount = Web3.utils.fromWei(this.state.amount || "0");
    const allowance = Web3.utils.fromWei(this.state.allowance || "0");

    return (
      <div className="ct-send-staking-rewards">
        <input
          type="number"
          placeholder={
            "1000" + UtilsHelpers.getTokenSymbolByAdress(this.props.address)
          }
          onChange={(e) =>
            this.setState({
              amount: Web3.utils.toWei(e.target.value.toString() || "0"),
            })
          }
          value={amount}
        />
        {Number(allowance) >= Number(amount) ? (
          <button
            onClick={() => {
              if (this.state.tokenInstance) {
                this.props.instance.addRewards(
                  this.props.address,
                  this.state.amount,
                  (error) => {
                    if (error) toast.error("[Staking pool] Error.");
                    else this.preloadComponent();
                  }
                );
              }
            }}
            className="ct-main-button"
          >
            send (
            {amount +
              " " +
              UtilsHelpers.getTokenSymbolByAdress(this.props.address)}
            )
          </button>
        ) : (
          <button
            onClick={() => {
              if (this.state.tokenInstance) {
                this.state.tokenInstance.approve(
                  this.props.instance.address,
                  Number(Web3.utils.fromWei(this.state.amount)) * 1.2,
                  (error) => {
                    if (error) toast.error("[Staking pool] Error.");
                    else this.preloadComponent();
                  }
                );
              }
            }}
            className="ct-main-button"
          >
            approve (
            {amount +
              " " +
              UtilsHelpers.getTokenSymbolByAdress(this.props.address)}
            )
          </button>
        )}
        <small>Add rewards to the pool</small>
      </div>
    );
  }
}
