import Web3 from "web3";
import { UniversalFactory } from "../contracts/universalFactory";
import { UniversalNFTGeneral } from "../contracts/universalNFTGeneral";
import { UniversalStaking } from "../contracts/universalStaking";
import { UniversalStakingPool } from "../contracts/universalStakingPool";
import { ApiHelpers } from "../helpers/api";
import UniversalStakingPoolData from "../../assets/contracts/UniversalStakingPool.json";
import UniversalStakingPoolOld from "../../assets/contracts/UniversalStakingPoolV1.json";
import { ContractsStateController } from "../contracts";
import { appConfig, AppMode } from "../app";
import { LPStaking } from "../contracts/LPStaking";
import { bool } from "aws-sdk/clients/signer";
import { Token } from "../contracts/token";
import { BlockChainHelpers } from "../helpers/chain";

import IERC20 from "../../assets/contracts/static/ERC20.json";
import { VoltDistributor } from "../contracts/VoltDistributor";

interface LPStakingData {
  canUnlock: boolean;
  dayUnlockPrice: string;
  lastRequest: number;
  locked: { amount: string; unlockTime: number };
  maxLockTime: number;
  minLockTime: number;
  power: number;
  requestTime: number;
  rewards: {
    finalValidation: boolean;
    lockedValidation: boolean;
    packageId: number;
    package: { mini: number; minPercentage: number; minTime: number };
    packageValidation: boolean;
    timeValidation: boolean;
  }[];
  totalLPs: string;
  lpsBalance: string;
  lpsAllowance: string;
  lpsInstance: Token;
}

interface VoltRewardsData {
  rewardsCurrency: string;
  totalLPs: string;
  cycleRewards: string;
  totalUsers: number;
  totalPoolPower: string;
  user: {
    rewards: string;
    percentage: number;
    totalLPs: string;
  };
}

interface UniversalStakingConfig {
  cycleTime: number;
  fee: string;
  formatedFee: number;
  hasKey: boolean;
  token: string;
  pools: any[];
  oldPools: { instance: any; nfts: any[]; address: string }[];
  lpStakingUserData: LPStakingData;
  voltRewardsData: VoltRewardsData;
}

export class UniveraleStakingController {
  data: UniversalStakingConfig | null = null;

  constructor(public contract: UniversalStaking, public lpStaking: LPStaking, public voltDistributor: VoltDistributor) {}

  async initialize() {
    const config = await this.contract.getConfig();
    const pools = await ApiHelpers.getStakingPools();
    const lpStakingUserData = await this.lpStaking.getUserData();
    const voltRewardsData = await this.voltDistributor.getContractInfo();

    // console.log(pools);

    // let oldPools: any = (
    //   appConfig.mode === AppMode.METER
    //     ? [
    //         "0xb0ffD18fD33f92cE5a3e3FBef6B5e11A1aa3c260",
    //         "0xD53C791292f36e2973DB5A2CF30D7Aa4AB46782B",
    //       ]
    //     : []
    // ).map(async (pool) => {
    //   return new Promise((res) => {
    //     const instance = new this.contract.web3.eth.Contract(
    //       UniversalStakingPoolOld as any,
    //       pool
    //     );

    //     instance.methods
    //       .getStakedNFTs(this.contract.selectedAccount)
    //       .call()
    //       .then(async (data: any) => {
    //         if (Array.isArray(data)) {
    //           const preloadedNFTs = await ApiHelpers.preloadExternalNFTs(
    //             data[0]?.collection,
    //             data[0]?.nfts
    //           );
    //           res({ instance, nfts: preloadedNFTs?.data || [] });
    //         } else res({ instance, nfts: [] });
    //       });
    //   });
    // });

    // oldPools = await Promise.all(oldPools);

    const provider = await BlockChainHelpers.getProvider();
    
    if (provider) {
      this.data = {
        ...config,
        oldPools: [],
        pools: Array.isArray(pools.data) ? pools.data.filter((pool: any) => pool._version === 2 && pool._active) : [],
        formatedFee: Number(Web3.utils.fromWei(config.fee)),
        voltRewardsData,
        lpStakingUserData: {
          ...lpStakingUserData,
          lpsInstance: new Token(
            new provider.eth.Contract(IERC20 as any, lpStakingUserData.lpsAddress),
            this.contract.web3,
            this.contract.selectedAccount
          ),
        } as LPStakingData,
      };
    }
  }
}
