import { ContractBaseModel } from "../abstracts/contract";
import { AbstractLPStaking } from "../abstracts/LPSStaking";
import { AppErrorCode } from "../app";

export class LPStaking extends ContractBaseModel<AbstractLPStaking> {
  vTime() {
    return this.contract.vTime(this.selectedAccount).call(this.config);
  }

  vPackage(id: number) {
    return this.contract.vPackage(id, this.selectedAccount).call(this.config);
  }

  vLock(id: number) {
    return this.contract.vLock(id, this.selectedAccount).call(this.config);
  }

  validate(id: number) {
    return this.contract.validate(id, this.selectedAccount).call(this.config);
  }

  power() {
    return this.contract.power(this.selectedAccount).call(this.config);
  }

  canUnlock() {
    return this.contract.canUnlock(this.selectedAccount).call(this.config);
  }

  hasLocked() {
    return this.contract.hasLocked(this.selectedAccount).call(this.config);
  }

  getUserData() {
    return this.contract.getUserData(3).call(this.config);
  }

  requestRewards(id: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .requestRewards(id)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  firstLock(amount: string, time: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .firstLock(amount, time)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }

  increaseLockTime(time: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .increaseLockTime(time)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }

  increaseLockAmount(amount: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .increaseLockAmount(amount)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }

  reduceLockTime(time: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .reduceLockTime(time)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }

  unlock(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .unlock()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }
}
