import Anim1 from "../../../assets/images/animations/1.png";
import Anim2 from "../../../assets/images/animations/2.jpg";
import Anim3 from "../../../assets/images/animations/3.gif";
import Anim4 from "../../../assets/images/animations/4.gif";
import Anim5 from "../../../assets/images/animations/5.jpg";
import Anim6 from "../../../assets/images/animations/6.jpg";
import Anim7 from "../../../assets/images/animations/7.png";
import Anim8 from "../../../assets/images/animations/8.gif";
import Anim9 from "../../../assets/images/animations/9.png";
import Anim10 from "../../../assets/images/animations/10.png";
import Anim11 from "../../../assets/images/animations/11.png";
import Anim12 from "../../../assets/images/animations/12.jpg";
import Anim13 from "../../../assets/images/animations/13.png";
import Anim14 from "../../../assets/images/animations/14.jpg";
import Anim15 from "../../../assets/images/animations/15.png";
import Anim16 from "../../../assets/images/animations/16.png";

import styles from "./styles.module.css";

const NFTImages: string[] = [
  Anim1,
  Anim2,
  Anim3,
  Anim4,
  Anim5,
  Anim6,
  Anim7,
  Anim8,
  Anim9,
  Anim10,
  Anim11,
  Anim12,
  Anim13,
  Anim14,
  Anim15,
  Anim16,
  Anim1,
  Anim2,
  Anim3,
  Anim4,
  Anim5,
  Anim6,
  Anim7,
  Anim8,
  Anim9,
  Anim10,
  Anim11,
  Anim12,
  Anim13,
  Anim14,
  Anim15,
  Anim16,
  Anim1,
  Anim2,
  Anim3,
  Anim4,
  Anim5,
  Anim6,
  Anim7,
  Anim8,
  Anim9,
  Anim10,
  Anim11,
  Anim12,
  Anim13,
  Anim14,
  Anim15,
  Anim16,
  Anim1,
  Anim2,
  Anim3,
  Anim4,
  Anim5,
  Anim6,
  Anim7,
  Anim8,
  Anim9,
  Anim10,
  Anim11,
  Anim12,
  Anim13,
  Anim14,
  Anim15,
  Anim16,
];

export const BackgroundAnimation = () => {
  return (
    <>
      <div className={styles.ctMintedAnimations}>
        <div className={styles.ctList}>
          {NFTImages.map((nft, index) => {
            return (
              <div key={index} className={styles.ctNFT}>
                <img src={nft} alt="" />
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.ctMintedShadow}></div>
    </>
  );
};
