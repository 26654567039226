import { AnyAction } from "redux";
import { AppErrorCode } from "../../../core/app";
import { BlockChain } from "../../../core/chain";
import { Customer } from "../../../core/customer";
import { AppActions, AppReducer } from "../../types";

export const blockChainReducerKey = AppReducer.BLOCK_CHAIN;

export interface BlockChainState {
  controller: BlockChain | null;
  customer: Customer | null;
  error: AppErrorCode | null;
  firstLoad: boolean;
}

const initialState: BlockChainState = {
  controller: null,
  customer: null,
  error: null,
  firstLoad: false,
};

export const blockChainReducer = (
  state: BlockChainState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case AppActions.SAVE_BLOCK_CHAIN_CONTROLLER:
      return BlockChain.saveBlockChainController(state, action.controller);
    case AppActions.DESTROY_BLOCK_CHAIN_CONTROLLER:
      return BlockChain.destroyBlockChainController(state);
    case AppActions.SET_BLOCK_CHAIN_ERROR:
      return BlockChain.setBlockChainError(state, action.error);
    case AppActions.SET_CUSTOMER:
      return Customer.setCustomer(state, action.customer);
    case AppActions.CLEAR_CUSTOMER:
      return Customer.clearCustomer(state);
    default:
      return state;
  }
};
