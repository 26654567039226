import React from "react";

import "./folowCursor.style.css";

interface CardState {
  offsetX: number;
  offsetY: number;
  friction: number;
}

interface CardProps {}

export class FollowCursorCard extends React.Component<CardProps, CardState> {
  constructor(props: CardProps) {
    super(props);

    this.state = {
      offsetX: 0,
      offsetY: 0,
      friction: 1 / 32,
    };

    this._mouseMove = this._mouseMove.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousemove", this._mouseMove);
  }
  componentWillUnmount() {
    document.removeEventListener("mousemove", this._mouseMove);
  }

  _mouseMove(e: any) {
    let followX = window.innerWidth / 2 - e.clientX;
    let followY = window.innerHeight / 2 - e.clientY;

    let x = 0;
    let y = 0;

    x += (-followX - x) * this.state.friction;
    y += (followY - y) * this.state.friction;

    this.setState({
      offsetX: x,
      offsetY: y,
    });
  }

  render() {
    let offset = {
      transform: `translate(-50%, -50%) perspective(600px)
                    rotateY(${this.state.offsetX}deg)
                    rotateX(${this.state.offsetY}deg)`,
    };

    return (
      <div className="wrapper" style={offset}>
        <div className="shape">{this.props.children}</div>
      </div>
    );
  }
}
