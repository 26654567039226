import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";
import { AbstractToken } from "../abstracts/token";

export class Token extends ContractBaseModel<AbstractToken> {
  name() {
    return this.contract.name().call(this.config);
  }

  symbol() {
    return this.contract.symbol().call(this.config);
  }

  decimals() {
    return this.contract.decimals().call(this.config);
  }

  totalSupply() {
    return this.contract.totalSupply().call(this.config);
  }

  balanceOf(owner: string) {
    return this.contract.balanceOf(owner).call(this.config);
  }

  allowance(owner: string, spender: string) {
    return this.contract.allowance(owner, spender).call(this.config);
  }

  increaseAllowance(spender: string, amount: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .increaseAllowance(spender, amount)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.SEND_EMPLOYEE_TO_STAKING, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.SEND_EMPLOYEE_TO_STAKING, error, callback));
  }

  approve(spender: string, amount: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .approve(spender, this.web3.utils.toWei(amount.toString(), "ether"))
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.APPROVE_SPEND, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.APPROVE_SPEND, error, callback));
  }
}
