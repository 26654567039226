import { ContractBaseModel } from "../abstracts/contract";
import { AbstractMiniBidding } from "../abstracts/miniBidding";
import { AppErrorCode } from "../app";

export class MiniBidding extends ContractBaseModel<AbstractMiniBidding> {
  getBiddingConfig() {
    return this.contract.getBiddingConfig().call(this.config);
  }

  bid(id: number) {
    return this.contract.bid(id).call(this.config);
  }

  manyBids(ids: number[]) {
    return this.contract.manyBids(ids).call(this.config);
  }

  canBid(id: number) {
    return this.contract.canBid(id).call(this.config);
  }

  getNext(id: number) {
    return this.contract.getNext(id).call(this.config);
  }

  getValues(amount: string) {
    return this.contract.getValues(amount).call(this.config);
  }

  createRequest(id: number, token: string, nftID: number, initialPrice: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .createRequest(id, token, nftID, initialPrice)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  bidding(id: number, amount: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .bidding(id, amount)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }

  generateMiniEmployee(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .generateMiniEmployee()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }

  finish(id: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .finish(id)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }
}
