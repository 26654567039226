import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";
import { AbstractBidding } from "../abstracts/bidding";

export class Bidding extends ContractBaseModel<AbstractBidding> {
  getBiddingConfig() {
    return this.contract.getBiddingConfig().call(this.config);
  }

  bid(nft: string, id: number) {
    return this.contract.bid(nft, id).call(this.config);
  }

  manyBids(nfts: { nft: string; id: number }[]) {
    return this.contract.manyBids(nfts).call(this.config);
  }

  canBid(nft: string, id: number) {
    return this.contract.canBid(nft, id).call(this.config);
  }

  getNext(nft: string, id: number) {
    return this.contract.getNext(nft, id).call(this.config);
  }

  getValues(amount: string) {
    return this.contract.getValues(amount).call(this.config);
  }

  createRequest(nft: string, token: string, id: number, initialPrice: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .createRequest(nft, token, id, initialPrice)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }

  bidding(nft: string, id: number, amount: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .bidding(nft, id, amount)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        console.log(receipt);
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }

  finish(nft: string, id: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .finish(nft, id)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }

  cancelRequest(nft: string, id: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .cancelRequest(nft, id)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback));
  }
}
