import React from "react";
import Select from "react-select";
import Web3 from "web3";

import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { appConfig, AppErrorCode, AppMode, Contract } from "../../core/app";

import Anim1 from "../../assets/images/animations/1.png";
import Anim2 from "../../assets/images/animations/2.jpg";
import Anim3 from "../../assets/images/animations/3.gif";
import Anim4 from "../../assets/images/animations/4.gif";
import Anim5 from "../../assets/images/animations/5.jpg";
import Anim6 from "../../assets/images/animations/6.jpg";
import Anim7 from "../../assets/images/animations/7.png";
import Anim8 from "../../assets/images/animations/8.gif";
import Anim9 from "../../assets/images/animations/9.png";
import Anim10 from "../../assets/images/animations/10.png";
import Anim11 from "../../assets/images/animations/11.png";
import Anim12 from "../../assets/images/animations/12.jpg";
import Anim13 from "../../assets/images/animations/13.png";
import Anim14 from "../../assets/images/animations/14.jpg";
import Anim15 from "../../assets/images/animations/15.png";
import Anim16 from "../../assets/images/animations/16.png";
import AnimatedEmployees from "../../assets/images/collection_gif_white.gif";

import VoltLogo from "../../assets/images/VOLT.png";
import DLCLogo from "../../assets/images/DLC.png";
import FTBLogo from "../../assets/images/BB_LOGO.svg";
import BUSDLogo from "../../assets/images/BUSD.png";
import MTRGLogo from "../../assets/images/meter-network.png";
import IERC721 from "../../assets/contracts/static/ERC721.json";

import { ApiHelpers } from "../../core/helpers/api";
import { toast } from "react-toastify";
import { BlockChainHelpers } from "../../core/helpers/chain";
import { UniversalNFTGeneral } from "../../core/contracts/universalNFTGeneral";
import { BiddingComponent } from "../organisms/bidding/bidding";
import { ConfigurationServicePlaceholders } from "aws-sdk/lib/config_service_placeholders";
import { UtilsHelpers } from "../../core/helpers/utils";
import { MiniBiddingComponent } from "../organisms/bidding/miniBidding";

interface BiddingProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadBlockChain: () => void;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface BiddingState {
  backgroundImages: string[];
  shuffleInterval: any;
  nftInstance: UniversalNFTGeneral | null;
  selectedToken: string;
  initialPrice: string;
  selectedNFT: string;
  selectedID: string;
  preloadedNFT: any;
  biddingInfo: any;
  miniBiddingInfo: any;
  nftAllowance: boolean;
  lastBids: any[];
  loadedMiniBids: any[];
}

export class BiddingPage extends React.PureComponent<BiddingProps, BiddingState> {
  constructor(props: BiddingProps) {
    super(props);

    this.state = {
      backgroundImages: [],
      shuffleInterval: null,
      nftInstance: null,
      selectedToken: "",
      initialPrice: "",
      selectedNFT: "",
      biddingInfo: null,
      miniBiddingInfo: null,
      selectedID: "",
      preloadedNFT: null,
      nftAllowance: false,
      lastBids: [],
      loadedMiniBids: [],
    };
  }

  async componentDidMount() {
    this.props.onToggleLoader(false);

    this.preloadControllers();
    this.loadAndSetBackground();
    this.randomizeBackground();
  }

  componentDidUpdate(prevProps: BiddingProps) {
    if (
      (!prevProps.blockChain.controller?.selectedAccount && this.props.blockChain.controller?.selectedAccount) ||
      (!prevProps.appState.appData && this.props.appState.appData)
    ) {
      this.preloadControllers();
    }
  }

  async loadAndSetBackground() {
    const images = [Anim1, Anim2, Anim3, Anim4, Anim5, Anim6, Anim7, Anim8, Anim9, Anim10, Anim11, Anim12, Anim13, Anim14, Anim15, Anim16];

    this.setState({
      backgroundImages: [...images, ...images, ...images, ...images, ...images],
    });
  }

  randomizeBackground() {
    const shuffleInterval = setInterval(() => {
      let shuffledArray = [...this.state.backgroundImages];
      let currentIndex = shuffledArray.length;
      let randomIndex = 0;

      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        [shuffledArray[currentIndex], shuffledArray[randomIndex]] = [shuffledArray[randomIndex], shuffledArray[currentIndex]];
      }

      this.setState({ backgroundImages: shuffledArray });
    }, 5000);

    this.setState({ shuffleInterval });
  }

  async preloadControllers() {
    if (this.props.blockChain) {
      let bids: any[] = [];
      let loadedMiniBids: any[] = [];
      let biddingInfo = null;
      let miniBiddingInfo = null;

      try {
        const loadedMiniBidsData = await ApiHelpers.getUniversalMiniBids();
        const loadedBidsData = await ApiHelpers.getUniversalBids();

        console.log(loadedBidsData);

        biddingInfo = await this.props.blockChain.controller?.bidding?.getBiddingConfig();
        miniBiddingInfo = await this.props.blockChain.controller?.miniBidding?.getBiddingConfig();

        // if (loadedMiniBidsData) {
        //   if (preloadedMiniEmployees && Array.isArray(preloadedMiniEmployees.data)) {
        //     for (let i = 0; i < loadedMiniBidsData.data.bids.length; i++) {
        //       const addedNFT = preloadedMiniEmployees.data.findIndex(
        //         (loaded: any) => Number(loadedMiniBidsData.data.bids[i]._nftID) === Number(loaded._nftID)
        //       );

        //       console.log(addedNFT);

        //       loadedMiniBids.push({
        //         ...loadedMiniBidsData.data.bids[i],
        //         nftData: preloadedMiniEmployees.data[addedNFT] || null,
        //         bidId: loadedMiniBidsData.data.bids[i]._bidID,
        //       });
        //     }
        //   }
        // }

        if (loadedBidsData && loadedBidsData.bids && Array.isArray(loadedBidsData.bids)) {
          bids = loadedBidsData.bids;
        }
      } catch (error) {
        console.log(error);
      }

      this.setState({ lastBids: bids, loadedMiniBids, biddingInfo, miniBiddingInfo });
    }
  }

  async _onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);

    this.preloadControllers();
    this._loadNFT();
  }

  componentWillUnmount() {
    if (this.state.shuffleInterval) clearInterval(this.state.shuffleInterval);
  }

  private async _loadNFT() {
    if (Web3.utils.isAddress(this.state.selectedNFT)) {
      const preloadedNFT = await ApiHelpers.preloadExternalNFTs(this.state.selectedNFT, [Number(this.state.selectedID)]);

      if (preloadedNFT && preloadedNFT.data && preloadedNFT.data[0]) {
        const provider = await BlockChainHelpers.getProvider();

        if (
          provider &&
          this.props.blockChain.controller?._web3 &&
          this.props.blockChain.controller?.selectedAccount &&
          this.props.blockChain.controller.bidding?.address
        ) {
          const collectionInstance = new UniversalNFTGeneral(
            new provider.eth.Contract(IERC721 as any, this.state.selectedNFT),
            this.props.blockChain.controller?._web3,
            this.props.blockChain.controller?.selectedAccount
          );

          const nftAllowance = await collectionInstance.isApprovedForAll(
            this.props.blockChain.controller?.selectedAccount,
            this.props.blockChain.controller.bidding?.address
          );

          this.setState({
            preloadedNFT: preloadedNFT.data[0],
            nftAllowance: nftAllowance,
            nftInstance: collectionInstance,
          });
        }
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="ct-bidding">
          <div className="ct-minted-animations">
            <div className="ct-list">
              {this.state.backgroundImages.map((nft, index) => {
                return (
                  <div key={index} className="ct-nft">
                    <img src={nft} alt="" />
                  </div>
                );
              })}
            </div>
          </div>

          <div className="ct-minted-shadow"></div>

          <div className="ct-bidding-container ct-max-container">
            <div className="ct-mini-employees ct-external-nfts ">
              <h4>Mini employees bidding.</h4>
              <small>
                Bid for your mini employees using different tokens. Every day we will launch a mini employees update, 100% of the collected funds will
                be used to buy back FTB and agregate rewards to staking pools.
              </small>
              <div className="ct-mini-employees-bids ct-bids">
                {this.state.loadedMiniBids?.length > 0
                  ? this.state.loadedMiniBids.map((loaded, index) => {
                      return (
                        <MiniBiddingComponent
                          key={index + "-" + loaded.nft + "-" + loaded.nftID}
                          data={loaded}
                          blockChain={this.props.blockChain}
                          appState={this.props.appState}
                        />
                      );
                    })
                  : ""}
              </div>
            </div>
            <div className="ct-external-nfts">
              <h4>Bid for your favorite NFTs.</h4>
              <ul>
                <li>
                  <strong> active bids: </strong> {this.state.biddingInfo?.activeBids} / {this.state.biddingInfo?.maxActiveBids}
                </li>
                <li>
                  <strong> last bidder fee: </strong> {this.state.biddingInfo?.lastbidder} %
                </li>
                <li>
                  <strong> system fee (each bid): </strong> {Number(20) / 100} %
                </li>
                <li>
                  <strong> NFT owner fee (each bid): </strong> {Number(5) / 100} % + 100% from the owner payment
                </li>
              </ul>
              <div className="ct-bids">
                <div className="ct-create-bid">
                  <div className="ct-image">
                    {this.state.preloadedNFT ? (
                      <img
                        src={
                          this.state.preloadedNFT
                            ? this.state.preloadedNFT?._attributes?.image?.includes("ipfs")
                              ? "https://ipfs.io/ipfs/" + this.state.preloadedNFT?._attributes?.image.replace("ipfs://", "")
                              : this.state.preloadedNFT?._attributes?.image
                            : ""
                        }
                        alt=""
                      />
                    ) : (
                      <span className="fas fa-question"></span>
                    )}
                    <div className="ct-info">
                      <h4>
                        {this.state.preloadedNFT ? (
                          <small>{"name: " + this.state.preloadedNFT?._attributes?.name + " - #" + this.state.preloadedNFT?._attributes?.id}</small>
                        ) : (
                          ""
                        )}
                      </h4>
                    </div>
                  </div>
                  <div className="ct-data">
                    <div className="ct-input">
                      <label htmlFor="address">contract address</label>
                      <input
                        type="text"
                        value={this.state.selectedNFT}
                        onChange={(e) =>
                          this.setState({ selectedNFT: e.target.value }, () => {
                            if (this.state.selectedID && this.state.selectedNFT) {
                              this._loadNFT();
                            }
                          })
                        }
                        placeholder="contract address"
                      />
                    </div>
                    <div className="ct-input">
                      <label htmlFor="address">nft id</label>
                      <input
                        type="number"
                        value={this.state.selectedID}
                        onChange={(e) => {
                          this.setState({ selectedID: e.target.value }, () => {
                            if (this.state.selectedID && this.state.selectedNFT) {
                              this._loadNFT();
                            }
                          });
                        }}
                        placeholder="nft id"
                      />
                    </div>
                    <div className="ct-input">
                      <label htmlFor="address">initial price</label>
                      <input
                        type="number"
                        value={this.state.initialPrice}
                        onChange={(e) => this.setState({ initialPrice: e.target.value })}
                        placeholder="1000$"
                      />
                    </div>
                    <div className="ct-input">
                      <label htmlFor="address">token</label>
                      <Select
                        className="ct-token-selector"
                        isMulti={false}
                        placeholder="Select your token..."
                        onChange={(e) => this.setState({ selectedToken: e ? e.value : "" })}
                        options={[
                          {
                            value:
                              appConfig.mode === AppMode.DEV
                                ? this.props.appState.appData?.contractsAddress[Contract.TOKEN] || ""
                                : "0x228ebbee999c6a7ad74a6130e81b12f9fe237ba3",
                            label: (
                              <div className="ct-token-option">
                                <img src={MTRGLogo} alt="" />
                                MTRG
                              </div>
                            ),
                          },
                          {
                            value: "0x8df95e66cb0ef38f91d2776da3c921768982fba0",
                            label: (
                              <div className="ct-token-option">
                                <img src={VoltLogo} alt="" />
                                VOLT
                              </div>
                            ),
                          },
                          {
                            value: "0x24aa189dfaa76c671c279262f94434770f557c35",
                            label: (
                              <div className="ct-token-option">
                                <img src={BUSDLogo} alt="" />
                                BUSD
                              </div>
                            ),
                          },
                          {
                            value: "0x6cfe9adad5215195c1aa9755daed29360e6ab986",
                            label: (
                              <div className="ct-token-option">
                                <img src={FTBLogo} alt="" />
                                FTB
                              </div>
                            ),
                          },
                        ]}
                      />
                    </div>
                    {this.state.nftAllowance ? (
                      <button
                        className="ct-main-button"
                        onClick={() => {
                          if (this.props.blockChain.controller) {
                            if (
                              this.state.selectedNFT &&
                              this.state.selectedToken &&
                              !isNaN(Number(this.state.selectedID)) &&
                              !isNaN(Number(this.state.initialPrice))
                            ) {
                              this.props.blockChain.controller.bidding?.createRequest(
                                this.state.selectedNFT,
                                this.state.selectedToken,
                                Number(this.state.selectedID),
                                Web3.utils.toWei(this.state.initialPrice.toString()),
                                (error) =>
                                  setTimeout(() => {
                                    toast.success("Your request was successfully created.");
                                    this._onUpdateData(error);
                                  }, 4000)
                              );
                            } else toast.info("Fill all fields.");
                          }
                        }}
                      >
                        create
                      </button>
                    ) : (
                      <button
                        className="ct-main-button"
                        onClick={() => {
                          if (this.props.blockChain.controller && this.state.nftInstance && this.props.blockChain.controller.bidding?.address) {
                            this.state.nftInstance.setApprovalForAll(this.props.blockChain.controller.bidding?.address, true, (error) => {
                              this._onUpdateData(error);
                            });
                          }
                        }}
                      >
                        approve
                      </button>
                    )}
                  </div>
                </div>
                {this.state.lastBids.length > 0
                  ? this.state.lastBids.map((bid, index) => {
                      return (
                        <BiddingComponent
                          key={index + "-" + bid._nft + "-" + bid._nftID}
                          data={bid}
                          blockChain={this.props.blockChain}
                          appState={this.props.appState}
                        />
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
