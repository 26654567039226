import { createStore, combineReducers, applyMiddleware } from "redux";
import { loadContractAbi } from "./middlewares/loadContract";
import { applicationKey, applicationReducer } from "./state/app/state";
import {
  blockChainReducer,
  blockChainReducerKey,
} from "./state/blockChain/state";
import { contractsKey, contractsReducer } from "./state/contracts/state";

export const Store = createStore(
  combineReducers({
    [contractsKey]: contractsReducer,
    [blockChainReducerKey]: blockChainReducer,
    [applicationKey]: applicationReducer,
  }),
  applyMiddleware(loadContractAbi)
);

export default Store;
