import { ApplicationState } from "./state/app/state";
import { BlockChainState } from "./state/blockChain/state";
import { ContractsState } from "./state/contracts/state";

export enum AppReducer {
  APP = "app",
  CONTRACTS = "contracts",
  BLOCK_CHAIN = "blockChain",
  RELATIONS_GFAME = "relations-game",
  STUDENTS_GAME = "students-game",
}

export enum AppActions {
  //Contracts
  SET_CONTRACT = "set-contract",
  SET_CONTRACT_ABI = "set-contract-abi",
  SET_MULTIPLE_CONTRACT_ABI = "set-multiple-contract-abi",
  LOAD_CONTRACT_ABI = "load-contract-abi",
  LOAD_MULTIPLE_CONTRACT_ABI = "load-multiple-contract-abi",
  //BlockChain
  SAVE_BLOCK_CHAIN_CONTROLLER = "save-block-chain-controller",
  DESTROY_BLOCK_CHAIN_CONTROLLER = "destroy-block-chain-controller",
  SET_BLOCK_CHAIN_ERROR = "set-block-chain-error",
  //Customer
  SET_CUSTOMER = "set-customer",
  CLEAR_CUSTOMER = "clear-customer",
  //App
  SET_ERROR = "set-error",
  TEST_STATE = "test-state",
  TOGGLE_LEFT_NAVIGATION = "toggle-left-navigation",
  TOGGLE_LOADER = "toggle-loader",
  CHANGE_EMPLOYEES_PAGINATION = "change-employees-pagination",
  SAVE_APP_DATA = "save-app-data",
  //Game
  GAME_CHANGE_SELECTED_FACTORY = "game-change-selected-factory",
  GAME_RESET_EMPLOYEE_SELECTION = "game-reset-employee-selection",
  GAME_ADD_EMPLOYEE_TO_SELECTION = "game-add-employee-to-selection",
  GAME_REMOVE_EMPLOYEE_FROM_SELECTION = "game-remove-employee-from-selection",
  //Students
  STUDENTS_CHANGE_SELECTED_EMPLOYEE = "students-change-selected-employee",
  STUDENTS_REMOVE_SELECTED_EMPLOYEE = "students-remove-selected-employee",
  STUDENTS_CLEAR_SELECTED_EMPLOYEE = "students-clear-selected-employee",
}

export interface AppState {
  [AppReducer.CONTRACTS]: ContractsState;
  [AppReducer.BLOCK_CHAIN]: BlockChainState;
  [AppReducer.APP]: ApplicationState;
}
