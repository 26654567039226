import Web3 from "web3";
import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";
import { AbstractUniversalNFTV2 } from "../abstracts/universalNFTV2";

export class UniversalNFTV2 extends ContractBaseModel<AbstractUniversalNFTV2> {
  name() {
    return this.contract.name().call(this.config);
  }

  paused() {
    return this.contract.paused().call(this.config);
  }

  getGenerationData() {
    return this.contract.getGenerationData().call(this.config);
  }

  getData() {
    return this.contract.getData(this.selectedAccount).call(this.config);
  }

  uri() {
    return this.contract.uri().call(this.config);
  }

  exampleUri(token: number) {
    return this.contract.exampleUri(token).call(this.config);
  }

  getMintingData() {
    return this.contract.getMintingData().call(this.config);
  }

  timeToBePublic() {
    return this.contract.timeToBePublic().call(this.config);
  }

  isPublic() {
    return this.contract.isPublic().call(this.config);
  }

  symbol() {
    return this.contract.symbol().call(this.config);
  }

  maxSupply() {
    return this.contract.maxSupply().call(this.config);
  }

  totalSupply() {
    return this.contract.totalSupply().call(this.config);
  }

  mintingFee() {
    return this.contract.mintingFee().call(this.config);
  }

  recolectorFee() {
    return this.contract.recolectorFee().call(this.config);
  }

  getMintingPrice() {
    return this.contract.getMintingPrice().call(this.config);
  }

  tokenURI(id: number) {
    return this.contract.tokenURI(id).call(this.config);
  }

  getCustomerTokens() {
    return this.contract.tokens(this.selectedAccount).call(this.config);
  }

  withdraw(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdraw()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  addToWhitelist(address: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .addToWhitelist(address)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  removeFromWhitelist(address: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .removeFromWhitelist(address)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  launch(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .launch()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  mint(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .mint()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  manyMint(amount: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .manyMint(amount)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  payedMint(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .payedMint()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  payedManyMint(amount: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .payedManyMint(amount)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  burn(id: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .burn(id)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  changeURI(uri: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .changeURI(uri)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  changeMaxSupply(supply: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .changeMaxSupply(supply)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  changeMintingFee(price: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .changeMintingFee(Web3.utils.toWei((price || "0").toString(), "ether"))
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  changePrice(price: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .changePrice(Web3.utils.toWei((price || "0").toString(), "ether"))
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  pause(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .pause()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  unpause(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .unpause()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }
}
