import { toast } from "react-toastify";
import { BlockChainState } from "../storage/state/blockChain/state";
import { AppErrorCode, Contract } from "./app";
import { BlockChain } from "./chain";
import { BlockChainHelpers } from "./helpers/chain";
import { UtilsHelpers } from "./helpers/utils";
import { AppData } from "./types";

export class Customer {
  constructor(public _address: string | null, public appDataModel: AppData) {}

  get tokensData() {
    return this.appDataModel.customerToken;
  }

  //BlockChain

  static async loadCustomerData(blockChain: BlockChain): Promise<{
    customer: null | Customer;
    error: AppErrorCode | null;
    appData: AppData | null;
  }> {
    UtilsHelpers.debugger("Load customer Data.");

    try {
      if (
        blockChain.token &&
        blockChain.universalFactory &&
        blockChain.universalGeneralNFT &&
        blockChain.universalStaking &&
        blockChain.universalMinter &&
        blockChain.battleMiniGames &&
        blockChain.bidding &&
        blockChain.lpStaking &&
        blockChain.universalQueries &&
        blockChain.selectedAccount
      ) {
        const mainTokenBalance = UtilsHelpers.normalizeWei((await BlockChainHelpers.getMainTokenBalance(blockChain.selectedAccount)) || "0");
        const loadTokenBalance = UtilsHelpers.normalizeWei(await blockChain.token.balanceOf(blockChain.selectedAccount));
        const loadTokenName = "Meter Governance";
        const loadTokenSymbol = "MTRG";

        const appDataModel: AppData = AppData.buildFromTemplates(
          {
            mainTokenBalance,
            loadTokenBalance,
            loadTokenSymbol,
            loadTokenName,
          },
          {
            [Contract.TOKEN]: blockChain.token.address,
            [Contract.UNIVERSAL_FACTORY]: blockChain.universalFactory.address,
            [Contract.UNIVERSAL_GENERAL]: blockChain.universalGeneralNFT.address,
            [Contract.UNIVERSAL_STAKING_FACTORY]: blockChain.universalStaking.address,
            [Contract.UNIVERSAL_MINTER]: blockChain.universalMinter.address,
          }
        );

        return {
          customer: new Customer(blockChain.selectedAccount, appDataModel),
          error: null,
          appData: appDataModel,
        };
      } else {
        toast.error("[ERROR] " + AppErrorCode.INVALID_CONTRACT_LOADING);

        return {
          error: AppErrorCode.INVALID_CONTRACT_LOADING,
          customer: null,
          appData: null,
        };
      }
    } catch (error) {
      toast.error("[ERROR] " + AppErrorCode.INVALID_CONTRACT_LOADING);

      return {
        error: AppErrorCode.INVALID_CONTRACT_LOADING,
        customer: null,
        appData: null,
      };
    }
  }

  //Storage

  static setCustomer(state: BlockChainState, customer: Customer) {
    return { ...state, customer };
  }

  static clearCustomer(state: BlockChainState) {
    return { ...state, customer: null };
  }
}
