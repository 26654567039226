import { ContractBaseModel } from "../abstracts/contract";
import { AbstractLPStaking } from "../abstracts/LPSStaking";
import { AbstractVoltDistributor } from "../abstracts/VoltDistributor";
import { AppErrorCode } from "../app";

export class VoltDistributor extends ContractBaseModel<AbstractVoltDistributor> {
  getContractInfo() {
    return this.contract.getContractInfo(this.selectedAccount).call(this.config);
  }

  getAvailableProfit() {
    return this.contract.getAvailableProfit(this.selectedAccount).call(this.config);
  }

  getCyclePercentage() {
    return this.contract.getCyclePercentage(this.selectedAccount).call(this.config);
  }

  withdrawProfit(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdrawProfit()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.GENERATE_RANDOM_STATS, receipt, callback);
      })
      .on("error", (error: any) => {
        console.log(error);
        this.errorCallback(AppErrorCode.GENERATE_RANDOM_STATS, error, callback);
      });
  }
}
