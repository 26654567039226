import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";
import { AbstractUniversalMinter } from "../abstracts/universalMinter";

export class UniversalMinter extends ContractBaseModel<AbstractUniversalMinter> {
  getInfo() {
    return this.contract.getInfo().call(this.config);
  }

  hasKey() {
    return this.contract.hasKey(this.selectedAccount).call(this.config);
  }

  mintKey(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .mintKey()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  mintGeneralNFT(soft: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .mintGeneralNFT(soft)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }
}
