import React from "react";
import { connect } from "react-redux";

import { BrowserRouter, Route, Switch } from "react-router-dom";

import { ContractsStateController } from "../core/contracts";
import { loadMultipleContractAbis } from "../storage/state/contracts/actions";
import { AppReducer, AppState } from "../storage/types";
import { BlockChain } from "../core/chain";

import { destroyBlockChainController, saveBlockChainController, setBlockChainError, setCustomer } from "../storage/state/blockChain/actions";

import { changeEmployeesPagination, saveAppData, toggleLeftNavigation, toggleLoader } from "../storage/state/app/actions";

import { BlockChainState } from "../storage/state/blockChain/state";
import { ContractsState } from "../storage/state/contracts/state";
import { appConfig, AppErrorCode, Contract } from "../core/app";
import { ApplicationState } from "../storage/state/app/state";
import { LeftNavigation } from "./organisms/navigation/left";
import { TopNavigation } from "./organisms/navigation/top";
import { BlockChainHelpers } from "../core/helpers/chain";
import { Footer } from "./organisms/navigation/footer";
import { toast, ToastContainer } from "react-toastify";
import { UtilsHelpers } from "../core/helpers/utils";
import { ErrorComponent } from "./molecules/error";
import { Loader } from "./organisms/loader";
import { Customer } from "../core/customer";
import { AppData } from "../core/types";
import { NavWindow } from "./types";
import { UniversalMinterPage } from "./pages/universalMinter";
import { CollectionGeneratorPage } from "./pages/collectionGenerator";
import { CollectionPage } from "./pages/collection";
import { CollectionAdminPage } from "./pages/admin";
import { MyCollectionsPage } from "./pages/myCollections";
import { AllCollectionsPage } from "./pages/collections";
import { AllNFTsPage } from "./pages/allNFTs";
import { StakingPoolsPage } from "./pages/staking";
import { DrawnCitiesPage } from "./pages/custom/drawnCities";
import { BattleVSPage } from "./pages/battles";
import { DistributionPage } from "./pages/distribution";
import { BiddingPage } from "./pages/bidding";
import { CiervoLabPage } from "./pages/custom/ciervoLab";
import { MangaPunksPage } from "./pages/custom/mangaPunks";
import { TokenResearchPage } from "./pages/tokenResearch";
import { BackgroundAnimation } from "./organisms/backgroundAnimation";

interface AppComponentProps {
  loadMultipleContractAbis: (contracts: Contract[]) => void;
  saveBlockChainController: (controller: BlockChain) => void;
  destroyBlockChainController: () => void;
  setBlockChainError: (error: AppErrorCode | null) => void;
  setCustomer: (customer: Customer) => void;
  toggleLeftNavigation: (force?: boolean) => void;
  toggleLoader: (force?: boolean) => void;
  saveAppData: (data: AppData) => void;
  appState: ApplicationState;
  contracts: ContractsState;
  blockChain: BlockChainState;
}

interface AppComponentState {
  contractLoading: boolean;
}

const mapStateToProps = (state: AppState) => {
  return {
    appState: state[AppReducer.APP],
    contracts: state[AppReducer.CONTRACTS],
    blockChain: state[AppReducer.BLOCK_CHAIN] as BlockChainState,
  };
};

const mapDispatchToProps = {
  destroyBlockChainController,
  changeEmployeesPagination,
  loadMultipleContractAbis,
  saveBlockChainController,
  toggleLeftNavigation,
  setBlockChainError,
  toggleLoader,
  setCustomer,
  saveAppData,
};

export class App extends React.Component<AppComponentProps, AppComponentState> {
  constructor(props: AppComponentProps) {
    super(props);

    this.state = {
      contractLoading: false,
    };
  }

  async componentDidMount() {
    toast.success("Welcome to BusinessBuilders.");
    this._web3InitialValidations();
  }

  private async _web3InitialValidations() {
    const wasConnected = await BlockChainHelpers.validateConnection();
    if (wasConnected) this._validateAndLoadChainNetwork();
  }

  private async _validateAndLoadChainNetwork() {
    const connected = await BlockChainHelpers.loadWeb3();

    if (connected) {
      if (appConfig.open) {
        UtilsHelpers.debugger("Link start.");
        const windowParse = window as NavWindow;

        if (windowParse.ethereum) {
          windowParse.ethereum.on("chainChanged", async () => {
            this.props.toggleLoader(true);
            sessionStorage.clear();
            this._loadContracts();
          });

          windowParse.ethereum.on("accountsChanged", () => {
            this.props.toggleLoader(true);
            sessionStorage.clear();
            this._loadContracts();
          });

          sessionStorage.clear();
          await this._loadContracts();
        } else {
          this.props.setBlockChainError(AppErrorCode.INVALID_PROVIDER);
          UtilsHelpers.debugger("[APP] Error, Invalid provider.");
        }
      }
    }
  }

  private async _loadContracts() {
    await BlockChainHelpers.validateBlockChain((correct: boolean) => {
      console.log(correct);
      if (correct) {
        if (!ContractsStateController.isLoaded(this.props.contracts)) {
          this.props.loadMultipleContractAbis([
            Contract.TOKEN,
            Contract.UNIVERSAL_FACTORY,
            Contract.UNIVERSAL_GENERAL,
            Contract.UNIVERSAL_STAKING_FACTORY,
            Contract.UNIVERSAL_MINTER,
            Contract.UNIVERSAL_QUERIES,
            Contract.UNIVERSAL_BIDDING,
            Contract.BATTLE_MINI_GAMES,
            Contract.UNIVERSAL_MINI_BIDDING,
            Contract.DISTRIBUTOR,
            Contract.LP_STAKING,
            Contract.VOLT_REWARDS,

            Contract.DAILY_COIN,
            Contract.DAILY_COIN_BIDDING,
            Contract.MTRG,
          ]);

          console.log("[GOD] Loading multiple contracts");
        }
      } else {
        this.props.setBlockChainError(AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK);

        this.props.destroyBlockChainController();
        this.props.toggleLoader(false);
      }
    });
  }

  componentDidUpdate() {
    if (!this.state.contractLoading && ContractsStateController.isLoaded(this.props.contracts)) {
      this.setState({ contractLoading: true }, () => {
        if (appConfig.open) this.loadBlockChain();
      });
    }
  }

  async changeToAppNetwork() {
    await BlockChainHelpers.reloadOrChangeNetwork();
  }

  loadBlockChain() {
    try {
      let blockChain = new BlockChain();

      blockChain.principalListener.on(AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK, () => {
        this.props.setBlockChainError(AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK);
      });

      blockChain.loadBlockChainData(this.props.contracts, async (err: AppErrorCode | null) => {
        if (err) this.props.setBlockChainError(err);
        this.props.saveBlockChainController(blockChain);
        this.loadCustomerData(true);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async loadCustomerData(inTheEnd: boolean = false) {
    try {
      if (this.props.blockChain.controller) {
        let { customer, appData, error } = await Customer.loadCustomerData(this.props.blockChain.controller);

        if (error) this.props.setBlockChainError(error);
        else {
          if (customer) this.props.setCustomer(customer);
          if (appData) this.props.saveAppData(appData);
        }

        if (inTheEnd) this.props.toggleLoader(false);
      }
    } catch (error) {
      toast.error("Invalid user loading, please report the error in the telegram group.");
    }
  }

  componentWillUnmount() {
    this.props.destroyBlockChainController();
  }

  render() {
    return (
      <BrowserRouter>
        <div className="ct-app-container">
          <Loader open={this.props.appState.loader} />
          <TopNavigation
            onLoadCustomerData={() => {
              this.props.toggleLoader(true);
              this.loadCustomerData(true);
            }}
            onToggleLeftNav={() => this.props.toggleLeftNavigation()}
            onLoadBlockChain={() => this._validateAndLoadChainNetwork()}
            customer={this.props.blockChain.customer}
            onToggleLoader={(force: boolean) => this.props.toggleLoader(force)}
          ></TopNavigation>
          <LeftNavigation onToggleLeftNavigation={() => this.props.toggleLeftNavigation()} open={this.props.appState.leftNavigation} />

          <BackgroundAnimation />
          {appConfig.open ? (
            <Switch>
              <Route exact path="/">
                <UniversalMinterPage
                  appState={this.props.appState}
                  blockChain={this.props.blockChain}
                  onLoadBlockChain={() => this._validateAndLoadChainNetwork()}
                  onLoadCustomerData={(loader) => this.loadCustomerData(loader)}
                  onToggleLoader={(force) => this.props.toggleLoader(force)}
                  onSetBlockChainError={(error) => this.props.setBlockChainError(error)}
                ></UniversalMinterPage>
              </Route>
              <Route path="/collection" exact>
                <CollectionPage
                  appState={this.props.appState}
                  blockChain={this.props.blockChain}
                  onLoadCustomerData={(loader) => this.loadCustomerData(loader)}
                  onLoadBlockChain={() => this._validateAndLoadChainNetwork()}
                  onToggleLoader={(force) => this.props.toggleLoader(force)}
                  onSetBlockChainError={(error) => this.props.setBlockChainError(error)}
                ></CollectionPage>
              </Route>
              <Route path="/collection-admin" exact>
                <CollectionAdminPage
                  onLoadBlockChain={() => this._validateAndLoadChainNetwork()}
                  appState={this.props.appState}
                  blockChain={this.props.blockChain}
                  onLoadCustomerData={(loader) => this.loadCustomerData(loader)}
                  onToggleLoader={(force) => this.props.toggleLoader(force)}
                  onSetBlockChainError={(error) => this.props.setBlockChainError(error)}
                ></CollectionAdminPage>
              </Route>
              <Route path="/nft-bidding" exact>
                <BiddingPage
                  appState={this.props.appState}
                  blockChain={this.props.blockChain}
                  onLoadCustomerData={(loader) => this.loadCustomerData(loader)}
                  onLoadBlockChain={() => this._validateAndLoadChainNetwork()}
                  onToggleLoader={(force) => this.props.toggleLoader(force)}
                  onSetBlockChainError={(error) => this.props.setBlockChainError(error)}
                ></BiddingPage>
              </Route>
              <Route path="/collections" exact>
                <AllCollectionsPage
                  appState={this.props.appState}
                  blockChain={this.props.blockChain}
                  onLoadBlockChain={() => this._validateAndLoadChainNetwork()}
                  onLoadCustomerData={(loader) => this.loadCustomerData(loader)}
                  onToggleLoader={(force) => this.props.toggleLoader(force)}
                  onSetBlockChainError={(error) => this.props.setBlockChainError(error)}
                ></AllCollectionsPage>
              </Route>
              {/* <Route path="/all-nfts" exact>
                {this.props.appState && this.props.blockChain && this.props.blockChain.customer ? (
                  <AllNFTsPage
                    appState={this.props.appState}
                    blockChain={this.props.blockChain}
                    onLoadCustomerData={(loader) => this.loadCustomerData(loader)}
                    onToggleLoader={(force) => this.props.toggleLoader(force)}
                    onSetBlockChainError={(error) => this.props.setBlockChainError(error)}
                  ></AllNFTsPage>
                ) : (
                  ""
                )}
              </Route> */}

              <Route path="/drawn-cities" exact>
                <DrawnCitiesPage
                  appState={this.props.appState}
                  blockChain={this.props.blockChain}
                  onLoadCustomerData={(loader) => this.loadCustomerData(loader)}
                  onToggleLoader={(force) => this.props.toggleLoader(force)}
                  onSetBlockChainError={(error) => this.props.setBlockChainError(error)}
                ></DrawnCitiesPage>
              </Route>
              <Route path="/ciervo-lab" exact>
                <CiervoLabPage
                  appState={this.props.appState}
                  blockChain={this.props.blockChain}
                  onLoadCustomerData={(loader) => this.loadCustomerData(loader)}
                  onToggleLoader={(force) => this.props.toggleLoader(force)}
                  onSetBlockChainError={(error) => this.props.setBlockChainError(error)}
                ></CiervoLabPage>
              </Route>
              <Route path="/manga-punks" exact>
                <MangaPunksPage
                  appState={this.props.appState}
                  blockChain={this.props.blockChain}
                  onLoadCustomerData={(loader) => this.loadCustomerData(loader)}
                  onToggleLoader={(force) => this.props.toggleLoader(force)}
                  onSetBlockChainError={(error) => this.props.setBlockChainError(error)}
                ></MangaPunksPage>
              </Route>

              <Route path="/nft-battles" exact>
                {this.props.appState && this.props.blockChain && this.props.blockChain.customer ? (
                  <BattleVSPage
                    appState={this.props.appState}
                    blockChain={this.props.blockChain}
                    onLoadCustomerData={(loader) => this.loadCustomerData(loader)}
                    onToggleLoader={(force) => this.props.toggleLoader(force)}
                    onSetBlockChainError={(error) => this.props.setBlockChainError(error)}
                  ></BattleVSPage>
                ) : (
                  ""
                )}
              </Route>
              <Route path="/staking" exact>
                <StakingPoolsPage
                  appState={this.props.appState}
                  blockChain={this.props.blockChain}
                  onLoadCustomerData={(loader) => this.loadCustomerData(loader)}
                  onToggleLoader={(force) => this.props.toggleLoader(force)}
                  onSetBlockChainError={(error) => this.props.setBlockChainError(error)}
                ></StakingPoolsPage>
              </Route>

              <Route path="/token-research" exact>
                <TokenResearchPage
                  appState={this.props.appState}
                  blockChain={this.props.blockChain}
                  onLoadCustomerData={(loader) => this.loadCustomerData(loader)}
                  onToggleLoader={(force) => this.props.toggleLoader(force)}
                  onSetBlockChainError={(error) => this.props.setBlockChainError(error)}
                  onLoadBlockChain={() => {
                    this.loadCustomerData();
                  }}
                ></TokenResearchPage>
              </Route>

              <Route exact path="/nft-collections">
                <CollectionGeneratorPage
                  appState={this.props.appState}
                  onLoadBlockChain={() => this._validateAndLoadChainNetwork()}
                  blockChain={this.props.blockChain}
                  onLoadCustomerData={(loader) => this.loadCustomerData(loader)}
                  onToggleLoader={(force) => this.props.toggleLoader(force)}
                  onSetBlockChainError={(error) => this.props.setBlockChainError(error)}
                ></CollectionGeneratorPage>
              </Route>
              <Route exact path="/distribution">
                <DistributionPage
                  appState={this.props.appState}
                  onLoadBlockChain={() => this._validateAndLoadChainNetwork()}
                  blockChain={this.props.blockChain}
                  onLoadCustomerData={(loader) => this.loadCustomerData(loader)}
                  onToggleLoader={(force) => this.props.toggleLoader(force)}
                  onSetBlockChainError={(error) => this.props.setBlockChainError(error)}
                ></DistributionPage>
              </Route>
            </Switch>
          ) : (
            ""
          )}

          <ToastContainer theme="light" limit={3} />
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
