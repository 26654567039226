import { AppErrorCode } from "../app";
import { Token } from "../contracts/token";
import { UtilsHelpers } from "../helpers/utils";

export class TokenController {
  constructor(public contract: Token) {}

  async update() {}

  public approveTokenSpend(
    address: string,
    amount: number,
    callback?: (error: AppErrorCode | null, hash?: string) => void
  ) {
    UtilsHelpers.debugger("Approve employee minting");

    UtilsHelpers.debugger(
      "Try to to approve token spend (" + this.contract.selectedAccount + ")"
    );

    if (callback) this.contract.approve(address, amount, callback);
  }

  public async getAllowance(contract: string) {
    return await this.contract.allowance(
      this.contract.selectedAccount,
      contract
    );
  }
}
