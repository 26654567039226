import React from "react";
import { Link } from "react-router-dom";
import { navigationLinks } from "../../../core/navigation";

import "./left.styles.css";

interface LeftNavigationProps {
  open: boolean;
  onToggleLeftNavigation: () => void;
}

interface LeftNavigationState {
  activeDisplays: { [key: string]: boolean };
}

export class LeftNavigation extends React.Component<
  LeftNavigationProps,
  LeftNavigationState
> {
  constructor(props: LeftNavigationProps) {
    super(props);

    const activeDisplays: any = {};

    for (let i = 0; i < navigationLinks.length; i++) {
      activeDisplays["ct-display-" + i] = false;
    }

    this.state = {
      activeDisplays,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className={"ct-left-nav" + (this.props.open ? " ct-open" : "")}>
        <div className="ct-left-nav-container">
          <div className="ct-links">
            {navigationLinks.map((parent, index) => {
              return (
                <div key={index} className="ct-link-parent">
                  <div
                    className="ct-display"
                    onClick={() => {
                      this.setState({
                        activeDisplays: {
                          ...this.state.activeDisplays,
                          ["ct-display-" + index]:
                            !this.state.activeDisplays["ct-display-" + index],
                        },
                      });
                    }}
                  >
                    <span className="ct-title">{parent.display}</span>
                    <span className="ct-openner fas fa-chevron-down"></span>
                    <p>
                      <small>{parent.description}</small>
                    </p>
                  </div>
                  <div
                    className={
                      "ct-parent-links" +
                      (this.state.activeDisplays["ct-display-" + index]
                        ? " ct-active"
                        : "")
                    }
                  >
                    {parent.links?.map((link, index) => {
                      if (link.type === "internal") {
                        if (
                          link.disabled ||
                          window.location.pathname === link.href
                        ) {
                          return (
                            <span
                              key={index}
                              className={
                                link.disabled ||
                                window.location.pathname === link.href
                                  ? "ct-disabled"
                                  : ""
                              }
                            >
                              <div className="ct-title">
                                <span>{link.display} </span>
                                {link.message ? (
                                  <small>{link.message}</small>
                                ) : (
                                  ""
                                )}
                              </div>
                              <span className="fas fa-check"></span>
                            </span>
                          );
                        } else {
                          return (
                            <Link
                              key={index}
                              onClick={() =>
                                this.props.onToggleLeftNavigation()
                              }
                              to={link.href}
                              className={link.disabled ? "ct-disabled" : ""}
                            >
                              <div className="ct-title">
                                <span>{link.display} </span>
                                {link.message ? (
                                  <small>{link.message}</small>
                                ) : (
                                  ""
                                )}
                              </div>
                              <span className={link.icon}></span>
                            </Link>
                          );
                        }
                      } else {
                        return (
                          <a
                            key={index}
                            href={link.href}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={link.disabled ? "ct-disabled" : ""}
                          >
                            <span>{link.display}</span>
                            <span className={link.icon}></span>
                          </a>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
