import React from "react";
import Web3 from "web3";

import { BlockChainState } from "../../../storage/state/blockChain/state";
import { ApplicationState } from "../../../storage/state/app/state";
import { AppErrorCode } from "../../../core/app";
import { ApiHelpers } from "../../../core/helpers/api";
import { BlockChainHelpers } from "../../../core/helpers/chain";
import { UniversalNFT } from "../../../core/contracts/universalNFT";

import UniversalNFTAbi from "../../../assets/contracts/UniversalNFT.json";

import { UniverseNFTController } from "../../../core/modules/universeNFT";
import { UniversalNFTV1 } from "../../../core/contracts/universalNFTV1";

import MainBannerImage from "../../../assets/images/collections/ciervoLab/ciervo-lab-banner.jpg";
import CiervoLabLogo from "../../../assets/images/collections/ciervoLab/LOGO-TP.png";
import AnimatedImage from "../../../assets/images/collections/ciervoLab/animation.gif";
import BBLogo from "../../../assets/images/BB_LOGO.svg";
import MeterLogo from "../../../assets/images/meter-network.png";
import { UniversalNFTV3 } from "../../../core/contracts/universalNFTV3";

interface CollectionProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

const linksBase = {
  instagram: "",
  twitter: "",
  linkedin: "",
  telegram: "",
  discord: "",
};

interface CollectionState {
  collectionInstance: UniversalNFTV3 | null;
  collection: string;
  isPublic: boolean;
  launched: boolean;
  hasCollectionKey: boolean;
  protection: boolean;
  refund: boolean;
  timeToBePublic: number;
  payment: string;
  collectionName: string;
  collectionSymbol: string;
  price: string;
  image: string;
  owner: string;
  gallery: string[];
  links: { [social: string]: string };
  totalMinted: Number;
  stakingPoolPercentage: Number;
  stakingPool: string | null;
  maxSupply: Number;
  allowance: Number;
  totalNFTs: string;
  contractBalance: Number;
  aboutOwner: string;
  aboutProject: string;
  myNFTs: any[];
  universalNFT: null | UniverseNFTController;
}

export class CiervoLabPage extends React.PureComponent<CollectionProps, CollectionState> {
  constructor(props: CollectionProps) {
    super(props);

    this.state = {
      collectionInstance: null,
      isPublic: false,
      launched: false,
      hasCollectionKey: false,
      refund: false,
      protection: false,
      timeToBePublic: 0,
      collection: "",
      collectionName: "",
      collectionSymbol: "",
      totalMinted: 0,
      maxSupply: 0,
      payment: "",
      allowance: 0,
      contractBalance: 0,
      totalNFTs: "",
      stakingPoolPercentage: 0,
      stakingPool: "",
      links: linksBase,
      gallery: [],
      price: "",
      image: "",
      owner: "",
      aboutOwner: "",
      aboutProject: "",
      myNFTs: [],
      universalNFT: null,
    };
  }

  async componentDidMount() {
    this.props.onToggleLoader(true);
    this.loadAndSetUniversalNFT();
    await this.preloadControllers(true);
  }

  async preloadControllers(closeLoader: boolean = false) {
    const provider = await BlockChainHelpers.getProvider();

    const contractAddress = "0xe80aa98cb0687d0d91b15015fac09ddd5e2efdc2";

    let collectionInstance = null;
    let isPublic = false;
    let launched = false;
    let refund = false;
    let timeToBePublic = 0;
    let collectionName = "";
    let collectionSymbol = "";
    let maxSupply = 0;
    let totalMinted = 0;
    let payment = "";
    let allowance = 0;
    let price = "";
    let image = "";
    let owner = "";
    let aboutOwner = "";
    let aboutProject = "";
    let stakingPool = "";
    let stakingPoolPercentage = 0;
    let links = linksBase;
    let gallery: any[] = [];
    let protection = false;

    if (provider && contractAddress && this.props.blockChain.controller?._web3 && this.props.blockChain.controller?.selectedAccount) {
      const collectionData = await ApiHelpers.collection(contractAddress);

      protection = collectionData._protected;

      collectionInstance = new UniversalNFTV3(
        new provider.eth.Contract(UniversalNFTAbi.abi as any, contractAddress),
        this.props.blockChain.controller?._web3,
        this.props.blockChain.controller?.selectedAccount
      );

      const mintingData = await collectionInstance.getMintingData();
      const generationData = await collectionInstance.getGenerationData();

      allowance = Number(
        Web3.utils.fromWei(
          await this.props.blockChain.controller.token?.allowance(this.props.blockChain.controller.selectedAccount, contractAddress),
          "ether"
        )
      );

      if (collectionData && generationData && mintingData) {
        const launchTime = Number(generationData.lt);
        const privateTime = Number(generationData.pt);
        const publicDate = new Date((launchTime + privateTime) * 1000);

        timeToBePublic = (publicDate.getTime() - Date.now()) / 1000;

        image = collectionData._image;
        owner = collectionData._owner;
        links = collectionData._projectLinks;
        aboutOwner = collectionData._aboutOwner;
        aboutProject = collectionData._aboutProject;
        maxSupply = generationData.mx;
        gallery = collectionData._gallery;
        launched = generationData.wl;
        collectionName = generationData.na;
        collectionSymbol = generationData.sy;
        price = mintingData.mp;
        totalMinted = mintingData.ts;
        isPublic = mintingData.ip;
        payment = mintingData.py;
        refund = generationData.r;

        if (generationData.cs) {
          stakingPool = generationData.cs;
          stakingPoolPercentage = generationData.csP;
        }
      }
    }

    this.setState(
      {
        collectionInstance,
        collectionName,
        protection,
        collectionSymbol,
        aboutOwner,
        launched,
        payment,
        refund,
        owner,
        stakingPool,
        stakingPoolPercentage,
        maxSupply,
        aboutProject,
        gallery,
        timeToBePublic,
        price,
        allowance,
        isPublic,
        totalMinted,
        image,
        links,
        collection: contractAddress || "",
      },
      async () => {
        if (closeLoader) this.props.onToggleLoader(false);

        if (this.state.collectionInstance) {
          const tokens = await this.state.collectionInstance.tokens();
          const preloadedNFTs = await ApiHelpers.preloadExternalNFTs(this.state.collection, tokens);

          if ((preloadedNFTs || preloadedNFTs.data) && (Array.isArray(preloadedNFTs) || Array.isArray(preloadedNFTs.data))) {
            this.setState({
              myNFTs: Array.isArray(preloadedNFTs) ? preloadedNFTs : Array.isArray(preloadedNFTs.data) ? preloadedNFTs.data : [],
            });
          }
        }
      }
    );
  }

  async loadAndSetUniversalNFT() {
    let universalNFT = null;

    if (this.props.blockChain.controller?.universalFactory) {
      universalNFT = new UniverseNFTController(this.props.blockChain.controller.universalFactory);
      await universalNFT.initialize();
    }

    this.setState({
      universalNFT,
      hasCollectionKey: universalNFT?.data?.hasKey || false,
    });
  }

  private _onUpdateData(error: AppErrorCode | null) {
    if (error) this.props.onSetBlockChainError(error);
    this.loadAndSetUniversalNFT();
    this.preloadControllers();
  }

  render() {
    const mintingPrice = Number(Web3.utils.fromWei(this.state.price || "0", "ether"));
    const minutes = Number(this.state.timeToBePublic) / 60;
    const hours = Number(this.state.timeToBePublic) / 60 / 60;
    const progress = (Number(this.state.totalMinted) * 100) / Number(this.state.maxSupply);

    return (
      <React.Fragment>
        <div className="ct-ciervo-lab">
          <div className="ct-call-to-action ">
            <div className="ct-animated-square animate__animated animate__backInLeft animate__delay-2s"></div>
            <div className="ct-animated-square animate__animated animate__backInLeft animate__delay-2s"></div>
            <div className="ct-animated-square animate__animated animate__backInLeft animate__delay-2s"></div>
            <div className="ct-animated-square animate__animated animate__backInLeft animate__delay-2s"></div>
            <div className="ct-animated-square animate__animated animate__backInLeft animate__delay-2s"></div>
            <div className="ct-animated-square animate__animated animate__backInLeft animate__delay-2s"></div>
            <div className="ct-animated-square animate__animated animate__backInLeft animate__delay-2s"></div>
            <div className="ct-animated-square animate__animated animate__backInLeft animate__delay-2s"></div>
            <div className="ct-animated-square animate__animated animate__backInLeft animate__delay-2s"></div>

            <div className="ct-minting  animate__animated animate__backInLeft animate__delay-2s">
              <div className="ct-minting-container">
                <h4>Mint your own ciervo</h4>
                <input
                  type="number"
                  placeholder="Total NFTs"
                  value={this.state.totalNFTs}
                  onChange={(e) => this.setState({ totalNFTs: e.target.value })}
                />
                <span>
                  <strong>Price: {(mintingPrice * Number(this.state.totalNFTs)).toFixed(1)} $MTRG</strong>{" "}
                </span>
                {this.state.allowance >= mintingPrice * Number(this.state.totalNFTs) ? (
                  <button
                    onClick={() => {
                      if (this.state.collectionInstance) {
                        this.state.collectionInstance.manyMint(Number(this.state.totalNFTs), (error) => {
                          this._onUpdateData(error);
                        });
                      }
                    }}
                  >
                    Mint
                  </button>
                ) : (
                  <button
                    className="ct-mt-5"
                    onClick={async () => {
                      if (this.props.blockChain.controller?.token) {
                        console.log((mintingPrice * Number(this.state.totalNFTs) * 2).toString());
                        this.props.blockChain.controller?.token?.approve(
                          this.state.collection,
                          mintingPrice * Number(this.state.totalNFTs) * 2,
                          (error) => {
                            this._onUpdateData(error);
                          }
                        );
                      }
                    }}
                  >
                    Approve ({(mintingPrice * Number(this.state.totalNFTs)).toFixed(1)} MTRG)
                  </button>
                )}
                {this.state.launched && !this.state.isPublic ? (
                  <span className="ct-small-text">For now only the owner of the collection key can mint NFTs</span>
                ) : (
                  ""
                )}
                {this.state.launched && !this.state.isPublic ? (
                  <span>
                    <strong>Public mint: </strong> {hours > 1 ? hours.toFixed(0) + " Hours" : minutes.toFixed(0) + " Minutes"}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="ct-animation">
                <img src={AnimatedImage} alt="" />
              </div>
            </div>

            <div className="ct-main-banner ct-max-container animate__animated animate__backInLeft animate__delay-2s">
              <img src={CiervoLabLogo} alt="" />
              <div className="ct-minted">
                <h4>Generative NFTs</h4>
                <small>By @testing</small>
                <small>Progress: {isNaN(progress) ? "0" : progress}%</small>
                <small>
                  <a href="http://twitter.com/nftbbuilders" target="_blank" rel="noopener noreferrer">
                    <span className="fab fa-twitter"></span>
                  </a>
                  <a href="http://twitter.com/nftbbuilders" target="_blank" rel="noopener noreferrer">
                    <span className="fab fa-telegram"></span>
                  </a>
                </small>
              </div>
            </div>
          </div>

          <div className="ct-banner-container ct-max-container animate__animated animate__delay-2s animate__backInDown">
            <div className="ct-banner" style={{ backgroundImage: "url(" + MainBannerImage + ")" }}></div>
          </div>

          <div className="ct-my-collection-nfts ct-max-container ct-mt-20">
            <div className="ct-collection-grid">
              {this.state.myNFTs.map((nft, index) => {
                return (
                  <div key={index + nft._nftID.toString()} className="ct-nft-data">
                    <img src={nft._attributes.image} alt="" />
                    <div className="ct-info">
                      <small>Collection: {this.state.collectionName}</small>
                      <h4>{nft._attributes.name}</h4>
                      {nft._attibutes?.attibutes && Array.isArray(nft._attibutes?.attibutes) ? (
                        <React.Fragment>
                          <h5>Information</h5>
                          <div className="ct-metadata">
                            {nft._attibutes?.attibutes.map((meta: any, index: number) => {
                              return (
                                <div key={index}>
                                  <span>{meta.type}: </span>
                                  <span>{meta.data}</span>
                                </div>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      <a href={"https://tofunft.com/nft/meter/" + this.state.collection + "/" + nft._nftID} rel="noopener noreferrer" target="_blank">
                        View in marketplace
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
