import { useState } from "react";
import "./footer.styles.css";

export const Footer = () => {
  const localStorageData = localStorage.getItem("phase-disclaimer-bb");

  const [disclaimer, setDisclaimer] = useState(
    localStorageData !== null ? !!Number(localStorageData) : true
  );

  const updateData = () => {
    const localStorageData = localStorage.getItem("phase-disclaimer-bb");
    if (localStorageData !== null) {
      setDisclaimer(!!Number(localStorageData));
    }
  };

  return (
    <div className="ct-footer">
      <div className="ct-big-info-container ct-max-container">
        <div className="ct-big-info">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://docsuninft.businessbuilders.city/"
          >
            <span className="fas fa-passport"></span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://t.me/uninft_businessbuilders/"
          >
            <span className="fab fa-telegram"></span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/UniNFTMeter"
          >
            <span className="fab fa-twitter"></span>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://instagram.com/businessbuildersnft"
          >
            <span className="fab fa-instagram"></span>
          </a>
        </div>
        <div className="ct-token-footer">
          <p>
            {" "}
            <a
              href="https://businessbuilders.city"
              target="_blank"
              rel="noopener noreferrer"
            >
              By BusinessBuilders Ecosystem <br /> https://businessbuilders.city
            </a>{" "}
          </p>
        </div>
      </div>
      {disclaimer ? (
        <div className="ct-version-info">
          <div className="ct-data">
            <p>
              <strong>Version: 0.9</strong> Development Phase.
            </p>
            <small>By BusinessBuilders Ecosystems</small>
          </div>
          <button
            onClick={() => {
              localStorage.setItem("phase-disclaimer-bb", "0");
              updateData();
            }}
            className="ct-main-button"
          >
            Accept
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
