export const navigationLinks = [
  {
    display: "NFTs",
    description:
      "Mint, trade and launch your own NFT collections using a simple minting DAPPs.",
    active: true,
    links: [
      {
        href: "/staking",
        display: "Staking",
        icon: "fas fa-box-open",
        type: "internal",
        message: "Stake everything to earn progressive rewards",
        disabled: false,
      },
      {
        href: "/collections",
        display: "Launched Collections",
        message: "See all launched collections in our DAPP.",
        icon: "fas fa-box",
        type: "internal",
        disabled: false,
      },
    ],
  },
  {
    display: "ReDistribution",
    description: "Read about our fees redistribution and buy back systems.",
    active: false,
    links: [
      {
        href: "/distribution",
        display: "Distribution",
        message:
          "Distribute all rewards between staking pools and buy and burn systems.",
        icon: "fas fa-dollar-sign",
        type: "internal",
        disabled: false,
      },
    ],
  },
];
