import Web3 from "web3";
import { ContractBaseModel } from "../abstracts/contract";
import { AppErrorCode } from "../app";
import { AbstractUniversalNFTV3 } from "../abstracts/universalNFTV3";

export class UniversalNFTV3 extends ContractBaseModel<AbstractUniversalNFTV3> {
  name() {
    return this.contract.name().call(this.config);
  }

  getGenerationData() {
    return this.contract.getGenerationData().call(this.config);
  }

  getData() {
    return this.contract.getData(this.selectedAccount).call(this.config);
  }

  uri() {
    return this.contract.uri().call(this.config);
  }

  getMintingData() {
    return this.contract.getMintingData(this.selectedAccount).call(this.config);
  }

  publicTime() {
    return this.contract.publicTime().call(this.config);
  }

  isPublic() {
    return this.contract.isPublic().call(this.config);
  }

  symbol() {
    return this.contract.symbol().call(this.config);
  }

  tokens() {
    return this.contract.tokens(this.selectedAccount).call(this.config);
  }

  withdraw(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdraw()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  addToWhitelist(address: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .addToWhitelist(address)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  removeFromWhitelist(address: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .removeFromWhitelist(address)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  launch(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .launch()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  mint(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .mint()
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  manyMint(amount: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .manyMint(amount)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  burn(id: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .burn(id)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  changeURI(uri: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .changeURI(uri)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  changeMaxSupply(supply: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .changeMaxSupply(supply)
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }

  changePrice(price: number, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .changePrice(Web3.utils.toWei((price || "0").toString(), "ether"))
      .send(this.config)
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.MINTER_REQUEST_ERROR, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.MINTER_REQUEST_ERROR, error, callback));
  }
}
